import { useParams } from "react-router-dom";
import PageTitle from "../../components/app/PageTItle";
import TopBar from "../../components/app/topbar";
import { AiFillPlusCircle } from "react-icons/ai";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";
import Loader from "../../components/Loader";
import { BsFillTrashFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import InputField from "../../components/reusables/@InputElements/InputField";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { nepaliNumberConverter } from "../../_helpers/template";
import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";
import Password from "../../components/reusables/@InputElements/Password/Password";
import { FaEdit } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";

export default function WardOffice() {
    const { wardId } = useParams();

    const { data, isLoading } = useQueryHook({
        queryName: `ward-${wardId}`,
        queryRoute: `/ward/${wardId}/`
    });

    const [showWardUserForm, setShowWardUserForm] = useState(false);
    const [wardUserDetail, setWardUserDetail] = useState(null);

    const handleShowWardUserForm = () => setShowWardUserForm(true);
    const handleHideWardUserForm = () => {
        setWardUserDetail(null);
        setShowWardUserForm(false);
    };

    const handleEditWardUser = (wardUser: any) => {
        setShowWardUserForm(true);
        setWardUserDetail(wardUser)
    }

    return (
        <>
            <TopBar
                showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="वडा कार्यालय" />
                    {
                        !showWardUserForm &&
                        <button onClick={handleShowWardUserForm} className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:drop-shadow-xl hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3"
                        >
                            <AiFillPlusCircle fontSize={18} className="mr-2" />

                            नयाँ प्रयोगकर्ता
                        </button>
                    }
                </div>

                {
                    showWardUserForm &&
                    <WardUserForm
                        userDetail={wardUserDetail}
                        handleHideWardUserForm={handleHideWardUserForm} />
                }


                <div className="settings-content-container">
                    <h3 className="text-xl font-bold text-blue-500 mb-3">प्रयोगकर्ताहरु</h3>

                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th className="text-xl">नाम</th>
                                <th className="text-xl">ईमेल</th>
                                <th className="text-xl">फोन</th>
                                <th className="text-xl">पद</th>
                                <th className="text-xl">कार्यहरू</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={5}>
                                            <Loader isLoading />
                                        </td>
                                    </tr>
                                    :
                                    data?.data?.users?.length > 0 ?
                                        data?.data?.users.map((user: any) => <tr key={user.id}>
                                            <td>{user.fullname_np}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone_np}</td>
                                            <td>{user.post_np}</td>
                                            <td className="flex">
                                                <button className="flex items-center justify-center rounded bg-blue-500 text-center text-white hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2 mr-2" onClick={() => handleEditWardUser(user)}>
                                                    <FaEdit fontSize={18} />
                                                </button>

                                                <button className="flex items-center justify-center rounded bg-red-500 text-center text-white hover:bg-red-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2">
                                                    <BsFillTrashFill fontSize={18} />
                                                </button>
                                            </td>
                                        </tr>)
                                        :
                                        <tr>
                                            <td colSpan={5} className="text-center">कुनै प्रयोगकर्ता फेला परेन
                                            </td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};


interface IWardUserFormProps {
    userDetail: any;
    handleHideWardUserForm: () => void;
}

const WardUserForm = ({ userDetail, handleHideWardUserForm }: IWardUserFormProps) => {
    const { wardId } = useParams();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, setValue } = useForm<any>({
        mode: 'onBlur',
        values: {},
    });
    const { language } = useAppSelector(state => state.language);

    const { mutate: patchWardUser, isLoading: patchLoading } = useMutationHook({
        queryRoute: `/profile/ward-user/${userDetail?.id}/`,
        method: 'patch',
        options: {
            onSuccess: () => {
                handleHideWardUserForm();
                dispatch(setSuccessToastMessage("प्रयोगकर्ता सफलतापूर्वक सम्पादन गरियो!"))
            },
            onError: () => {
                dispatch(setErrorToastMessage("कार्य असफल भएको छ। कृपया केहि समय पछि पुन: प्रयास गर्नुहोस्!"))
            }
        }
    });

    const { mutate: postWardUser, isLoading: postLoading } = useMutationHook({
        queryRoute: `/profile/ward-user/`,
        options: {
            onSuccess: () => {
                handleHideWardUserForm();
                dispatch(setSuccessToastMessage("प्रयोगकर्ता सफलतापूर्वक सिर्जना गरियो!"))
            },
            onError: () => {
                dispatch(setErrorToastMessage("कार्य असफल भएको छ। कृपया केहि समय पछि पुन: प्रयास गर्नुहोस्!"))
            }
        }
    });

    const loading = postLoading || patchLoading;

    useEffect(() => {
        if (userDetail) {
            setValue('fullname_np', userDetail?.fullname_np);
            setValue('fullname_en', userDetail?.fullname_en);
            setValue('password', userDetail?.password);
            setValue('phone_en', userDetail?.phone_en);
            setValue('email', userDetail?.email);
            setValue('post_np', userDetail?.post_np);
            setValue('post_en', userDetail?.post_en);
            setValue('role', userDetail?.role);
        }
    }, [setValue, userDetail]);

    const onSubmit = (data: any) => {
        if (data.phone_en.length !== 10) {
            return dispatch(setErrorToastMessage('Phone number is invalid'));
        }

        data.phone_np = nepaliNumberConverter(data.phone_en);

        let body = {
            ...data,
            username: data.email,
            organization: wardId
        };

        if (userDetail) {
            patchWardUser(body);
        }
        else {
            postWardUser(body);
        }
    };

    return (
        <div className="card shadow-md border p-4 mb-10">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                <div>
                    <div className="card-header-custom mb-4">
                        <h3 className="text-xl text-blue-500 font-semibold">{userDetail ? 'प्रयोगकर्ता सम्पादन' : ' नयाँ प्रयोगकर्ता'}</h3>
                    </div>

                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1 mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">नाम (नेपालीमा)</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'fullname_np'}
                                    placeHolder={'पूरा नाम भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.fullname_np}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">नाम (अंग्रेजीमा)</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'fullname_en'}
                                    placeHolder={'Enter full name'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.fullname_en}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">Phone No.</label>
                                <InputField
                                    type="number"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'phone_en'}
                                    placeHolder={'Enter phone number'}
                                    maxLength={10}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.phone_en}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">ईमेल</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'email'}
                                    placeHolder={'ईमेल भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.email}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">पासवर्ड</label>
                                <Password
                                    register={register}
                                    id="password"
                                    placeholder={'Enter password'}
                                    value={userDetail && userDetail.password}
                                    className={`mini-field ${userDetail?.eventType === 'edit' ? '' : 'wardform-input textbox_req'}`}
                                    mini-field
                                    isRequired={!userDetail}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">पद</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={`post_np`}
                                    placeHolder={'पद भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    setValue={setValue}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">Designation</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={`post_en`}
                                    placeHolder={'Enter post'}
                                    maxLength={50}
                                    register={register}
                                    setValue={setValue}
                                    isRequired={true}
                                    value={userDetail && userDetail.post_en}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">भूमिका</label>
                                <select
                                    className="py-2 px-1"
                                    {...register('role')}
                                >
                                    <option value="">भूमिका छान्नुहोस्</option>
                                    <option value="ward_admin">वडा अध्यक्ष</option>
                                    <option value="ward_user">वडा प्रयोगकर्ता</option>
                                    <option value="ward_kawa">वडा का.वा</option>
                                    <option value="ward_paper_issuer">थप प्रयोगकर्ता</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                        <button
                            className="flex items-center mx-3 rounded-xl bg-red-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3 mr-2"
                            onClick={handleHideWardUserForm}
                            disabled={loading}
                        >
                            रद्द गर्नुहोस्
                        </button>

                        <button type="submit" className="flex justify-center items-center rounded-xl bg-blue-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3 min-w-[90px]"
                            disabled={loading}>
                            {
                                loading ? <VscLoading className='animate-spin' /> : 'पेश गर्नुहोस्'
                            }
                        </button>
                    </div>
                </div>
            </form >
        </div >
    )
}