
import { generateHeaderPreview } from "../../_helpers/template"

export const NagarpalikaHeader = ({ user, template, headerProps, chalaniNum }) => {
    return (<div
        key={chalaniNum}
        className="header"
        dangerouslySetInnerHTML={{
            __html: generateHeaderPreview(template, headerProps, user),
        }} />)
}

export const NagarpalikaFooter = ({ defaultTemplate }) => {
    return (<div
        className="footer"
        dangerouslySetInnerHTML={{
            __html: defaultTemplate?.data?.additional_info?.nagarpalika_footer,
        }} />)
}