import { FC, ReactElement, ReactNode } from 'react';
import { VscLoading } from 'react-icons/vsc';
import cls, { Argument } from 'classnames';

interface LoaderProps {
	children?: ReactNode | ReactElement | string;
	isLoading: boolean;
	fontSize?: number | string;
	isTable?: boolean;
	className?: Argument;
	iconClassName?: Argument;
}

const Loader: FC<LoaderProps> = ({ children, isLoading, fontSize = 24, isTable = false, className, iconClassName }) => {
	return (
		<>
			{isLoading ? (
				isTable ? (
					<tr>
						<td>
							<div className="py-6 flex justify-center">
								<VscLoading className="animate-spin text-[#D24DA6]" fontSize={fontSize} />
							</div>
						</td>
					</tr>
				) : (
					<div className={cls('py-6 flex justify-center', className)}>
						<VscLoading className={cls('animate-spin text-[#D24DA6]', iconClassName)} fontSize={fontSize} />
					</div>
				)
			) : (
				children
			)}
		</>
	);
};

export default Loader;
