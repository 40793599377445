import { FC, Key } from 'react';
import Modal from '../Modal';
import DocumentUpload from '../../@compose/DocumentUpload';
import { IFile } from '../../@compose/DocumentUpload/DocumentUpload';
import { useQueryClient } from '@tanstack/react-query';

interface IUploadFileModal {
	paperId: Key | undefined;
}

const UploadDocumentModal: FC<IUploadFileModal> = ({ paperId }) => {
	const queryClient = useQueryClient();

	const handleDocumentUpload = (files: IFile[]) => {
		queryClient.invalidateQueries(['draft']);
	};
	return (
		<Modal id="upload-document-modal">
			<DocumentUpload url={`/paper/${paperId}/upload-hardcopy/`} onUpload={handleDocumentUpload} />
		</Modal>
	);
};

export default UploadDocumentModal;
