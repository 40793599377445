import { useQuery } from '@tanstack/react-query';

//
import OrganizationServices from '../../apis/services/OrganizationServices';

/**
 * 
 */
export const useGetOrganizationQuery = ({ handleSuccess, isSakha, handleError }: IUseGetOrganizationQueryProps) => {
	const getOrganizations = () => {
		if (isSakha) {
			return OrganizationServices.getSakhaData();
		}
		return OrganizationServices.getOrganizationData();
	}

	const onSuccess = (data: any) => {
		if (handleSuccess) {
			handleSuccess(data);
		}
		else {
			return data;
		}
	}
	const onError = () => {
		if (handleError) {
			handleError();
		}
	}

	return useQuery(
		[isSakha ? 'sakha' : 'organization'],
		getOrganizations,
		{
			onSuccess,
			onError,
		}
	);
}


interface IUseGetOrganizationQueryProps {
	handleSuccess?: (data: any) => void;
	handleError?: () => void;
	isSakha?: boolean;
}