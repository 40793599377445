import React from "react";
import InputField from "../../../reusables/@InputElements/InputField";
// import { dynamicValidator } from "../../../../_helpers/dynamicValidator";

function Text({
  label,
  register,
  elementId,
  language,
  className,
  placeholder,
  maxlength,
  value,
  setValue,
  errors = {},
  properties = {},
  isPublic,
  validation,
  entity,
}) {
  if (isPublic && entity === "private") {
    return null;
  }
  return (
    <div className="py-2 mb-2">
      <label htmlFor={elementId} className="relative mb-2">
        <label className="block font-semibold mb-1" htmlFor={elementId}>
          {label} :{" "}
        </label>
        <InputField
          type="text"
          language={language}
          className={`px-2 py-1 rounded ${className} ${properties?.isRequired ? "textbox_req" : ""
            } ${errors[elementId] ? "is-invalid" : ""} `}
          register={register}
          elementId={elementId}
          placeholder={placeholder}
          maxLength={maxlength}
          value={value}
          setValue={setValue}
          properties={properties}
          validation={validation}
        />

        {
          errors[elementId] &&
          <div className="error-text">
            {errors[elementId].message}
          </div>
        }
      </label>
    </div>
  );
}

export default Text;
