	import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
export type toastStateType = {
	type: 'success' | 'error' | null;
	message: String | null;
	show: boolean;
};

const initialState: toastStateType = {
	show: false,
	type: null,
	message: null,
};

const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		setToastMessage: (state: toastStateType, action: PayloadAction<toastStateType>) => {
			state.show = true;
			state.type = action.payload.type;
			state.message = action.payload.message;
		},
	},
});

const { setToastMessage } = toastSlice.actions;

export default toastSlice.reducer;

export const setToast: any = (toast: toastStateType) => (dispatch: Dispatch) => {
	dispatch(setToastMessage(toast));
};

export const setSuccessToastMessage: any = (message: string) => (dispatch: Dispatch) => {
	dispatch(setToastMessage({ type: 'success', message: message, show: true }));
};

export const setErrorToastMessage: any = (message: string) => (dispatch: Dispatch) => {
	dispatch(setToastMessage({ type: 'error', message: message, show: true }));
};


