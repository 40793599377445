import { createSlice, Dispatch } from "@reduxjs/toolkit";
export type imagePreviewStateType = {
  image: string;
};

const initialState: imagePreviewStateType = {
  image: "",
};

const imagePreviewSlice = createSlice({
  name: "imagePreview",
  initialState,
  reducers: {
    setImage: (state: imagePreviewStateType, action) => {
      state.image = action.payload;
    },
  },
});

const { setImage } = imagePreviewSlice.actions;

export default imagePreviewSlice.reducer;

export const setLightBoxImage: any =
  (image: imagePreviewStateType) => (dispatch: Dispatch) => {
    dispatch(setImage(image));
  };
