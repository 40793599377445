import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PageLoading from '../../components/app/pageLoading';

//
import { LOCAL_ACCESS_TOKEN_NAME, USER_PROFILE_TOKEN_NAME } from '../../constants/constants';
import { useAppDispatch } from '../../hooks/redux';
import { handleGetUserData, handleGetUserProfileData, handleSetActiveFiscalYear } from '../../_redux/slices/auth';
import { useQueryHook } from '../../hooks/react-query/useQueryHook';
import { logoutUser } from '../../_redux/slices/auth';
import { setErrorToastMessage } from '../../_redux/slices/toast';

//

/**
 *
 */
export default function AuthLayout() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const sso =
		process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === 'true' || process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === true;


	//
	const getSession = async () => {
		let isLoggedIn = false;
		const localAccess = localStorage.getItem(LOCAL_ACCESS_TOKEN_NAME);
		localStorage.removeItem('showLoginPage');

		if (localAccess) {
			const result = await dispatch(handleGetUserData());
			if (!result) {
				dispatch(setErrorToastMessage('प्रयोगकर्ता विवरण प्राप्त गर्न असफल भयो।'))
			}
			await dispatch(handleGetUserProfileData());
			isLoggedIn = result;
		}

		setIsLoading(false);

		if (!isLoggedIn) {
			if (sso && process.env.REACT_APP_SSO_URL && process.env.REACT_APP_SSO_LOGIN === 'true') {
				const redirectLink = document.createElement('a');
				let url = `http://localhost:3000`;
				if (process.env.NODE_ENV === "production") {
					url = `${process.env.REACT_APP_SSO_URL}`;
				}
				let link = `${url}/signout`;

				redirectLink.href = link;
				redirectLink.click();
				console.log('isLoggedIn auth');
			}
			else {
				navigate('/login');
			}
			dispatch(logoutUser());
		}

		setIsLoggedIn(isLoggedIn);
	};

	useQueryHook({
		queryName: 'inbox-stats',
		queryRoute: '/inbox/stats/',
		options: {
			enabled: isLoggedIn
		}
	});

	useQueryHook({
		queryName: 'defaultTemplate',
		queryRoute: '/template/default/',
		options: {
			staleTime: Infinity
		}
	});

	useQueryHook({
		queryName: 'activeFiscalYear',
		queryRoute: '/fiscal-year/active/',
		options: {
			enabled: isLoggedIn,
			staleTime: Infinity,
			onSuccess: (data) => {
				dispatch(handleSetActiveFiscalYear(data));
			}
		}
	});

	useEffect(() => {
		getSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Do not show the content initially
	if (isLoading) return <PageLoading />;

	return (
		<div>
			{/* Maybe add some loading animation initially when fetching and validating the data?? */}
			<Outlet />
		</div>
	);
}
