import InputField from '../InputField';
import { useAppSelector } from '../../../../hooks/redux';

/**
 * 
 */
export default function Text({ elementId, innerRef, className, placeholder, value, disabled, maxlength, getInputData, subType = "text", onEnterPress }: ITextProps) {
    const language = useAppSelector(state => state.language.language);
    const properties = {};
    return (
        <div>
            <InputField
                language={language}
                className={`${className}`}
                // className={`${className} input ${properties?.isRequired ? "textbox_req" : ""
                //     } ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} `}
                // register={register}
                elementId={elementId}
                placeHolder={placeholder}
                maxLength={maxlength}
                value={value}
                type={subType}
                // setValue={setValue}
                properties={properties}
                // validation={validations}
                innerRef={innerRef}
                disabled={disabled}
                getInputData={getInputData}
                onEnterPress={onEnterPress}
            />
        </div>
    )
}

interface ITextProps {
    elementId: string;
    className?: string;
    placeholder?: string;
    value?: any;
    innerRef?: any;
    disabled?: boolean;
    maxlength?: number;
    subType?: 'text' | 'textarea' | 'number';
    getInputData?: (value: string) => void;
    onEnterPress?: (value: string) => void;
}