import React, { useEffect } from 'react';
import { FaEdit, FaPlusSquare, FaTrash } from 'react-icons/fa';
import { AiFillMinusSquare } from 'react-icons/ai';

import { useFieldArray } from 'react-hook-form';
import { nepaliNumber } from '../../../../_helpers/methods';
import InputField from '../../../reusables/@InputElements/InputField';
import Swal from 'sweetalert2';

const Table = ({ data, language, register, control, setValue, value = [], category, entity, isPublic, handleDeleteTable, handleEditTable }) => {
	const headings = data.data.headings;
	const headingLength = headings.length;

	const { fields, append, remove, swap } = useFieldArray({
		control,
		name: data.id,
	});

	useEffect(() => {
		if (value.length === 0) {
			if (fields.length < (Number(data.data.minRow) || 2)) {
				append({});
			}
		} else {
			if (fields.length < value.length) {
				append({});
			}
		}
	}, [append, data.data.minRow, fields, value]);

	if (isPublic && entity === 'private') {
		return null;
	}

	const onDeleteTableClick = () => {
		Swal.fire({
			title: 'के तपाई यो तालिका delete गर्न चाहनुहुन्छ?',
			// text: "You want to delete this Patrachar!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#60A5FA',
			cancelButtonColor: '#f87171',
			confirmButtonText: 'सुनिश्चित गर्नुहोस्',
			cancelButtonText: 'रद्द गर्नुहोस्',
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				handleDeleteTable(data.id);
			}
		});
	};


	const onEditTableClick = () => {
		handleEditTable(data.id);
	};

	return (
		<div className="w-full my-2">
			<table className="table border table-striped w-full mb-5">
				<thead>
					<tr>
						{headings.map((heading, idx) => (
							<th className="border border-slate-100" key={idx}>
								{heading.name}
							</th>
						))}
						<th className="w-[7px] border border-slate-100">Action</th>
					</tr>
				</thead>
				<tbody>
					{fields.map(({ id, ...rest }, index) => (
						<tr key={id}>
							{Array.from({ length: headingLength }).map((_, fieldIndex) => {
								let elementId = `${data.id}[${index}].${fieldIndex}___${headings[fieldIndex].name
									.split('.')
									.join('####')}`;
								let elementNoHashParseId = `${data.id}[${index}].${fieldIndex}___${headings[fieldIndex].name}`;
								let tableDataValue =
									value[index] &&
									(value[index][`${fieldIndex}___${headings[fieldIndex].name.split('.').join('####')}`] ||
										value[index][`${fieldIndex}___${headings[fieldIndex].name}`]);
								return (
									<td key={fieldIndex} className="p-2 m-0 border border-slate-300 relative !h-[2.75rem]">
										{headings[fieldIndex].type !== 'select' ? (
											fieldIndex === 0 ? (
												<InputField
													className="table-input absolute inset-2 h-[26px]"
													type={headings[fieldIndex].type}
													language={language}
													register={register}
													elementId={elementId}
													readOnly
													value={category === 'English Format' ? index + 1 : nepaliNumber(index + 1)}
													setValue={setValue}
												/>
											) : (
												<InputField
													className="table-input absolute inset-2 h-[26px]"
													type={headings[fieldIndex].type}
													language={language}
													register={register}
													elementId={elementId}
													value={tableDataValue}
													setValue={setValue}
												/>
											)
										) : (
											<select {...register(elementNoHashParseId)}>
												{headings[fieldIndex].options.map((option) => (
													<option key={option.value} value={option.value} selected={tableDataValue === option.value}>
														{option.value}
													</option>
												))}
											</select>
										)}
									</td>
								);
							})}
							{index + 1 > (Number(data.data.minRow) || 2) ? (
								<td
									onClick={() => {
										swap(index, fields.length - 1);
										remove(fields.length - 1);
									}}
									className="cursor-pointer w-[75px] text-center border border-slate-300"
								>
									<AiFillMinusSquare color="#e91e63" size="28" />
								</td>
							) : (
								<td></td>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<div className="flex w-full justify-end items-center">
				{Number(data.data.maxRow) > fields.length && (
					<>
						<div className="flex justify-end mr-2 cursor-pointer" onClick={() => append({})}>
							<FaPlusSquare color="blue" size="24" />
						</div>

					</>
				)}

				<div
					className="d-flex justify-content-end mr-2 cursor-pointer"
					onClick={onEditTableClick}
				>
					<FaEdit color="#006aff" size="24" />
				</div>
				<div className="flex justify-end mr-2 cursor-pointer" onClick={onDeleteTableClick}>
					<FaTrash color="#e91e63" size="24" />
				</div>
			</div>
		</div>
	);
};

export default Table;
