import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillCloseCircle } from "react-icons/ai";
import { VscLoading } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { useMutationHook } from "../../../hooks/react-query/useQueryHook";
import { setErrorToastMessage } from "../../../_redux/slices/toast";

/**
 *
 */
export default function DocumentUpload(props: IDocumentUploadProps) {
  const dispatch = useDispatch();
  const dropzoneProps = { ...(props?.properties || {}) };

  const [files, setFiles] = useState<any[]>([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...dropzoneProps,
  });
  const [preview, setPreview] = useState<string | null>(null);
  const modalBtnRef = useRef<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [needsUpload, setNeedsUpload] = useState(false);

  const handleRemoveFile = (file: IFile) => {
    const tempFiles = uploadedFiles.filter((f: IFile) => f.id !== file.id);
    setPreview(null);
    setUploadedFiles(tempFiles);
    setNeedsUpload(true);
  };

  const listFiles = uploadedFiles.map((file: IFile) => {
    return (
      <li
        key={file.file}
        className="bg-slate-500 text-white p-4 rounded-3xl mr-2 max-w-[200px]"
      >
        <div className="flex items-center justify-between mb-2">
          <div className="cursor-pointer" onClick={() => setPreview(file.file)}>
            {file.title?.substring(0, 15)}
          </div>

          <AiFillCloseCircle
            fontSize={24}
            className="cursor-pointer ml-1"
            onClick={() => handleRemoveFile(file)}
          />
        </div>

        <img src={file.file} alt={file.title} className="rounded-xl" />
      </li>
    );
  });

  //
  const { mutate, isLoading } = useMutationHook({
    queryRoute: props?.url || "/add-documents/",
    axiosOptions: {
      multipart: true,
    },
    options: {
      onSuccess: (data) => {
        try {
          if (modalBtnRef?.current) {
            modalBtnRef.current.click();
          }
          setUploadedFiles((prop) => [...prop, data]);
          setNeedsUpload(true);

          return data;
        } catch (error) {
          console.log("ERROR Composing", error);
          return error;
        }
      },
      onError: (error) => {
        dispatch(setErrorToastMessage("Something went wrong"));
      },
    },
  });

  const handleSubmit = useCallback(async () => {
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("title", file.name);
      formData.append("file", file);

      mutate(formData);
    });
  }, [mutate, files]);

  useEffect(() => {
    if (acceptedFiles) {
      setFiles(acceptedFiles);
    }
  }, [acceptedFiles, props.isMulti]);

  useEffect(() => {
    if (files.length > 0) {
      handleSubmit();
    }
  }, [files, handleSubmit]);

  useEffect(() => {
    if (needsUpload) {
      props.onUpload(uploadedFiles);
      setNeedsUpload(false);
    }
  }, [uploadedFiles, needsUpload, props, props.onUpload]);

  return (
    <div className="w-full">
      <section>
        {isLoading ? (
          <div className="border-4 rounded p-4 h-[100px] flex justify-center items-center cursor-pointer focus:border-dashed focus:border-cyan-600">
            <VscLoading fontSize={60} className="animate-spin" />
          </div>
        ) : (
          <div
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
            className="border-4 rounded p-4 h-[100px] flex justify-center items-center cursor-pointer focus:border-dashed focus:border-cyan-600"
          >
            <input accept="image/*" {...getInputProps()} />
            <p className="font-semibold text-gray-700">
              {props.image ? "फोटो" : "पत्र"} यहाँ अपलोड गर्नुहोस् 
            </p>
          </div>
        )}
        {Boolean(listFiles?.length) && (
          <aside>
            <h4 className="font-bold mt-4">
              {props.image ? "फोटोहरु" : "पत्रहरु"}
            </h4>
            <ul className="flex">{listFiles}</ul>
          </aside>
        )}
        {preview && (
          <div id="preview">
            <img src={preview} alt="dfd" />
          </div>
        )}

        <div className="mt-6">
          {/* <button className="btn btn-primary" disabled={isLoading} onClick={handleSubmit}>
						{isLoading ? <Loader /> : 'Submit'}
					</button> */}
        </div>
      </section>

      <label htmlFor="upload-document-modal" ref={modalBtnRef}></label>
    </div>
  );
}
interface IDocumentUploadProps {
  isMulti?: boolean;
  onUpload: (files: IFile[]) => void;
  url?: string | null;
  properties?: {};
  image?: boolean;
}

export interface IFile {
  created_at: string;
  file: string;
  id: number;
  title: string;
}
