export const InboxDataFetchInterval = 30000;

export const LOCAL_ACCESS_TOKEN_NAME = 'patrachar_user';
export const LOCAL_REFRESH_TOKEN_NAME = 'patrachar_userRefresh';
export const USER_PROFILE_TOKEN_NAME = 'patrachar_profile_token';

export const MAIL_PRIORITY = {
    "low": 'निम्न',
    "medium": "मध्यम",
    "high": 'उच्च',
    "urgent": 'अत्यावश्यक',
}

export const DEFAULT_MAIL_PRIORITY = 'medium';
export const CHECK_STATUS_INTERVAL = 4000;

export const COLORS = {
    a: '#1abc9c',
    b: '#f1c40f',
    c: '#3498db',
    d: '#9b59b6',
    e: '#34495e',
    f: '#16a085',
    0: '#27ae60',
    1: '#2980b9',
    2: '#7f8c8d',
    3: '#2c3e50',
    4: '#21878A',
    5: '#e67e22',
    6: '#e74c3c',
    7: '#99B523',
    8: '#f39c12',
    9: '#5E44D2',
};

export const CHALANI_MEDIUM = {
    'इमेल':'इमेल',
    'हुलाक':'हुलाक',
    'हार्डकपी':'हार्डकपी',
    'स्वयं व्यक्ति मार्फत':'स्वयं व्यक्ति मार्फत',
}