import { FaIdBadge } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const colorClass = [
	'bg-purple-600 text-white hover:bg-purple-500',
	'bg-yellow-400 text-neutral-800 hover:bg-yellow-300',
	'bg-red-600 text-white hover:bg-red-500',
	'bg-orange-600 text-white hover:bg-orange-500',
	'bg-green-600 text-white hover:bg-green-500',
	'bg-sky-600 text-white hover:bg-sky-500',
];

function TemplateCard({
	title,
	id,
	templateUrl = '/template-single/',
	categoryIcon,
	icon,
	idx,
	additional_info,
}: ITemplateCardProps) {
	let isAdditionalInfoString = false;
	let isAdditionalInfoArray = false;
	if (typeof additional_info === 'string') {
		isAdditionalInfoString = true;
	}
	if (Array.isArray(additional_info)) {
		isAdditionalInfoArray = true;
	}

	const minValidLengthOfIconUrl = 16;
	let templateIcon = null;
	if (icon?.length > minValidLengthOfIconUrl) {
		templateIcon = icon;
	} else if (categoryIcon?.length > minValidLengthOfIconUrl) {
		templateIcon = categoryIcon;
	}

	//   const popover = (
	//     <Popover id="popover-basic" className="template-card-popover">
	//       <Popover.Title as="h3">
	//         <b>आवश्यक विवरण</b>
	//       </Popover.Title>
	//       <Popover.Content className="content">
	//         {additional_info ? (
	//           <Fragment>
	//             {isAdditionalInfoString ? (
	//               additional_info
	//             ) : isAdditionalInfoArray ? (
	//               additional_info.map(({ title, description }) => {
	//                 if (Array.isArray(description)) {
	//                   return (
	//                     <div key={title} className="mb-2">
	//                       <span className="font-weight-bold">{title} :- </span>
	//                       <div>
	//                         <ol>
	//                           {description.map((document, idx) => (
	//                             <li key={idx} className="detail">
	//                               {/* {nepaliNumberConverter(String(idx + 1))}.{" "} */}
	//                               {typeof document === "string" && document}
	//                             </li>
	//                           ))}
	//                         </ol>
	//                       </div>
	//                     </div>
	//                   );
	//                 }
	//                 return (
	//                   <div key={title} className="mb-2">
	//                     <span className="font-weight-bold">{title} :- </span>
	//                     <span className="detail">{description}</span>
	//                   </div>
	//                 );
	//               })
	//             ) : (
	//               <div className="text-danger">
	//                 Information is updating, please check later
	//               </div>
	//             )}
	//           </Fragment>
	//         ) : (
	//           <div>No data found</div>
	//         )}
	//       </Popover.Content>
	//     </Popover>
	//   );

	return (
		<div className="mb-3 col col-span-1">
			<div className={`${colorClass[idx]} mb-1 rounded p-2 shadow-md hover:scale-[1.025] active:scale-[1.025]`}>
				{/* {additional_info && (
          <div className="d-flex justify-content-end align-items-center ">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popover}
            >
              <button className="btn-info-popover d-flex align-items-center">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 150, hide: 100 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      आवश्यक विवरण
                    </Tooltip>
                  )}
                >
                  <FiInfo
                    size="18px"
                    strokeWidth={"3"}
                    className={`${colorClass[idx]} i-icon`}
                  />
                </OverlayTrigger>
              </button>
            </OverlayTrigger>
          </div>
        )} */}
				<Link to={`${templateUrl}${id}`}>
					<div className="p-0 card-body">
						<div className="flex items-center tempalte-card-content">
							{templateIcon ? (
								<div className="tempate-card-icon-container">
									<img src={templateIcon} alt="icon" className="icon" />
								</div>
							) : (
								<FaIdBadge size="48" className=" mr-3" />
							)}
							<h5 className="text-left">{title}</h5>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
}

export default TemplateCard;

interface ITemplateCardProps {
	title: string;
	id: any;
	templateUrl: string;
	categoryIcon: any;
	icon: any;
	idx: any;
	additional_info: any;
}
