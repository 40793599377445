import ReceiverListInput from "../../components/@compose/ReceiverListInput";
import FormInput from "../../components/reusables/FormInput";
import { CHALANI_MEDIUM } from "../../constants/constants";
import { ILabel } from "../Compose/Compose";

/**
 * 
 */
export default function ChalaniForm({ chalaniData, chalaniError, onInputChange, disable }: IChalaniFormProps) {
    let relatedBranch: ILabel | null = chalaniData.related_branch ? {
        value: chalaniData.related_branch,
        label: chalaniData.related_branch
    } : null;
    return (
        <div className="border-t-2 border-gray-300 py-4">
            <h2 className="text-xl font-bold underline mb-4">चलानी विवरण</h2>
            <div className="grid grid-cols-12 gap-x-6">
                <div className="col-span-4">
                    <label>
                        <FormInput id="receiving_department" label="पत्र पाउने ब्यक्ति/संस्था/कार्यालयको नाम" type="Text" defaultValue={chalaniData.receiving_department} getInputData={(value: string) => onInputChange('receiving_department', value)} className='h-[2.4rem]'
                            error={chalaniError['receiving_department']} isDisabled={disable} />
                    </label>
                </div>
                <div className="col-span-4">
                    <label>
                        <ReceiverListInput
                            value={relatedBranch ? [relatedBranch] : null}
                            defaultLabel="पत्रको सम्बन्धित शाखा"
                            onChange={({ label }: any) => onInputChange('related_branch', label)}
                            isMulti={false}
                            isDisabled={disable}
                            allowSelfSelect
                        />
                    </label>
                </div>
                <div className="col-span-4">
                    <label>
                        <FormInput id="address" label="ठेगाना"
                            type="Text"
                            defaultValue={chalaniData.address} getInputData={(value: string) => onInputChange('address', value)} className='h-[2.4rem]'
                            error={chalaniError['address']} isDisabled={disable} />
                    </label>
                </div>
                <div className="col-span-4">
                    <label>
                        <FormInput id="sending_medium" label="पठाएको साधन" type="Select"
                            options={CHALANI_MEDIUM}
                            isMulti={false}
                            defaultValue={[{ label: chalaniData?.sending_medium, value: chalaniData?.sending_medium }]} getInputData={(data: any) => onInputChange('sending_medium', data?.value)} className='h-[2.4rem]'
                            error={chalaniError['sending_medium']} isDisabled={disable} />
                    </label>
                </div>
                <div className="col-span-4">
                    <label>
                        <FormInput id="paper_count" label="पत्र संख्या" type="Text" defaultValue={chalaniData.paper_count} getInputData={(value: string) => onInputChange('paper_count', value)} className='h-[2.4rem]'
                            error={chalaniError['paper_count']} isDisabled={disable} />
                    </label>
                </div>
                <div className="col-span-8">
                    <label>
                        <FormInput id="remarks" label="कैफियत" type="Text" subType="textarea" defaultValue={chalaniData.remarks} getInputData={(value: string) => onInputChange('remarks', value)} className='h-[2.4rem]'
                            error={chalaniError['remarks']} isDisabled={disable} />
                    </label>
                </div>
            </div>
        </div>
    )
}

export interface chalaniDataProps {
    receiving_department?: string;
    address?: string;
    sending_medium?: string;
    related_branch?: string;
    paper_count?: string;
    remarks?: string;
    subject?: string;
    label?: string;
    organization?: string;
    date?: string;
    paper_id?: string;
}

interface IChalaniFormProps {
    chalaniData: chalaniDataProps;
    chalaniError: any;
    onInputChange: (id: string, value: string) => void;
    disable: boolean;
}