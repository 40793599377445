import { nepaliNumberConverter } from '../../_helpers/template';

export const DefaultWardHeader = ({
	user,
	isEnglish,
	fiscal,
	previewFormData,
	chalaniNum,
	paperCount,
}) => {
	const organization = user.organization;
	const nepaliChalaniNumber = chalaniNum ?? nepaliNumberConverter(chalaniNum);
	const nepaliPaperCountNumber =
		paperCount ?? nepaliNumberConverter(paperCount);

	return (
		<div className="heading w-full">
			<div className="flex justify-between items-center">
				<div>
					{user.nagarpalika_info?.nagarpalika_main_logo && (
						<img
							className="logo w-[125px]"
							src={user.nagarpalika_info?.nagarpalika_main_logo}
							alt="nagarpalika_main_logo"
						/>
					)}
				</div>

				<div className="title text-center">
					<h5 className="text-xl text-red-600 font-semibold mb-0">
						{user.nagarpalika_info?.nagarpalika_name_np}
					</h5>
					<h5 className="text-xl text-red-600 mb-0">
						{user.nagarpalika_info?.nagarpalika_name_en}
					</h5>

					{user?.organization?.type === 'ward' && (
						<>
							<h3 className="text-3xl text-red-600 mb-0 font-semibold">
								{user?.organization?.name_np} नं वडा कार्यालय
							</h3>
							<h3 className="text-3xl text-red-600 mb-0">
								Office of Ward No. {user?.organization?.name_en}
							</h3>
						</>
					)}

					<h5 className="text-red-600 mb-0 font-bold">
						{user?.organization?.address_np} ({user?.organization?.address_en})
					</h5>

					<h5 className="mb-0 text-red-600">
						<span className="font-bold mr-1">
							{user.nagarpalika_info?.nagarpalika_address_np}
						</span>
						({user.nagarpalika_info?.nagarpalika_address_en})
					</h5>
				</div>

				{user.nagarpalika_info?.nagarpalika_campaign_logo ? (
					<img
						className="sidelogo w-[125px]"
						src={user.nagarpalika_info?.nagarpalika_campaign_logo}
						alt="campaign_logo"
					/>
				) : (
					<div style={{ width: '20%' }}></div>
				)}
			</div>
			<div>
				<div className="flex justify-between items-end header-text">
					<div>
						<p className="mb-0 text-small">
							प.स. (I.No.)- {nepaliPaperCountNumber}
						</p>
						<p className="mb-0 text-small">
							चलानी नं. (Ref.No): {nepaliChalaniNumber} {nepaliChalaniNumber ? organization.code || "" : ""}
						</p>
					</div>

					{isEnglish ? (
						previewFormData?.date_today_en ? (
							<p className="mb-0 text-small">
								Date: {previewFormData?.date_today_en.year}/
								{previewFormData?.date_today_en.month}/
								{previewFormData?.date_today_en.day}
							</p>
						) : null
					) : previewFormData?.date_today ? (
						<p className="mb-0 text-small">
							मिती: {previewFormData?.date_today.year}/
							{previewFormData?.date_today.month}/
							{previewFormData?.date_today.day}
						</p>
					) : null}
				</div>
				<hr className="border-gray-300 mt-2 black border-2" />{' '}
			</div>
		</div>
	);
};
