import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import { nepaliNumber } from "../../../../_helpers/methods";

export default function Pagination({ isLoading, offset, total, limit, onPrev, onNext }: IPaginationProps) {
    const canNext = (offset + 1) * limit < total;
    const canPrev = offset > 0;

    const handlePrev = () => {
        if (canPrev) {
            onPrev();
        }
    }

    const handleNext = () => {
        if (canNext) {
            onNext();
        }
    }
    if (isLoading) {
        return <div className="animate-pulse flex" >
            <div className="bg-slate-200 h-[21px] my-2 w-[138px] rounded mr-2" />

            <div className="bg-slate-200 h-[21px] my-2 w-[22px] rounded mr-1" />
            <div className="bg-slate-200 h-[21px] my-2 w-[22px] rounded mr-2" />
        </div>
    }
    return (
        <>
            <span
                className="text-slate-500 font-light text-[14px] tooltip tooltip-bottom p-2"
            >
                <strong className="ml-1">
                    <span className="mr-1">
                        {nepaliNumber(offset * limit + 1)}
                    </span>
                    देखि
                    <span className="mx-1">
                        {nepaliNumber((total < offset + 1 + limit) ? total : (offset + 1) * limit)}
                    </span>
                    सम्म
                </strong>

                <span className="ml-1">
                    ( कुल
                    <strong className="ml-1 mr-0.5">
                        {nepaliNumber(total)}
                    </strong>
                    )
                </span>
            </span>

            <button
                data-tip="Previous"
                className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 rounded-full disabled:text-slate-300"
                disabled={!canPrev}
                onClick={handlePrev}
            >
                <IoChevronBackSharp fontSize={18} />
            </button>
            <button
                data-tip="Next"
                className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 rounded-full disabled:text-slate-300"
                disabled={!canNext}
                onClick={handleNext}
            >
                <IoChevronForwardSharp fontSize={18} />
            </button>
        </>
    )
}

interface IPaginationProps {
    offset: number;
    limit: number;
    total: number;
    onNext: () => void;
    onPrev: () => void;
    isLoading: boolean | undefined;
}