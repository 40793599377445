import { FaDownload } from "react-icons/fa";

function LightBoxDownloadBtn({ image }: { image: string }) {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = image;
    link.href = image;
    link.click();
  };
  return (
    <button
      className="ril__toolbarItemChild ril__builtinButton"
      onClick={handleDownload}
    >
      <FaDownload />
    </button>
  );
}

export default LightBoxDownloadBtn;
