import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

//
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

//
import { logoutUser } from "../../../_redux/slices/auth";

//
import SubMenu from "../../reusables/SubMenu";

//
import SidebarData from "./_data";

import { BiMailSend } from "react-icons/bi";
import { FaSignOutAlt } from "react-icons/fa";

/**
 *
 */
export default function Sidebar({ showMenu }: ISidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const sso = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true";

  const handleSignOut = () => {
    if (
      sso &&
      process.env.REACT_APP_SSO_URL &&
      process.env.REACT_APP_SSO_LOGIN === "true"
    ) {
      const redirectLink = document.createElement("a");
      let url = `http://localhost:3000`;
      if (process.env.NODE_ENV === "production") {
        url = `${process.env.REACT_APP_SSO_URL}`;
      }
      let link = `${url}/signout`;

      redirectLink.href = link;
      redirectLink.click();
      console.log("isLoggedIn auth");
    } else {
      navigate("/login");
    }
    dispatch(logoutUser());
  };
  return (
    <div className="flex space-y-2 flex-col justify-between pt-2">
      <Link
        to="/templates"
        className={classNames(
          "flex items-center mx-3 rounded-xl bg-blue-400 text-center text-white hover:drop-shadow-2xl hover:scale-105 cursor-pointer hover:ease-in-out duration-500 py-3",
          showMenu ? "pl-5" : "justify-center"
        )}
      >
        <span className={classNames(showMenu ? "mr-[9px]" : "")}>
          <svg
            fill="#FFF"
            width="15px"
            height="15px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M30.133 1.552c-1.090-1.044-2.291-1.573-3.574-1.573-2.006 0-3.47 1.296-3.87 1.693-0.564 0.558-19.786 19.788-19.786 19.788-0.126 0.126-0.217 0.284-0.264 0.456-0.433 1.602-2.605 8.71-2.627 8.782-0.112 0.364-0.012 0.761 0.256 1.029 0.193 0.192 0.45 0.295 0.713 0.295 0.104 0 0.208-0.016 0.31-0.049 0.073-0.024 7.41-2.395 8.618-2.756 0.159-0.048 0.305-0.134 0.423-0.251 0.763-0.754 18.691-18.483 19.881-19.712 1.231-1.268 1.843-2.59 1.819-3.925-0.025-1.319-0.664-2.589-1.901-3.776zM22.37 4.87c0.509 0.123 1.711 0.527 2.938 1.765 1.24 1.251 1.575 2.681 1.638 3.007-3.932 3.912-12.983 12.867-16.551 16.396-0.329-0.767-0.862-1.692-1.719-2.555-1.046-1.054-2.111-1.649-2.932-1.984 3.531-3.532 12.753-12.757 16.625-16.628zM4.387 23.186c0.55 0.146 1.691 0.57 2.854 1.742 0.896 0.904 1.319 1.9 1.509 2.508-1.39 0.447-4.434 1.497-6.367 2.121 0.573-1.886 1.541-4.822 2.004-6.371zM28.763 7.824c-0.041 0.042-0.109 0.11-0.19 0.192-0.316-0.814-0.87-1.86-1.831-2.828-0.981-0.989-1.976-1.572-2.773-1.917 0.068-0.067 0.12-0.12 0.141-0.14 0.114-0.113 1.153-1.106 2.447-1.106 0.745 0 1.477 0.34 2.175 1.010 0.828 0.795 1.256 1.579 1.27 2.331 0.014 0.768-0.404 1.595-1.24 2.458z"></path>
          </svg>
        </span>
        {
          // showMenu && "नयाँ पत्र बनाउनुहोस्"
          showMenu && "Create a new letter"
        }
      </Link>

      <Link
        to="/compose"
        className={classNames(
          "flex items-center mx-3 rounded-xl py-3 bg-red-400 text-center text-white hover:drop-shadow-2xl hover:scale-105 cursor-pointer hover:ease-in-out duration-500",
          showMenu ? "pl-4" : "justify-center"
        )}
      >
        <BiMailSend
          fontSize={20}
          className={classNames(showMenu ? "mr-2" : "")}
        />
        {
          // showMenu && "कम्पोज गर्नुहोस्"
          showMenu && "Compose"
        }
      </Link>

      <ul
        className={classNames(
          "appmenu mt-3 rounded-r-xl flex-grow align-middle justify-start",
          showMenu ? "mr-5" : ""
        )}
      >
        {SidebarData.map((item) => {
          if (item.dontShow) {
            return null;
          }
          if (item.permittedRoles) {
            if (item.permittedRoles.includes(user?.role)) {
              return (
                <SubMenu
                  item={item}
                  key={item.id}
                  currentPath={location.pathname}
                  showMenu={showMenu}
                />
              );
            } else {
              return null;
            }
          }
          return (
            <SubMenu
              item={item}
              key={item.id}
              currentPath={location.pathname}
              showMenu={showMenu}
            />
          );
        })}
      </ul>

      <div
        className={classNames(
          "menu bg-base-100 rounded-xl mx-1",
          showMenu ? "w-56" : "mini-icons"
        )}
      >
        <button
          className={classNames(
            "flex items-center p-5 w-full font-semibold rounded-xl hover:bg-gray-100",
            showMenu ? "ml-[6px]" : ""
          )}
          onClick={handleSignOut}
        >
          <FaSignOutAlt className={showMenu ? "mr-2.5" : "mr-1"} />
          {showMenu && "Sign out"}
        </button>
      </div>
    </div>
  );
}

interface ISidebarProps {
  showMenu: boolean;
}
