import Loader from '../Loader';

/**
 *
 */
export default function PageLoading() {
	return (
		<div
			className='min-h-screen w-full flex justify-center items-center'
			style={{ backgroundColor: '#ffffff', color: '#D24DA6' }}
		>
			<Loader size={'64px'} />
		</div>
	);
}
