import classNames from "classnames";
import NepaliDate from "nepali-date-converter";
import { useEffect, useRef, useState } from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//
import ReceiverListInput from "../../../@compose/ReceiverListInput";

//
import FormInput from "../../../reusables/FormInput";

//
import { useOutsideClickListener } from "../../../../hooks/useOutsideClickListener";

//
import { ILabel } from "../../../../Pages/Compose/Compose";

//
import { IoMdClose } from "react-icons/io";

var adbs = require("ad-bs-converter");

/**
 * 
 * 
*/
export default function SearchBar() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchModalRef = useRef<any>(null);
    const searchRef = useRef<any>();
    const isDraftSearch = location.pathname === '/draft' || searchParams.get('isDraftSearch');
    const isSentSearch = location.pathname === '/sent' || searchParams.get('isSentSearch');

    const [showFilter, setShowFilter] = useState(false);

    // Filters
    const [text, setText] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [fromOrg, setFromOrg] = useState<ILabel[] | null>(null);
    const [toOrg, setToOrg] = useState<ILabel[] | null>(null);
    const [fromDate, setFromDate] = useState<string | undefined>();
    const [toDate, setToDate] = useState<string | undefined>();
    const [dartaNumber, setDartaNumber] = useState<string | undefined>();
    const [chalaniNumber, setChalaniNumber] = useState<string | undefined>();

    useEffect(() => {
        if (location.pathname === "/search/") {
            const from = searchParams.get('from');
            const fromOrg = searchParams.get('fromOrg');
            const to = searchParams.get('to');
            const toOrg = searchParams.get('toOrg');
            const toDate = searchParams.get('toDate');
            const fromDate = searchParams.get('fromDate');
            const text = searchParams.get('text');
            const dartaNumber = searchParams.get('darta_number');
            const chalaniNumber = searchParams.get('chalani_number');

            let searchArray = [];

            if (fromOrg && from) {
                searchArray.push(`पठाउने=(${fromOrg})`);
                setFromOrg([{
                    label: fromOrg,
                    value: from
                }]);
            }
            if (toOrg && to) {
                searchArray.push(`पाउने=(${toOrg})`);
                setToOrg([{
                    label: toOrg,
                    value: to
                }]);
            }
            if (fromDate) {
                let formDateBs = adbs.ad2bs(fromDate.replaceAll('-', '/'));
                let date = `${formDateBs?.ne?.year}-${formDateBs?.ne?.month}-${formDateBs?.ne?.day}`;
                searchArray.push(`देखि=(${date})`);
                setFromDate(date);
            }
            if (toDate) {
                let toDateBs = adbs.ad2bs(toDate.replaceAll('-', '/'));
                let date = `${toDateBs?.ne?.year}-${toDateBs?.ne?.month}-${toDateBs?.ne?.day}`;
                searchArray.push(`सम्म=(${date})`);
                setToDate(date);
            }
            if (dartaNumber) {
                searchArray.push(`दर्ता नं.=${dartaNumber}`);
                setDartaNumber(dartaNumber);
            }
            if (chalaniNumber) {
                searchArray.push(`चलानी नं.=${chalaniNumber}`);
                setChalaniNumber(chalaniNumber)
            }
            if (text) {
                searchArray.push(text);
                setText(text);
            }

            setSearchFilter(searchArray.join(' '));
        }
    }, [location, searchParams]);

    const handleFromOrgChange = (value: ILabel[]) => {
        setFromOrg(value);
    };

    const handleToOrgChange = (value: ILabel[]) => {
        setToOrg(value);
    };

    const handleTextChange = (value: string) => {
        setText(value)
    }

    const handleShowFilter = () => {
        setShowFilter(true);
    }

    const handleSearch = () => {
        let url = '/search/?';
        const queryParams = new URLSearchParams();
        if (text) {
            queryParams.append("text", text);
        }
        if (fromOrg && fromOrg[0]) {
            queryParams.append("from", fromOrg[0].value);
            queryParams.append("fromOrg", fromOrg[0].label);
        }
        if (toOrg && toOrg[0]) {
            queryParams.append("to", toOrg[0].value);
            queryParams.append("toOrg", toOrg[0].label);
        }
        if (fromDate) {
            let dateObj = new NepaliDate(fromDate);
            let adObj = dateObj.getAD()
            queryParams.append("fromDate", `${adObj.year}-${adObj.month + 1}-${adObj.date}`);
        }
        if (toDate) {
            let dateObj = new NepaliDate(toDate);
            let adObj = dateObj.getAD()
            queryParams.append("toDate", `${adObj.year}-${adObj.month + 1}-${adObj.date}`);
        }
        if (dartaNumber) {
            queryParams.append("darta_number", dartaNumber);
        }
        if (chalaniNumber) {
            queryParams.append("chalani_number", chalaniNumber);
        }
        if (isDraftSearch) {
            queryParams.append("isDraftSearch", 'true');
        }
        if (isSentSearch) {
            queryParams.append("isSentSearch", 'true');
        }
        url += queryParams;
        navigate(url);
        setShowFilter(false);
    }

    useOutsideClickListener(searchModalRef, () => {
        if (showFilter) {
            setShowFilter(false);
        }
    });

    const handleClearFilters = () => {
        setSearchFilter('')
        setText('');
        setFromOrg(null);
        setToOrg(null);
        setFromDate(undefined);
        setToDate(undefined);
        setDartaNumber(undefined);
        setChalaniNumber(undefined);

        if (isSentSearch) {
            navigate('/sent');
        }
        else if (isDraftSearch) {
            navigate('/draft');
        }
        else {
            navigate('/');
        }
    }

    useEffect(() => {
        if (searchModalRef.current && searchRef.current && searchModalRef.current.shouldFocus) {
            searchRef.current.focus();
            searchModalRef.current.shouldFocus = false;
        }
    }, [searchFilter])

    const handleEnterPress = (value: string) => {
        setText(value);
        handleSearch();
    }

    const handleDartaNumberChange = (value: any) => {
        setDartaNumber(value);
    }
    const handleChalaniNumberChange = (value: any) => {
        setChalaniNumber(value);
    }

    return (
        <div className="grow relative ml-[12px]" ref={searchModalRef}>
            <div className={classNames("h-[3rem] border-0 shadow-sm form-control w-2/3 relative rounded-lg ", showFilter ? "drop-shadow-xl bg-white outline-none  hover:outline-none focus:outline-none" : "bg-[#e7eff7]")}>
                {
                    searchFilter ?
                        <input id="search" value={searchFilter} onChange={() => {
                            if (searchModalRef.current) {
                                searchModalRef.current.shouldFocus = true;
                            }
                            handleClearFilters();
                        }
                        }
                            className={classNames("h-full w-[90%] bg-inherit border-none focus:outline-none active:outline-none pl-12")} />
                        :
                        <FormInput innerRef={searchRef} id="text" label="" type="Text" defaultValue={text} getInputData={handleTextChange}
                            className={classNames("!h-12 w-[90%] placeholder:text-slate-600 bg-inherit border-none focus:outline-none active:outline-none pl-12")}
                            placeholder="पत्र खोजी" noMarginBottom
                            onEnterPress={handleEnterPress} />
                }

                <button className="btn btn-ghost btn-circle absolute" onClick={handleSearch}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </button>


                {searchFilter &&
                    <button className="btn btn-ghost btn-circle absolute right-12" onClick={handleClearFilters}>
                        <IoMdClose fontSize={24} />
                    </button>
                }

                {
                    !showFilter &&
                    <button className="btn btn-ghost btn-circle absolute right-0" onClick={handleShowFilter}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
                        </svg>
                    </button>
                }
            </div>
            {/* Filter modal */}
            {
                showFilter &&
                <div className="form-control w-2/3 absolute bg-white drop-shadow-md rounded-lg z-10 top-[52px] p-5" >
                    <div className="flex">
                        <div className="w-[10%] mt-2">
                            <label htmlFor="from" className="text-slate-600">पठाउने</label>
                        </div>

                        <div className="w-[90%]">
                            <ReceiverListInput
                                defaultLabel={""}
                                className="mb-0"
                                value={fromOrg}
                                onChange={handleFromOrgChange}
                                key={fromOrg && fromOrg.length > 0 ? fromOrg[0].value : 'ds'} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-[10%] mt-2">
                            <label htmlFor="to" className="text-slate-600">पाउने</label>
                        </div>

                        <div className="w-[90%]" key={toOrg && toOrg.length > 0 ? toOrg[0].value : 'ds'} >
                            <ReceiverListInput
                                defaultLabel={""}
                                className="mb-0"
                                value={toOrg}
                                onChange={handleToOrgChange}
                            />
                        </div>
                    </div>

                    {
                        isDraftSearch ?
                            <div className="flex mb-4">
                                <div className="w-[10%] mt-2">
                                    <label htmlFor="from" className="text-slate-600">चलानी नं.</label>
                                </div>

                                <div className="w-[90%]">
                                    <FormInput id='dartanumber' placeholder="चलानी नं. लेख्नु होस्" type="Text" defaultValue={chalaniNumber} getInputData={handleChalaniNumberChange} subType="number" className="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2 " noMarginBottom />
                                </div>
                            </div>
                            :
                            <div className="flex mb-4">
                                <div className="w-[10%] mt-2">
                                    <label htmlFor="from" className="text-slate-600">दर्ता नं.</label>
                                </div>

                                <div className="w-[90%]">
                                    <FormInput id='dartanumber' placeholder="दर्ता नं. लेख्नु होस्" type="Text" defaultValue={dartaNumber} getInputData={handleDartaNumberChange} subType="number" className="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2" noMarginBottom />
                                </div>
                            </div>
                    }

                    <div className="flex">
                        <div className="w-[10%] mt-1">
                            <label htmlFor="from" className="text-slate-600">मिति
                            </label>
                        </div>

                        <div className="w-[90%] flex items-center">
                            <div className="flex-1">
                                <NepaliDatePicker inputClassName="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2"
                                    className=""
                                    value={fromDate}
                                    onChange={(value: string) => setFromDate(value)}
                                    options={{ calenderLocale: "ne", valueLocale: "en" }} />
                            </div>

                            <div className="mx-4">
                                देखि
                            </div>

                            <div className="flex-1">
                                <NepaliDatePicker inputClassName="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2"
                                    className=""
                                    value={toDate}
                                    onChange={(value: string) => setToDate(value)}
                                    options={{ calenderLocale: "ne", valueLocale: "en" }} />
                            </div>

                            <div className="ml-4">
                                सम्म
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 flex  justify-end">
                        <button className="btn bg-blue-500 outline-none border-0 hover:bg-blue-400" onClick={handleSearch}>खोज्नुहोस्</button>
                    </div>
                </div>
            }
        </div >
    )
}