import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";

/**
 * 
 */
export default function ProtectedRoute({ allowedRoles, redirectPath = '/', children }: IProtectedRouteProps) {
    const user = useAppSelector(state => state.auth.user);

    let isAllowed = allowedRoles.includes(user?.role);

    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />
    }

    return children ? children : <Outlet />;
}

interface IProtectedRouteProps {
    allowedRoles: string[];
    redirectPath?: string;
    children?: any;
}