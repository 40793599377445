import { AiFillCheckCircle, AiFillPlusCircle } from "react-icons/ai";
import PageTitle from "../../components/app/PageTItle";
import TopBar from "../../components/app/topbar";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";
import { BsFillTrashFill } from "react-icons/bs";
import Loader from "../../components/Loader";
import { useState } from "react";
import Modal from "../../components/reusables/Modal";
import FormInput from "../../components/reusables/FormInput";
import { useAppDispatch } from "../../hooks/redux";
import { setSuccessToastMessage } from "../../_redux/slices/toast";
import { VscLoading } from "react-icons/vsc";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

export default function FiscalYear() {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const { data: fiscalYears, isLoading } = useQueryHook({
        queryName: 'fiscal-years',
        queryRoute: 'fiscal-year/',
    });

    const handleFormSubmitSuccess = () => {
        dispatch(setSuccessToastMessage('आर्थिक वर्ष सफलतापूर्वक सिर्जना गरियो।'));
        queryClient.invalidateQueries(['fiscal-years']);
        handleHideForm();
    }

    const { mutate, isLoading: loadingFormSubmit } = useMutationHook({
        queryRoute: 'fiscal-year/',
        options: {
            onSuccess: handleFormSubmitSuccess
        }
    })

    const [isActive, setIsActive] = useState(false);
    const [fiscalYear, setFiscalYear] = useState('');
    const [showFiscalYearForm, setShowFiscalYearForm] = useState(false);

    const handleShowForm = () => setShowFiscalYearForm(true);
    const handleHideForm = () => {
        setFiscalYear('');
        setIsActive(false);
        setShowFiscalYearForm(false)
    };

    const handleFiscalYear = (value: string) => { setFiscalYear(value) };
    const handleActiveChange = (event: any) => { setIsActive(event.target.checked) };

    const handleSubmit = () => {
        mutate({
            title: fiscalYear,
            is_active: isActive
        })
    };
    return (
        <>
            <TopBar
                showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="आर्थिक वर्ष" />

                    {
                        !showFiscalYearForm &&
                        <button className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:drop-shadow-xl hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3"
                            onClick={handleShowForm}
                        >
                            <AiFillPlusCircle fontSize={18} className="mr-2" />

                            नयाँ आर्थिक वर्ष
                        </button>
                    }

                </div>


                <Modal id="" show={showFiscalYearForm} handleHide={handleHideForm} showClose>
                    {showFiscalYearForm &&
                        <div className="card shadow">
                            <h3 className="text-2xl font-semibold">नयाँ आर्थिक वर्ष</h3>

                            <div className="my-4">
                                <div className="mb-2">
                                    <FormInput id="fiscalyear" label="आर्थिक वर्ष" type="Text" className='h-[2.4rem]' getInputData={handleFiscalYear} />
                                </div>

                                <label className="flex items-center">
                                    <input type="checkbox" className="mr-2" onChange={handleActiveChange} />
                                    चालु आर्थिक वर्ष?
                                </label>
                            </div>


                            <div className="flex w-full justify-between">
                                <button className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:drop-shadow-xl hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2 min-w-[120px] justify-center disabled:bg-blue-400"
                                    onClick={handleSubmit}
                                    disabled={loadingFormSubmit}
                                >
                                    {
                                        loadingFormSubmit ?
                                            <VscLoading className="animate-spin" />
                                            :
                                            <>
                                                <AiFillPlusCircle fontSize={18} className="mr-2" />

                                                पेश गर्नुहोस्
                                            </>
                                    }
                                </button>

                                <button className="flex items-center rounded-xl border border-red-500 text-center text-red-500 hover:drop-shadow-xl hover:bg-red-500 hover:text-white cursor-pointer hover:ease-in-out duration-500 px-3 py-2" onClick={handleHideForm}>
                                    <AiFillPlusCircle fontSize={18} className="mr-2" />

                                    रद्द गर्नुहोस्
                                </button>
                            </div>
                        </div>}

                </Modal>



                <div className="settings-content-container">
                    <div className="overflow-x-auto">
                        <table className="table w-full">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th className="text-xl">आर्थिक वर्ष	</th>
                                    <th className="text-xl text-end">कार्यहरू</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ?
                                        <tr>
                                            <td colSpan={2}>
                                                <Loader isLoading />
                                            </td>
                                        </tr>
                                        :
                                        fiscalYears?.data?.results?.map((fiscalYear: IFiscalYear) => <FiscalYearRow key={fiscalYear.id} fiscalYear={fiscalYear} />)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

interface IFiscalYear { id: number; title: string; is_active: boolean }

const FiscalYearRow = ({ fiscalYear }: IFiscalYearRowProps) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const { mutate: patchActiveYear, isLoading: patchLoading } = useMutationHook({
        queryRoute: `fiscal-year/${fiscalYear.id}/`,
        method: 'patch',
        options: {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['fiscal-years']);
                dispatch(setSuccessToastMessage('आर्थिक वर्ष सफलतापूर्वक सम्पादन गरियो।'));
            }
        }
    });

    const { mutate: deleteActiveYear, isLoading: deleteLoading } = useMutationHook({
        queryRoute: `fiscal-year/${fiscalYear.id}/`,
        method: 'delete',
        options: {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['fiscal-years']);
                dispatch(setSuccessToastMessage('आर्थिक वर्ष सफलतापूर्वक हटाइयो।'));
            }
        }
    });

    return (
        <tr key={fiscalYear.id}>
            <td className={`flex items-center ${fiscalYear.is_active ? 'font-bold text-green-500' : ''}`}>
                {fiscalYear.title}

                {fiscalYear.is_active && <AiFillCheckCircle fontSize={20} className="ml-2" />}
            </td>
            <td>
                {!fiscalYear.is_active && <span className="flex justify-end">
                    <button className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2 mr-2 w-[150px]" disabled={patchLoading} onClick={() => patchActiveYear({ is_active: true })}>
                        {
                            patchLoading ?
                                <Loader isLoading className="!p-0 text-center w-full" iconClassName="!text-white" /> :
                                <>
                                    <AiFillCheckCircle fontSize={18} className="mr-2" />

                                    सक्रिय गर्नुहोस्
                                </>
                        }
                    </button>

                    <button className="flex items-center rounded-xl bg-red-500 text-center text-white hover:bg-red-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2 w-[120px]" disabled={deleteLoading} onClick={() => Swal.fire({
                        title: 'के तपाई यो आर्थिक वर्ष delete गर्न चाहनुहुन्छ?',
                        // text: "You want to delete this Patrachar!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#60A5FA',
                        cancelButtonColor: '#f87171',
                        confirmButtonText: 'सुनिश्चित गर्नुहोस्',
                        cancelButtonText: 'रद्द गर्नुहोस्',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            deleteActiveYear({});
                        }
                    })}>
                        {
                            deleteLoading ?
                                <Loader isLoading className="!p-0 w-full" iconClassName="!text-white" /> :
                                <>
                                    <BsFillTrashFill fontSize={18} className="mr-2" />

                                    हटाउनुहोस्
                                </>
                        }
                    </button>
                </span>}
            </td>
        </tr >
    )
}

interface IFiscalYearRowProps {
    fiscalYear: IFiscalYear;
}