import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Key, useMemo, useState, useCallback } from 'react';
import { useMutationHook, useQueryHook } from '../../hooks/react-query/useQueryHook';

//
import Table, { tableDataType, tableHeadType } from '../../components/reusables/Table';
import UploadDocumentModal from '../../components/reusables/UploadDocumentModal';

//
import { FiMail } from 'react-icons/fi';
import { IoStar, IoStarOutline } from 'react-icons/io5';

//
import Avatar from '../../components/reusables/Avatar';
import PreviewModal from '../../components/reusables/PreviewModal';

//
import { setErrorToastMessage } from '../../_redux/slices/toast';
import TopBar from '../../components/app/topbar';
import { nepaliNumber } from '../../_helpers/methods';
import Loader from '../../components/app/Loader';
import { englishNumberConverter } from '../../_helpers/template';

//
var adbs = require('ad-bs-converter');

const LIMIT = 50;


/**
 *
 */
export default function SearchPage() {
	const queryClient = useQueryClient();
	const [searchParams] = useSearchParams();

	const text = searchParams.get('text');
	const from = searchParams.get('from');
	const to = searchParams.get('to');
	const fromDate = searchParams.get('fromDate');
	const toDate = searchParams.get('toDate');
	const dartaNumber = searchParams.get('darta_number');
	const chalaniNumber = searchParams.get('chalani_number');
	const isDraftSearch = searchParams.get('isDraftSearch');
	const isSentSearch = searchParams.get('isSentSearch');

	const [isListView, setIsListView] = useState(false);
	const [previewPaperId, setPreviewPaperId] = useState<undefined | Key>();

	const [total, setTotal] = useState(0);
	const [offset, setOffset] = useState(0);
	const [selectedItemsId, setSelectedItemsId] = useState<Key[]>([]);

	const handleItemSelect = useCallback(
		(id: Key) => {
			const itemIdx = selectedItemsId.findIndex((selectedItemId) => selectedItemId === id);
			if (itemIdx >= 0) {
				let tempSelectedItemsId = [...selectedItemsId];
				tempSelectedItemsId.splice(itemIdx, 1);
				setSelectedItemsId(tempSelectedItemsId);
			} else {
				let tempSelectedItemsId = [...selectedItemsId];
				tempSelectedItemsId.push(id);
				setSelectedItemsId(tempSelectedItemsId);
			}
		},
		[selectedItemsId]
	);

	const { mutate: starMail } = useMutationHook({
		queryRoute: 'inbox/pin/',
		options: {
			onSuccess: (data) => {
				try {
					queryClient.invalidateQueries(['search']);
					setSelectedItemsId([]);

					return data;
				} catch (error) {
					console.log('ERROR star mails', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				setErrorToastMessage('Something went wrong');
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	const { mutate: unStarMail } = useMutationHook({
		queryRoute: '/inbox/unpin/',
		options: {
			onSuccess: (data) => {
				try {
					queryClient.invalidateQueries(['search']);
					setSelectedItemsId([]);

					return data;
				} catch (error) {
					console.log('ERROR star mails', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				setErrorToastMessage('Something went wrong');
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	const handleUnstarClick = useCallback(
		(id: Key) => {
			unStarMail({ papers: [id] });
		},
		[unStarMail]
	);

	const handleStarClick = useCallback(
		(id: Key) => {
			starMail({ papers: [id] });
		},
		[starMail]
	);

	const toggleListView = () => {
		setIsListView((prev) => !prev);
	};

	const handleNextPage = () => {
		setOffset((prev) => prev + 1);
	};

	const handlePrevPage = () => {
		setOffset((prev) => prev - 1);
	};

	const headers: tableHeadType[] = useMemo(() => {
		return [
			{
				id: 1,
				cell: (value: tableDataType) => (
					<span className="flex space-x-2 items-center">
						<input
							type="checkbox"
							name="select1"
							id="select1"
							className="cursor-pointer checkbox h-4 w-4 rounded-sm ml-2 block"
							defaultChecked={selectedItemsId.includes(value?.id)}
							onClick={() => handleItemSelect(value?.id)}
							key={selectedItemsId[0] ?? '1'}
						/>
						<label htmlFor="" className="label !ml-0 !p-0">
							{value?.pinned ? (
								<span
									className="cursor-pointer !ml-0 p-2 text-yellow-400  hover:bg-yellow-200 hover:ease-in-out duration-500 hover:rounded-full"
									onClick={() => handleUnstarClick(value?.id)}
								>
									<IoStar fontSize={17} />
								</span>
							) : (
								<span
									className="cursor-pointer !ml-0 p-2 hover:bg-slate-200 hover:ease-in-out duration-500 hover:rounded-full"
									onClick={() => handleStarClick(value?.id)}
								>
									<IoStarOutline fontSize={17} />
								</span>
							)}
						</label>
					</span>
				),
				noLink: true,
				dataClassName: 'w-[5%] border-l-[3px]',
			},
			{
				id: 2,
				cell: (value: tableDataType) => (
					<div className="flex items-center justify-start space-x-2 pl-5 text-sm">
						{isDraftSearch && (
							<>
								{value.chalani_number && (
									<div className="tooltip tooltip-bottom" data-tip={`चलानी नं.: ${nepaliNumber(value.chalani_number)}`}>
										<span>च नं.: {nepaliNumber(value.chalani_number)}</span>
									</div>
								)}
							</>
						)}
						{isSentSearch && (
							<div className="flex space-x-4 items-center">
								<span className="text-sm font-normal">To:</span>
								<span className="ml-2">
									<Avatar size="sm" name={value?.receiver} id={String(value?.receiver_id)} />
									<span className="ml-2 text-sm">{value?.receiver}</span>
								</span>
							</div>
						)}

						{!isSentSearch && !isDraftSearch && (
							<>
								<Avatar size="sm" name={value?.sender} id={String(value?.id)} />
								<span className="text-sm">{value?.sender}</span>
							</>
						)}
					</div>
				),
				dataClassName: 'w-[20%]',
			},
			{
				id: 3,
				// title: 'message',
				cell: (value: tableDataType) => (
					<div className="text-sm flex">
						{value?.title && (
							<div className="flex space-x-2 items-center font-medium pl-5">
								<span>{value?.title}</span>
							</div>
						)}
						{value?.label && (
							<div className="flex space-x-2 items-center font-medium pl-5">
								<span>{value?.label}</span>
							</div>
						)}

						{value.darta_number && (
							<div className="tooltip tooltip-bottom" data-tip={`दर्ता नं.: ${nepaliNumber(value.darta_number)}`}>
								<span>#{nepaliNumber(value.darta_number)}</span> <span> &nbsp;| &nbsp;</span>
							</div>
						)}

						{value.subject && <span className="font-bold">{value.subject}</span>}

						{value.message && <span className="text-sm">&nbsp;- &nbsp;{value.message?.substring(0, 80)}</span>}

						{value.remarks && <span className="text-sm text-light pl-1">{value.remarks?.substring(0, 80)}</span>}
					</div>
				),
				dataClassName: 'w-[65%]',
			},
			{
				id: 4,
				cell: (value: tableDataType) => {
					const { ne } = adbs.ad2bs(dayjs(value.date).format('YYYY/M/D'));
					const nepaliDate = `${ne.year}/${ne.month.padStart(2, '०')}/${ne.day.padStart(2, '०')}`;

					const time = dayjs(value.date).format('hh:mm A');
					const isToday = dayjs(value.date).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD');
					return (
						<span className="text-slate-500 mr-4">
							<div className="tooltip tooltip-bottom" data-tip={`${nepaliDate} ${time}`}>
								{isToday ? (
									<span className="text-sm text-slate-600">{time}</span>
								) : (
									<span className="text-sm text-slate-600">{nepaliDate}</span>
								)}
							</div>
						</span>
					);
				},
				dataClassName: 'w-[10%]',
			},
		];
	}, [isDraftSearch, isSentSearch, handleItemSelect, handleStarClick, handleUnstarClick, selectedItemsId]);

	const getQueryParams = (): [string, string | number][] | string[][] | undefined => {
		let paramsArray = [];
		paramsArray.push(['limit', String(LIMIT)]);
		paramsArray.push(['offset', String(offset * LIMIT)]);
		if (text) {
			paramsArray.push([isDraftSearch === 'true' ? 'label' : 'subject', text]);
		}
		if (from) {
			paramsArray.push(['from', from]);
		}
		if (to) {
			paramsArray.push(['to', to]);
		}
		if (fromDate) {
			paramsArray.push(['from_date', fromDate]);
		}
		if (toDate) {
			paramsArray.push(['to_date', toDate]);
		}
		if (dartaNumber) {
			paramsArray.push(['serial_number', englishNumberConverter(dartaNumber)]);
		}
		if (chalaniNumber) {
			paramsArray.push(['chalani_number', englishNumberConverter(chalaniNumber)]);
		}

		if (paramsArray.length) {
			return paramsArray;
		}
		return undefined;
	};

	const getQueryRoute = () => {
		if (isDraftSearch) {
			return '/paper/draft/';
		}
		if (isSentSearch) {
			return '/paper/';
		}
		return '/inbox/';
	};

	const { data: searchData, isLoading } = useQueryHook({
		queryName: 'search',
		queryRoute: getQueryRoute(),
		params: getQueryParams(),
		options: {
			onSuccess: (data: any) => {
				setTotal(data?.data?.count);
				return data;
			}
		}
	});

	const generateData = (data: any) => {
		if (data?.results?.length) {
			const final = data?.results?.map((result: any) => {
				const sender =
					result.organization_name || result.sender_fullname_np || result.sender_organization_name || result.receiver;

				return {
					id: result.id,
					label: result.label,
					title: result?.values?.title,
					sender: sender,
					receiver: result.receiver,
					receiverId: result.receiver_id,
					subject: result.subject,
					message: result.message,
					remarks: result.remarks,
					chalani_number: result.chalani_number,
					read: true,
					darta_number: result.darta_id || result.darta_number,
					date: result.created_at,
					pinned: result.pinned,
					priority: result.priority
				};
			});
			return final;
		}
		return [];
	};

	const finalData = searchData?.data ? generateData(searchData.data) : [];

	const { mutate: ArchiveMail } = useMutationHook({
		queryRoute: 'paper/archive/',
		options: {
			onSuccess: (data) => {
				try {
					Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
					queryClient.invalidateQueries(['inbox']);

					return data;
				} catch (error) {
					console.log('ERROR Archiving', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				setErrorToastMessage('Something went wrong');
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	let render = null;

	if (isLoading) {
		render = (
			<div className="no-top-content-container">
				<div className="bg-white flex justify-center items-center flex-col rounded m-3 py-20">
					<Loader size="64px" />
				</div>
			</div>
		);
	} else if (finalData.length < 1) {
		render = (
			<div className="no-top-content-container">
				<div className="bg-white flex justify-center items-center flex-col rounded m-3 py-20">
					<div className="mb-6 p-9 w-[190px] h-[190px] rounded-full bg-gray-200 flex justify-center items-center">
						<FiMail size={82} color="gray" />
					</div>

					<div className="text-xl">तपाईंको कुनै पनि पत्राचार फेला परेन।</div>
				</div>
			</div>
		);
	} else {
		render = (
			<div className="no-top-content-container">
				{/* Table */}
				<Table
					headers={headers}
					data={finalData}
					isLoading={isLoading}
					className=""
					noHeader
					compact={isListView}
					rowLinkDestination={isSentSearch ? 'sent-mail' : isDraftSearch ? 'preview' : 'inbox'}
				/>

				{/* preview Modal */}
				{/* // TODO new page for inbox/:id ?? */}
				<PreviewModal paperId={previewPaperId} setPreviewPaperId={setPreviewPaperId} />
				<UploadDocumentModal paperId={previewPaperId} />
			</div>
		);
	}

	return (
		<>
			<TopBar
				pagination={{
					limit: LIMIT,
					offset: offset,
					total: total,
					onNext: handleNextPage,
					onPrev: handlePrevPage,
				}} showRefresh showBackButton isListView={isListView} toggleListView={toggleListView} />

			{render}
		</>
	);
}
