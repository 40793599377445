import { Axios } from '../configs/axiosConfig';

const OrganizationServices = {
	async getOrganizationData() {
		try {
			const response = await Axios().get('/organizations/?limit=50');
			return response.data.data;
		} catch (error) {
			console.log(error);
		}
	},
	async getSakhaData() {
		try {
			const response = await Axios().get('/sakha/?limit=50');
			return response.data.data;
		} catch (error) {
			console.log(error);
		}
	}
};

export default OrganizationServices;
