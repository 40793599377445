import dayjs from "dayjs";
import NepaliDate from "nepali-date-converter";

//
var adbs = require("ad-bs-converter");

/**
 * 
 */
export default function TimeStamp({ timeStamp }: ITimeStampProps) {

    if (!timeStamp) return null;

    const { ne } = adbs.ad2bs(dayjs(timeStamp).format("YYYY/M/D"));

    const nepaliDate = `${ne.year}/${ne.month.padStart(2, "०")}/${ne.day.padStart(2, "०")}`;

    const time = dayjs(timeStamp).format("hh:mm A");

    return (
        <div className="text-sm text-slate-600"><div className='flex flex-col items-end'>
            <div>{nepaliDate}</div>
            <div>{time}</div>
        </div></div>
    )
}

interface ITimeStampProps {
    timeStamp: string;
}