//
import classNames from "classnames";
import { getInitials, getRandomColor } from "../../../_helpers/methods";

/**
 * 
 */
export default function Avatar(props: IAvatarProps) {
  const size = props.size ?? "md";
  const nameInitial = getInitials(props.name);
  const background = getRandomColor(props.id);

  let sizeClass = "";
  if (size === "md") {
    sizeClass += "w-10 ";
  } else if (size === "sm") {
    sizeClass += "w-8";
  }

  return (
    <div className="avatar">
      <div className={`${sizeClass} rounded-full`}>
        {
          props.image ?
            <img src={props.image} alt={props.name} />
            :
            <span className={classNames('flex justify-center items-center h-full w-full text-white', background)}
              style={{
                backgroundColor: background ?? undefined
              }}>
              {nameInitial}
            </span>

        }
      </div>
    </div >
  );
}

interface IAvatarProps {
  size?: "md" | "sm";
  name: string;
  id: string;
  image?: null | string;
}
