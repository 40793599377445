import { nepaliNumbers } from '../_datas';
import { COLORS } from '../constants/constants';
import NepaliDate from 'nepali-date-converter';

export const nepaliNumber = (number: number) => {
	const strNum = String(number);
	let strNepNum = '';
	for (let i = 0; i < strNum.length; i++) {
		strNepNum +=
			nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
			(nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
			'';
	}
	return strNepNum;
};

export const errorMessageHandler = (error: { [key: string]: string[] | string }): string | null => {
	let errorMessage = '';
	if (typeof error === 'object') {
		Object.entries(error).forEach(([key, value]) => {
			if (value.length > 0) {
				errorMessage = value[0];
			} else {
				errorMessage = '';
			}
		});
		if (errorMessage === "") {
			errorMessage = typeof error.message === 'string' ? error.message : '';
		}
	}
	return errorMessage;
};

export const getInitials = (name: string) => {
	if (!name) return null;
	name = String(name);
	var nameArray = name.split(' ');

	if (nameArray.length <= 1) {
		return name.charAt(0).toUpperCase();
	}

	return nameArray[0].charAt(0).toUpperCase() + nameArray[nameArray.length - 1].charAt(0).toUpperCase();
};

export const getRandomColor = (id: string) => {
	if (id) {
		const lastChar = id.slice(-1);
		return COLORS[lastChar as keyof typeof COLORS];
	} else {
		return null;
	}
};

export function isObjEmpty(obj: any) {
	return Object.keys(obj).length === 0;
}

export function getTodayDate(): string {
	let today = new NepaliDate().format('YYYY-MM-DD');
	today = String(today);
	return today;
}
