import { useEffect, useState } from "react";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";
import TopBar from "../../components/app/topbar";
import PageTitle from "../../components/app/PageTItle";
import { AiFillPlusCircle } from "react-icons/ai";
import Loader from "../../components/Loader";
import { FaEdit } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useForm } from "react-hook-form";
import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";
import { nepaliNumberConverter } from "../../_helpers/template";
import InputField from "../../components/reusables/@InputElements/InputField";
import Password from "../../components/reusables/@InputElements/Password/Password";
import { VscLoading } from "react-icons/vsc";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

export default function ITOUsers() {
    const { data, isLoading } = useQueryHook({
        queryName: `ito-users`,
        queryRoute: `/profile/ito/`
    });

    const [showITOUserForm, setShowITOUserForm] = useState(false);
    const [itoUserDetail, setITOUserDetail] = useState(null);

    const handleShowITOUserForm = () => setShowITOUserForm(true);
    const handleHideITOUserForm = () => {
        setITOUserDetail(null);
        setShowITOUserForm(false);
    };

    const handleEditWardUser = (wardUser: any) => {
        setShowITOUserForm(true);
        setITOUserDetail(wardUser)
    }

    return (
        <>
            <TopBar
                showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="ITO प्रयोगकर्ता" />

                    {
                        !showITOUserForm &&
                        <button onClick={handleShowITOUserForm} className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:drop-shadow-xl hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3"
                        >
                            <AiFillPlusCircle fontSize={18} className="mr-2" />

                            नयाँ ITO प्रयोगकर्ता
                        </button>
                    }
                </div>

                {
                    showITOUserForm &&
                    <ITOUserForm
                        userDetail={itoUserDetail}
                        handleHideITOUserForm={handleHideITOUserForm} />
                }


                <div className="settings-content-container">

                    <table className="table w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                <th className="text-xl">नाम</th>
                                <th className="text-xl">ईमेल</th>
                                <th className="text-xl">फोन</th>
                                <th className="text-xl">पद</th>
                                <th className="text-xl">कार्यहरू</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ?
                                    <tr>
                                        <td colSpan={5}>
                                            <Loader isLoading />
                                        </td>
                                    </tr>
                                    :
                                    data?.data?.results?.length > 0 ?
                                        data?.data?.results.map((user: any) => <ITOUserRow key={user.id} user={user} onEdit={handleEditWardUser} />)
                                        :
                                        <tr>
                                            <td colSpan={5} className="text-center text-md">कुनै प्रयोगकर्ता फेला परेन
                                            </td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

interface IITOUserFormProps {
    userDetail: any;
    handleHideITOUserForm: () => void;
}

const ITOUserRow = ({ user, onEdit }: { user: any, onEdit: (user: any) => void }) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const { mutate: deleteITO } = useMutationHook({
        queryRoute: `/profile/ito/${user.id}/`,
        method: 'delete',
        options: {
            onSuccess: () => {
                console.log('dleted');
                dispatch(setSuccessToastMessage(' ITO प्रयोगकर्ता सफलतापूर्वक हटाइयो।'));
                queryClient.invalidateQueries(['ito-users']);
            }
        }
    })

    const handleDeleteWardUser = () => {
        Swal.fire({
            title: 'के तपाई यो ITO प्रयोगकर्ता delete गर्न चाहनुहुन्छ?',
            // text: "You want to delete this Patrachar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60A5FA',
            cancelButtonColor: '#f87171',
            confirmButtonText: 'सुनिश्चित गर्नुहोस्',
            cancelButtonText: 'रद्द गर्नुहोस्',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteITO({});
            }
        });
    }

    return (<tr key={user.id}>
        <td>{user.fullname_np}</td>
        <td>{user.email}</td>
        <td>{user.phone_np}</td>
        <td>{user.post_np}</td>
        <td className="flex">
            <button className="flex items-center justify-center rounded bg-blue-500 text-center text-white hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2 mr-2" onClick={() => onEdit(user)}>
                <FaEdit fontSize={18} />
            </button>

            <button className="flex items-center justify-center rounded bg-red-500 text-center text-white hover:bg-red-600 cursor-pointer hover:ease-in-out duration-500 px-3 py-2" onClick={() => handleDeleteWardUser()}>
                <BsFillTrashFill fontSize={18} />
            </button>
        </td>
    </tr>

    )
}

const ITOUserForm = ({ userDetail, handleHideITOUserForm }: IITOUserFormProps) => {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, setValue } = useForm<any>({
        mode: 'onBlur',
        values: {},
    });
    const { language } = useAppSelector(state => state.language);

    const { mutate: patchWardUser, isLoading } = useMutationHook({
        queryRoute: `/profile/ito/${userDetail?.id}/`,
        method: 'patch',
        options: {
            onSuccess: () => {
                handleHideITOUserForm();
                queryClient.invalidateQueries(['ito-users']);
                dispatch(setSuccessToastMessage("प्रयोगकर्ता सफलतापूर्वक सम्पादन गरियो!"));
            },
            onError: () => {
                dispatch(setErrorToastMessage("कार्य असफल भएको छ। कृपया केहि समय पछि पुन: प्रयास गर्नुहोस्!"))
            }
        }
    });

    const { mutate: postWardUser, isLoading: postLoading } = useMutationHook({
        queryRoute: `/profile/ito/`,
        options: {
            onSuccess: () => {
                handleHideITOUserForm();
                dispatch(setSuccessToastMessage("प्रयोगकर्ता सफलतापूर्वक सिर्जना गरियो!"))
            },
            onError: () => {
                dispatch(setErrorToastMessage("कार्य असफल भएको छ। कृपया केहि समय पछि पुन: प्रयास गर्नुहोस्!"))
            }
        }
    });

    const loading = isLoading || postLoading;

    useEffect(() => {
        if (userDetail) {
            setValue('fullname_np', userDetail?.fullname_np);
            setValue('fullname_en', userDetail?.fullname_en);
            setValue('password', userDetail?.password);
            setValue('phone_en', userDetail?.phone_en);
            setValue('email', userDetail?.email);
            setValue('post_np', userDetail?.post_np);
            setValue('post_en', userDetail?.post_en);
        }
    }, [setValue, userDetail]);

    const onSubmit = (data: any) => {
        if (data.phone_en.length !== 10) {
            return dispatch(setErrorToastMessage('Phone number is invalid'));
        }

        data.phone_np = nepaliNumberConverter(data.phone_en);

        let body = {
            ...data,
            role: 'ito_admin',
            username: data.email,
            organization: 1
        };

        if (userDetail) {
            patchWardUser(body);
        }
        else {
            postWardUser(body);
        }
    };

    return (
        <div className="card shadow-md border p-4 mb-10">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                <div>
                    <div className="card-header-custom mb-4">
                        <h3 className="text-xl text-blue-500 font-semibold">{userDetail ? 'प्रयोगकर्ता सम्पादन' : ' नयाँ प्रयोगकर्ता'}</h3>
                    </div>

                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-1 mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">नाम (नेपालीमा)</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'fullname_np'}
                                    placeHolder={'पूरा नाम भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.fullname_np}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">नाम (अंग्रेजीमा)</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'fullname_en'}
                                    placeHolder={'Enter full name'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.fullname_en}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">Phone No.</label>
                                <InputField
                                    type="number"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'phone_en'}
                                    placeHolder={'Enter phone number'}
                                    maxLength={10}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.phone_en}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">ईमेल</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={'email'}
                                    placeHolder={'ईमेल भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    isRequired={true}
                                    setValue={setValue}
                                    value={userDetail && userDetail.email}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">पासवर्ड</label>
                                <Password
                                    register={register}
                                    id="password"
                                    placeholder={'Enter password'}
                                    value={userDetail && userDetail.password}
                                    className={`mini-field ${userDetail?.eventType === 'edit' ? '' : 'wardform-input textbox_req'}`}
                                    mini-field
                                    isRequired={!userDetail}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">पद</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={`post_np`}
                                    placeHolder={'पद भर्नुहोस्'}
                                    maxLength={50}
                                    register={register}
                                    setValue={setValue}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="flex flex-col">
                                <label className="wardform-label mb-0">Designation</label>
                                <InputField
                                    type="text"
                                    className={`wardform-input textbox_req`}
                                    language={language}
                                    elementId={`post_en`}
                                    placeHolder={'Enter post'}
                                    maxLength={50}
                                    register={register}
                                    setValue={setValue}
                                    isRequired={true}
                                    value={userDetail && userDetail.post_en}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                        <button
                            className="flex items-center mx-3 rounded-xl bg-red-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3 mr-2"
                            onClick={handleHideITOUserForm}
                            disabled={loading}>
                            रद्द गर्नुहोस्
                        </button> <button type="submit" className="flex justify-center items-center rounded-xl bg-blue-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3 min-w-[90px]"
                            disabled={loading}>
                            {
                                loading ? <VscLoading className='animate-spin' /> : 'पेश गर्नुहोस्'
                            }
                        </button>
                    </div>
                </div>
            </form >
        </div >
    )
}