import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import InputField from "../../components/reusables/@InputElements/InputField";

import { yupResolver } from '@hookform/resolvers/yup';
import { wardValidationSchema } from "../../_helpers/schema-validation";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";
import { useMutationHook } from "../../hooks/react-query/useQueryHook";
import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";
import PageTitle from "../../components/app/PageTItle";
import TopBar from "../../components/app/topbar";
import Loader from "../../components/Loader";

function WardForm() {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        formState,
    } = useForm({
        mode: 'onBlur',
        shouldUnregister: true,

        resolver: yupResolver(wardValidationSchema),
    });

    const errors: any = formState.errors;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { mutate: patchWard, isLoading: patchLoading } = useMutationHook({
        queryRoute: `ward/${id}/`,
        method: 'patch',
        options: {
            onSuccess: (data) => {
                dispatch(setSuccessToastMessage('वडा कार्यालय सम्पादन गरियो '));
                navigate('/settings/ward-offices');
            },
            onError: (err: any) => {
                console.log(err, 'err')
                Object.keys(err.error).forEach((errorKey) => {
                    dispatch(setErrorToastMessage(err.error[errorKey][0]));
                });
            }
        }
    })

    const { mutate: postWard, isLoading: postLoading } = useMutationHook({
        queryRoute: '/ward/',
        options: {
            onSuccess: (data) => {
                dispatch(setSuccessToastMessage('नया वडा कार्यालय थपियो '));
                navigate('/settings/ward-offices');
            },
            onError: (err: any) => {
                Object.keys(err.error).forEach((errorKey) => {
                    dispatch(setErrorToastMessage(err.error[errorKey][0]));
                });
            }
        }
    })

    const language = useAppSelector(state => state.language.language);
    const [defaultVal, setDefaultVal] = useState<any>(null);
    const [nagarpalikas, setNagarpalikas] = useState<any>(null);

    const { isLoading: isWardFormLoading } = useQueryHook({
        queryName: `ward-${id}`,
        queryRoute: `/ward/${id}/`,
        options: {
            enabled: Boolean(id),
            onSuccess: (res: any) => setDefaultVal(res?.data)
        }
    });

    useEffect(() => {
        if (defaultVal) {
            setValue('name_np', defaultVal?.name_np || defaultVal?.name);
            setValue('name_en', defaultVal?.name_en || defaultVal?.name);
            setValue('phone_np', defaultVal?.phone_np || defaultVal?.phone);
            setValue('phone_en', defaultVal?.phone_en || defaultVal?.phone);
            setValue('address_np', defaultVal?.address_np || defaultVal?.address);
            setValue('email', defaultVal?.email);
            setValue('address_en', defaultVal?.address_en || defaultVal?.address);
        }
    }, [setValue, defaultVal]);

    useQueryHook({
        queryName: "nagarpalika",
        queryRoute: '/nagarpalika/',
        options: {
            onSuccess: (res: any) => setNagarpalikas(res?.data?.results)
        }
    });

    const onSubmit = async (data: any) => {
        data.phone = data.phone_en;

        let body = {
            ...data,
        };


        console.log(body && id, body, id, 'body && id')
        if (body) {
            if (id) {
                patchWard(body);
            }
            else {
                postWard(body);
            }
        }
    };

    const isLoading = patchLoading || postLoading;

    return (
        <>
            <TopBar
                showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="वडा कार्यालय" />

                </div>


                <div className="settings-content-container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card shadow border rounded-lg p-4 mb-10">
                            <div className="card-header-custom mb-3">
                                <h3 className="text-xl text-blue-500 font-semibold">वडा कार्यालयको विवरण</h3>
                            </div>

                            {
                                id && isWardFormLoading ?
                                    <Loader isLoading />
                                    :
                                    <div className="grid grid-cols-4 gap-3">
                                        {/* <div className="col-span-1 flex flex-col mb-3">
                                            <label className="wardform-label mb-0">Nagarpalika</label>
                                            {nagarpalikas && (
                                                <select
                                                    className="py-2 outline-none border rounded px-1"
                                                    {...register('nagarpalika')}
                                                    defaultValue={defaultVal?.nagarpalika}
                                                >
                                                    {nagarpalikas?.map((nagarpalika: any) => {
                                                        return (
                                                            <option key={nagarpalika.id} value={nagarpalika.id}>
                                                                {nagarpalika.name_np}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            )}
                                        </div> */}
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="wardform-label mb-0">वडा नम्बर</label>
                                            <InputField
                                                type="text"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'name_np'}
                                                placeHolder={'वडा नम्बर भर्नुहोस्'}
                                                maxLength={50}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.name_np}
                                                disabled={isLoading}
                                            />
                                            {errors.name_np ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.name_np.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="wardform-label mb-0">वडा नम्बर (अंग्रेजीमा)</label>
                                            <InputField
                                                type="text"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'name_en'}
                                                placeHolder={'Enter ward number'}
                                                maxLength={50}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.name_en}
                                                disabled={isLoading}
                                            />
                                            {errors.name_en ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.name_en.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="wardform-label mb-0">ईमेल</label>
                                            <InputField
                                                type="text"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'email'}
                                                placeHolder={'ईमेल भर्नुहोस्'}
                                                maxLength={50}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.email}
                                                disabled={isLoading}
                                            />
                                            {errors.email ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.email.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="mb-0 wardform-label">फोन</label>
                                            <InputField
                                                type="number"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'phone_np'}
                                                placeHolder={'फोन न. भर्नुहोस्'}
                                                maxLength={10}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.phone_np}
                                                disabled={isLoading}
                                            />
                                            {errors.phone_np ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.phone_np.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="mb-0 wardform-label">Phone No.</label>
                                            <InputField
                                                type="number"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'phone_en'}
                                                placeHolder={'Enter phone number'}
                                                maxLength={10}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.phone_en}
                                                disabled={isLoading}
                                            />
                                            {errors.phone_en ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.phone_en.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="mb-0 wardform-label">ठेगाना</label>
                                            <InputField
                                                type="text"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'address_np'}
                                                placeHolder={'ठेगाना भर्नुहोस्'}
                                                maxLength={20}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.address_np}
                                                disabled={isLoading}
                                            />
                                            {errors.address_np ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.address_np.message}
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="col-span-1 flex flex-col mb-3">
                                            <label className="mb-0 wardform-label">Address</label>

                                            <InputField
                                                type="text"
                                                className={`wardform-input textbox_req`}
                                                language={language}
                                                elementId={'address_en'}
                                                placeHolder={'Enter address'}
                                                maxLength={50}
                                                register={register}
                                                isRequired={true}
                                                setValue={setValue}
                                                value={defaultVal?.address_en}
                                                disabled={isLoading}
                                            />
                                            {errors.address_en ? (
                                                <p
                                                    className="text-red-400 text-sm"
                                                >
                                                    {errors.address_en.message}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                            }

                            <div className="d-flex justify-content-end align-items-center mt-3">
                                <button className="flex items-center mx-3 rounded-xl bg-red-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3" onClick={() => navigate(-1)} disabled={isLoading}>
                                    रद्द गर्नुहोस्
                                </button>

                                <button type="submit" disabled={isLoading} className="flex items-center rounded-xl bg-blue-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3">
                                    {isLoading ? <VscLoading className="animate-spin" /> : <>{id ? 'अपडेट' : 'सुरक्षित'} गर्नुहोस्</>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default WardForm