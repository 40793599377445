import { FC, ReactNode, ReactElement } from "react";
import cls, { Argument } from "classnames";
import { MdDone, MdWarning, MdOutlineError, MdInfo } from "react-icons/md";

interface ModalProps {
  id: string;
  children: ReactNode | ReactElement | String | null | undefined;
  className?: Argument;
  show?: boolean;
  handleHide?: () => void;
  showClose?: boolean;
}

const Modal: FC<ModalProps> = ({
  id,
  children,
  showClose,
  className,
  show,
  handleHide,
}) => {
  return (
    <>
      {id && <input type="checkbox" id={id} className="modal-toggle" />}
      <div id={id} className={`modal ${show ? "modal-open" : ""}`}>
        <div className={cls("modal-box relative", className)}>
          {showClose && (
            <label
              htmlFor={id}
              className="btn btn-sm btn-circle absolute right-2 top-2 z-20"
              onClick={() => {
                handleHide && handleHide();
              }}
            >
              ✕
            </label>
          )}

          <div className="modal-children | max-h-[730px] overflow-auto">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
interface IAlert {
  modalId: string;
  message: string;
  type: "success" | "info" | "warning" | "error";
  onSuccess?: () => void;
  isLoading?: boolean;
  successButton?: string;
}
const Alert: FC<IAlert> = ({
  modalId,
  message,
  type = "warning",
  onSuccess,
  isLoading = false,
  successButton,
}) => {
  let icon: ReactElement | string = "";
  switch (type) {
    case "success":
      icon = <MdDone fontSize={40} />;
      break;
    case "warning":
      icon = <MdWarning fontSize={40} />;
      break;
    case "info":
      icon = <MdInfo fontSize={40} />;
      break;
    case "error":
      icon = <MdOutlineError fontSize={40} />;
      break;
    default:
      icon = <MdOutlineError fontSize={40} />;
      break;
  }
  return (
    <>
      <input type="checkbox" id={modalId} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <div className="py-4">
            <p className="text-xl font-bold text-center">{message}</p>
            <div className="grid place-content-center py-8">
              <span
                className={cls(
                  "p-8 rounded-full ",
                  type === "success"
                    ? "bg-success/30 text-success"
                    : type === "info"
                    ? "bg-info/30 text-info"
                    : type === "warning"
                    ? "bg-warning/30 border-warning text-warning"
                    : "bg-error/30 text-error"
                )}
              >
                {icon}
              </span>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <label
              role="button"
              htmlFor={modalId}
              className={cls(
                "btn",
                type === "success"
                  ? "btn-success"
                  : type === "info"
                  ? "btn-info"
                  : type === "warning"
                  ? "btn-warning"
                  : "btn-error",
                isLoading ? "btn-disabled" : ""
              )}
              aria-disabled={isLoading}
              onClick={onSuccess}
            >
              {successButton ?? "पेश गर्नुहोस्"}
            </label>
            {type !== "info" && (
              <label htmlFor={modalId} className="btn btn-neutral">
                रद्द गर्नुहोस
              </label>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Object.assign(Modal, { Alert: Alert });
