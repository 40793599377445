import { useState, useRef, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import { useNavigate, useParams } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { languageSelector } from '../../_redux/slices/languages';

// import { incrementPaperCountHandler } from "../../_redux/slices/paper";
import { VscLoading } from 'react-icons/vsc';
import TableModal from './TableModal';
import { generateEditedPreview, generatePreview, insertSubStringAtString } from '../../_helpers/template';
import Elements from './Elements';
import { useAppSelector } from '../../hooks/redux';
import SifarishPreviewModal from '../SifarishPreviewModal/SifarishPreviewModal';
import { useMutationHook } from '../../hooks/react-query/useQueryHook';
import { setErrorToastMessage, setSuccessToastMessage } from '../../_redux/slices/toast';
import NepaliDate from 'nepali-date-converter';
import ChalaniForm from '../../Pages/Template/ChalaniForm';
import { isObjEmpty } from '../../_helpers/methods';
import ReferenceContainer from './ReferenceContainer';

function FormElements(props) {
	const paperData = props.singleValue;
	const templateLabel = props.label;
	const signatory = props.signatory;
	const formInputValues = props.vals;
	const disableChalaniForm = props.disableChalaniForm;
	const actionRef = useRef(null);
	const todayNepaliDate = new NepaliDate().format('YYYY-MM-DD');

	const printRef = useRef();
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { language } = useSelector(languageSelector);
	const {
		control,
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [show, setShow] = useState(false);
	const [previewData, setPreviewData] = useState(null);
	const [previewFormData, setPreviewFormData] = useState({});
	const [savedPaperId, setSavedPaperId] = useState(null);
	const [isPrint, setIsPrint] = useState(false);
	const user = useAppSelector((state) => state?.auth?.user);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [wardAdmin, setWardAdmin] = useState(false);
	const [hasNewTableAdded, setHasNewTableAdded] = useState(false);
	const [editTable, setEditTable] = useState(null);
	const [templateWithPreviewData, setTemplateWithPreviewData] = useState({});
	const [chalaniNum, setChalaniNum] = useState(props.chalaniNumber);
	const [chalaniCreatedOrg, setChalaniCreatedOrg] = useState(props.chalaniCreatedOrg);
	const [issueId, setIssueId] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [chalaniError, setChalaniError] = useState({});
	const [tableTitles, setTableTitles] = useState({});

	const paperId = params?.paperId;
	useEffect(() => {
		if (paperId) {
			setSavedPaperId(paperId);
		}
	}, [paperId]);

	useEffect(() => {
		if (props.data) {
			setTemplateWithPreviewData(props.data);
		}
	}, [props.data]);

	const [isSaved, setIsSaved] = useState(() => {
		if (paperData?.issue_id) {
			return true;
		}
		return false;
	});

	useEffect(() => {
		if (paperData?.issue_id) {
			setIssueId(paperData?.issue_id);
		}
	}, [paperData?.issue_id]);

	const handleIsSaved = (value) => {
		setIsSaved(value);
	};
	const handleIssueId = (value) => {
		setIssueId(value);
	};

	//
	const { mutate: CreatePaper, isLoading: isLoadingCreatePaper } = useMutationHook({
		queryRoute: 'paper/draft/',
		options: {
			onSuccess: (data) => {
				try {
					dispatch(setSuccessToastMessage('Patrachar Successfully saved!'));
					setSavedPaperId(data?.id);
					if (props.onTemplateSave) {
						props.onTemplateSave();
					}
					setIssueId(data?.issue_id);
					if (actionRef.current?.shouldPrint) {
						actionRef.current.shouldPrint = false;

						setTimeout(() => {
							handlePrint();
						}, 100)
					} else {
						navigate('/draft');
					}
					return data;
				} catch (error) {
					dispatch(setErrorToastMessage('Something went wrong!'));
					console.log('ERROR Composing', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				dispatch(setErrorToastMessage('Something went wrong'));
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	//
	const { mutate: PatchPaper, isLoading: isLoadingPatchPaper } = useMutationHook({
		queryRoute: `paper/draft/${savedPaperId}/`,
		method: 'patch',
		options: {
			onSuccess: (data) => {
				try {
					dispatch(setSuccessToastMessage('Patrachar Successfully saved!'));
					setSavedPaperId(data?.id);
					if (props.onTemplateSave) {
						props.onTemplateSave();
					}
					if (actionRef.current?.shouldPrint) {
						actionRef.current.shouldPrint = false;

						setTimeout(() => {
							handlePrint();
						}, 100)
					}
					return data;
				} catch (error) {
					console.log('ERROR Composing', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				dispatch(setErrorToastMessage('Something went wrong'));
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	const handleAddTable = (formValue) => {
		let tempTemplateWithPreviewData = { ...templateWithPreviewData };

		let tempValues = [...tempTemplateWithPreviewData.values];
		tempValues.push(formValue);
		let tempPreviewData = tempTemplateWithPreviewData.preview;
		if (!hasNewTableAdded && tempPreviewData) {
			let tableAlreadyPresentInPreview = tempPreviewData.includes('{{table}}');
			if (!tableAlreadyPresentInPreview) {
				let lastDivIndex = tempPreviewData.lastIndexOf('</div>');
				tempPreviewData = insertSubStringAtString(lastDivIndex, tempPreviewData, '{{table}}');
				setHasNewTableAdded(true);
			}
		}
		tempTemplateWithPreviewData.preview = tempPreviewData;
		setTemplateWithPreviewData({
			...tempTemplateWithPreviewData,
			values: tempValues,
		});
		setTableTitles((prev) => ({ ...prev, [formValue.id]: formValue.title }));
	};

	const handleEditTableTemplate = (formValue, Rows) => {
		const rowsNames = Rows.map((row, index) => row.name.replace('.', ''));

		let formInputValues = getValues();
		let editTableInputValues = formInputValues[editTable.tableId];

		editTableInputValues = editTableInputValues.reduce((acc, cur) => {
			let currRows = { ...cur };
			let newRows = {};
			let index = 0;
			Object.keys(currRows).forEach((key) => {
				let simplifiedKey = key.replace(/[0-9]/g, ''); // removed digits;
				let simplifiedKeyPWF = simplifiedKey.replace('___', '');
				simplifiedKey = simplifiedKeyPWF.replace('####', '');
				if (rowsNames.includes(simplifiedKey)) {
					newRows[`${index}___${simplifiedKeyPWF}`.replace('.', '####')] = currRows[key];
					index++;
				}
			});
			return [...acc, newRows];
		}, []);

		setValue(editTable.tableId, editTableInputValues);
		let tempTemplateWithPreviewData = { ...templateWithPreviewData };

		let tempValues = [...tempTemplateWithPreviewData.values];
		tempValues = tempValues.map((values, index) => {
			if (values.id === formValue.id && index === formValue.index) {
				return formValue;
			}
			return values;
		});

		setTemplateWithPreviewData({
			...tempTemplateWithPreviewData,
			values: tempValues,
		});
		setTableTitles((prev) => ({ ...prev, [formValue.id]: formValue.title }));
		setEditTable(null);
	};
	const handleDeleteTable = (id, element) => {
		let tempTemplateWithPreviewData = { ...templateWithPreviewData };

		let tempValues = [...tempTemplateWithPreviewData.values];
		let tableElementId = tempValues.findIndex((value) => value.id === element.id && value.title === element.title);
		let tableElement = tempValues[tableElementId];
		let tempTableElement = { ...tableElement };
		if (tempTableElement.row) {
			let tempTableRows = tempTableElement.row.reduce((acc, cur) => {
				let row = cur.filter((item) => item.id !== id);
				return [...acc, ...row];
			}, []);
			if (tempTableRows.length === 0) {
				tempValues.splice(tableElementId, 1);
				setTemplateWithPreviewData({
					...tempTemplateWithPreviewData,
					values: tempValues,
				});
			} else {
				tempTableElement.row = tempTableRows;
				tempValues[tableElementId] = tempTableElement;
				setTemplateWithPreviewData({
					...tempTemplateWithPreviewData,
					values: tempValues,
				});
			}
			setValue(id, []);
		}
	};

	const handleEditTable = (id, element, index) => {
		setEditTable({ tableId: id, ...element, index });
	};

	const onSubmit = (formData) => {
		let tempFormData = {
			label: templateLabel,
			ward_admin: signatory?.value,
			...formInputValues,
			...formData,
			...props.chalaniData,
		};
		//chalani validation
		let errors = {};
		Object.entries(props.chalaniData).forEach(([key, value]) => {
			if (!value && key !== 'remarks') {
				errors[key] = 'कृपया यी फिल्ड भर्नुहोस्';
			}
		});

		if (Object.values(errors).length > 0) {
			return setChalaniError(errors);
		}

		//chalani clear
		setChalaniError({});

		let FORMDATA = { ...tempFormData };
		setPreviewFormData(FORMDATA);

		setWardAdmin(formData?.ward_admin);
		if (formInputValues) {
			const tempPreviewData = generateEditedPreview(
				{
					previewData: formInputValues.previewData,
					meta: templateWithPreviewData.meta,
				},
				FORMDATA,
				tableTitles,
				user
			);
			setPreviewData(tempPreviewData);
		} else if (templateWithPreviewData?.preview) {
			const tempPreviewData = generatePreview(templateWithPreviewData, FORMDATA, tableTitles, user);

			setPreviewData(tempPreviewData);
		}
		handleShow();
	};

	const handlePrint = useReactToPrint({
		content: () => {
			return printRef.current;
		},
		onAfterPrint: () => {
			try {
				setIsPrint(true);
				// dispatch(incrementPaperCountHandler(params.paperId));
			} catch (err) {
				console.log(err, 'Error after print');
			}
		},
	});

	useEffect(() => {
		if (isPrint && savedPaperId) {
			// dispatch(incrementPaperCountHandler(savedPaperId));
			setIsPrint(false);
		}
	}, [isPrint, savedPaperId, dispatch]);

	//
	const { mutateAsync: SavePaper } = useMutationHook({
		queryRoute: 'paper/draft/',
		axiosOptions: {
			multipart: false,
		},
	});

	const { mutateAsync: GenerateChalani } = useMutationHook({
		env: 'REACT_APP_DARTACHALANI_URL',
		queryRoute: '/chalani/',
	});

	const handleGenerateClick = async (val = {}, preview) => {
		try {
			setIsLoading(true);
			const body = {
				label: templateLabel,
				values: templateWithPreviewData,
				form_values: { ...val, previewData: preview },
				__save_and_print: true,
			};

			let response = {};
			if (savedPaperId) {
				PatchPaper(body);
				response.id = savedPaperId;
			} else {
				response = await SavePaper(body);
				setSavedPaperId(response.id);
				setIssueId(response.issue_id);
			}

			let generateChalaniObject = (({
				receiving_department,
				related_branch,
				remarks,
				sending_medium,
				address,
				paper_count,
			}) => ({ receiving_department, related_branch, remarks, sending_medium, address, paper_count }))(val);

			generateChalaniObject.subject = val.label;
			generateChalaniObject.organization = user?.organization?.id;
			generateChalaniObject.date = todayNepaliDate;
			generateChalaniObject.paper_id = response.id;
			generateChalaniObject.created_by_fullname = `${user?.organization?.name_np}_${user?.organization?.type === "ward" ? "वडा" : "शाखा"}`;

			if (signatory?.value) {
				generateChalaniObject.sender = signatory.value.split('_')[0];
				generateChalaniObject.senders_post = signatory.value.split('_')[1];
			}

			const res = await GenerateChalani(generateChalaniObject);
			setChalaniNum(res.data?.serial_number);
			console.log(res.data, 'res')
			PatchPaper({ ...body, chalani_number: res.data?.serial_number });


			setChalaniCreatedOrg(res.data.created_by_fullname);
			setIsLoading(false);
		} catch (error) {
			console.log(error, 'error');
			dispatch(setErrorToastMessage('Something went wrong!'));
			setIsLoading(false);
		}
	};

	const handleTemplateSave = async (val = {}, preview, isFromSaveAndPrint = false) => {
		const body = {
			label: templateLabel,
			values: templateWithPreviewData,
			form_values: { ...val, previewData: preview },
		};
		if (isFromSaveAndPrint) {
			body.__save_and_print = true;
			actionRef.current.shouldPrint = true;
		}

		if (savedPaperId) {
			delete body.applicant;
			return PatchPaper(body);
		} else {
			body.paper_status = 'Approved';
			return CreatePaper(body);
		}
	};

	return (
		<div className="bg-white pt-2" ref={actionRef}>
			<div className="mb-2">
				<h3 className="text-3xl font-bold text-blue-600 border-b-2 border-blue-600 w-fit mb-5">
					{templateWithPreviewData.title}{' '}
				</h3>

				<Fragment>
					{templateWithPreviewData?.meta?.ward_kawa?.length > 0 ? (
						<div className="float-right">
							{templateWithPreviewData.category_title === 'English Format' ? 'Act. Chairman' : 'वडा का.वा'}{' '}
							<span>:</span>{' '}
							{`${templateWithPreviewData?.meta?.ward_kawa[0][
								templateWithPreviewData.category_title === 'English Format' ? 'name_en' : 'name_np'
							]
								}
                `}
							<input
								{...register('ward_admin')}
								value={`${templateWithPreviewData?.meta?.ward_kawa[0][
									templateWithPreviewData.category_title === 'English Format' ? 'name_en' : 'name_np'
								]
									}_${templateWithPreviewData?.meta?.ward_kawa[0][
									templateWithPreviewData.category_title === 'English Format' ? 'post_en' : 'post_np'
									]
									}`}
								hidden
							/>
						</div>
					) : (
						templateWithPreviewData?.meta?.ward_admins && (
							<div className="float-right">
								<label>
									{templateWithPreviewData.category_title === 'English Format' ? 'Ward Admins' : 'वडा अध्यक्ष'}
								</label>
								<select
									{...register('ward_admin')}
									className="w-fit"
									defaultValue={formInputValues && formInputValues.ward_admin}
								>
									{templateWithPreviewData.meta.ward_admins.map((ward_admins) => (
										<option
											value={`${ward_admins[templateWithPreviewData.category_title === 'English Format' ? 'name_en' : 'name_np']
												}_${ward_admins[templateWithPreviewData.category_title === 'English Format' ? 'post_en' : 'post_np']
												}`}
											key={`${ward_admins.name_np}_${ward_admins.post_np}`}
										>
											{ward_admins[templateWithPreviewData.category_title === 'English Format' ? 'name_en' : 'name_np']}
										</option>
									))}
								</select>
							</div>
						)
					)}
				</Fragment>
			</div>
			<form>
				<div className="mt-10">
					<Elements
						language={language}
						elements={templateWithPreviewData.values}
						register={register}
						errors={errors}
						setValue={setValue}
						control={control}
						values={formInputValues}
						category={templateWithPreviewData.category_title}
						handleDeleteTable={handleDeleteTable}
						handleEditTable={handleEditTable}
					/>
				</div>

				<div>
					<TableModal
						editTable={editTable}
						handleSave={handleAddTable}
						handleEditTableTemplate={handleEditTableTemplate}
						setEditTable={setEditTable}
					/>
				</div>

				<div>
					{templateWithPreviewData.config?.hasReference &&
						<ReferenceContainer
							setValue={setValue}
							defaultValue={formInputValues?.references}
						/>
					}
				</div>

				{!isObjEmpty(props.chalaniData) && (
					<div>
						{
							<ChalaniForm
								chalaniData={props.chalaniData}
								onInputChange={props.onChalaniInputChange}
								chalaniError={chalaniError}
								disable={disableChalaniForm}
							/>
						}
					</div>
				)}

				<div className="d-flex justify-content-between mb-3">
					<Fragment>
						<button
							className="btn btn-success"
							disabled={isLoadingCreatePaper || isLoadingPatchPaper}
							onClick={handleSubmit(onSubmit)}
						>
							{isLoadingCreatePaper || isLoadingPatchPaper ? <VscLoading className="spin" /> : 'Preview & Print'}
						</button>

						<SifarishPreviewModal
							show={show}
							chalaniNum={chalaniNum}
							chalaniCreatedOrg={chalaniCreatedOrg}
							paperCount={props.chalaniData?.paper_count}
							handleClose={handleClose}
							innerRef={printRef}
							isSaved={isSaved}
							handleIsSaved={handleIsSaved}
							issueId={issueId}
							handleIssueId={handleIssueId}
							paper={
								<div className="page">
									<div className="content">
										<Elements register={register} language={language} elements={templateWithPreviewData?.values} />
									</div>
								</div>
							}
							previewFormData={previewFormData}
							previewData={previewData}
							wardAdmin={wardAdmin}
							paperExtra={props.paperExtra}
							handlePrint={handlePrint}
							handleGenerateClick={handleGenerateClick}
							handleTemplateSave={handleTemplateSave}
							config={templateWithPreviewData.config}
							meta={templateWithPreviewData.meta}
							paperId={paperId}
							isEnglish={templateWithPreviewData.category_title === 'English Format'}
							isCitizenTemplate={params?.citizen && params?.citizen === 'citizen' ? true : false}
							isLoading={isLoading}
							showSave={!savedPaperId}
							showGenerate={!chalaniNum}
							showUpdate={savedPaperId}
							showSaveAndPrint={!savedPaperId}
							showUpdateAndPrint={savedPaperId}
							showPrint={chalaniNum && issueId}
							showEdit={true}
						/>
					</Fragment>
				</div>
			</form>
		</div>
	);
}

export default FormElements;
