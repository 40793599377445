import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";

//
import { FiMail } from "react-icons/fi";


const ACTIVEPATHS = ['inbox'];
/**
 *
 */
export default function InboxSidebarItem({ showMenu }: IInboxSidebarItem) {
  const location = useLocation();
  const PATH = "/";

  return (
    <NavLink
      to={PATH}
      className={({ isActive }) => {
        let showActive = false;
        ACTIVEPATHS.forEach((path: string) => {
          if (location.pathname.includes(path)) {
            showActive = true;
          }
        });
        if ((isActive && PATH) || showActive) {
          return "bg-[#eaf1fb] font-medium indicator w-full rounded-r-2xl";
        }
        else {
          return "indicator w-full font-light"
        }
      }
      }
      end
    >
      <div className={classNames("flex items-center py-3 w-full rounded-r-2xl",
        showMenu ? "gap-1 pl-8" : "justify-center mini-icons"
      )}>
        <FiMail className="flex-none" />
        {showMenu &&
          // <span className="ml-1 w-2/3">इनबक्स</span>
          <span className="ml-1 w-2/3">Inbox</span>
        }
        {/* {
          !!cacheInboxStatsData?.data?.unread &&
          <span className="flex-1 w-2/4 pr-1 text-sm">{cacheInboxStatsData?.data?.unread}</span>
        } */}
      </div>
    </NavLink>
  );
}

interface IInboxSidebarItem {
  showMenu: boolean;
}