import { FC } from "react";
import FormInput from "../../components/reusables/FormInput";
import DocumentUpload from "../../components/@compose/DocumentUpload";
import { IFile } from "../../components/@compose/DocumentUpload/DocumentUpload";

interface SettleModalProps {
  handleSettleRemarksChange: (text: string) => void;
  handleSettleMail: () => void;
  handleSettleFilesChange: (value: string[]) => void;
}

const SettleModal: FC<SettleModalProps> = ({
  handleSettleRemarksChange,
  handleSettleMail,
  handleSettleFilesChange,
}) => {
  const handleFileUpload = (files: IFile[]) => {
    handleSettleFilesChange(files.map((f: IFile) => f.file));
  };
  return (
    <div className="pt-4">
      <h1 className="text-3xl mb-4 text-[#60A5FA] font-semibold">फर्स्यौट</h1>

      <div className="mb-3 min-h-[220px]">
        <FormInput
          id="remarks"
          label="प्रतिक्रिया लेख्नुहोस"
          type="Text"
          subType="textarea"
          getInputData={handleSettleRemarksChange}
        />
      </div>

      <div className="mb-3 min-h-[220px]">
        <DocumentUpload isMulti onUpload={handleFileUpload} url="" />
      </div>

      <div className="flex justify-end">
        <label
          htmlFor="settle-modal"
          className="btn btn-primary text-white rounded-box"
          onClick={() => handleSettleMail()}
        >
          फर्स्यौट गर्नुहोस
        </label>
      </div>
    </div>
  );
};

export default SettleModal;
