import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

//
import PageLoading from '../../components/app/pageLoading';
import { handleGetUserData } from '../../_redux/slices/auth';
import { LOCAL_ACCESS_TOKEN_NAME } from '../../constants/constants';
import { useAppDispatch } from '../../hooks/redux';

//

/**
 *
 */
export default function ProtectedLayout() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	//
	const getSession = async () => {
		let isLoggedIn = false;
		const localAccess = localStorage.getItem(LOCAL_ACCESS_TOKEN_NAME);

		if (localAccess) {
			const result = await dispatch(handleGetUserData());
			isLoggedIn = result;
		}

		setIsLoading(false);

		if (isLoggedIn) {
			navigate('/');
		}
	};

	useEffect(() => {
		getSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Do not show the content initially
	if (isLoading) return <PageLoading />;

	return (
		<div>
			<Outlet />
		</div>
	);
}
