import React from "react";
import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../reusables/@InputElements/InputField";
import { nepaliNumber } from "../../../../_helpers/methods";

function Number({
  label,
  language,
  className,
  placeholder,
  register,
  errors = {},
  elementId,
  value,
  properties = {},
  setValue,
  isPublic,
  entity,
}) {
  if (isPublic && entity === "private") {
    return null;
  }

  let validation = {};
  if (properties) {
    validation = dynamicValidator(properties);
  }

  let errorMessage = '';
  if (errors[elementId]) {
    switch (errors[elementId].type) {
      case "required":
        errorMessage = 'कृपया यो फिल्ड भर्नुहोस् !'
        break;
      case "length":
        errorMessage = `यस फिल्डमा ${nepaliNumber(properties.length)} अंक हुनपर्द छ !`
        break;
      default:
        errorMessage = '';
    }
  }

  return (
    <div className="py-2 mb-2">
      <label htmlFor={elementId} className="relative mb-2">
        <label className="block font-semibold mb-1" htmlFor={elementId}>
          {label} :{" "}
        </label>

        <InputField
          type="number"
          language={language}
          className={`${className} ${properties?.isRequired ? "textbox_req" : ""
            } ${errors[elementId] ? "is-invalid" : ""} `}
          register={register}
          errors={errors}
          elementId={elementId}
          placeholder={placeholder}
          maxLength={properties?.length}
          value={value}
          properties={properties}
          validation={validation}
          setValue={setValue}
        />

        {
          errorMessage &&
          <div className="error-text">
            {errorMessage}
          </div>
        }
      </label>
    </div>
  );
}

export default Number;
