import { useEffect } from "react";
import { useState } from "react";
import { FaMinus, FaPlus, FaTable } from 'react-icons/fa'
import { useSelector } from "react-redux";
import short from "short-uuid";
import InputField from "../reusables/@InputElements/InputField";
import Modal from "../reusables/Modal";
const shortUUIDObj = short();

const columnSelectTypes = [
    { label: 'Text', value: 'text' },
    { label: 'Number', value: 'number' },
    { label: 'Select', value: 'select' },
]

const initialRows = [
    {
        name: 'क्र स.',
        id: '123',
        type: 'number'
    }
];
function TableModal({ editTable, setEditTable, handleSave, handleEditTableTemplate }) {
    const [rows, setRows] = useState(initialRows);
    const language = useSelector(state => state.language.language);
    const [maxRow, setMaxRow] = useState(5);
    const [minRow, setMinRow] = useState(1);
    const [title, setTitle] = useState('');
    const [editElementIndexes, setEditElementIndexes] = useState();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (editTable) {
            setShow(true)
            const tempEditTable = { ...editTable }
            const { title, row, tableId } = tempEditTable;

            for (let i = 0; i < row.length; i++) {
                for (let j = 0; j < row[i].length; j++) {
                    if (row[i][j].id === tableId) {
                        setMaxRow(+row[i][j].data.maxRow);
                        setMinRow(+row[i][j].data.minRow);
                        setRows(row[i][j].data.headings);
                        setEditElementIndexes({ i, j });
                    }
                }
            }

            setTitle(title);
        }
    }, [editTable]);

    const handleClose = () => {
        setShow(false);
        setTitle('');
        setMaxRow(5);
        setMinRow(1);
        setRows(initialRows);
        setEditTable(null);
    };

    const onSaveBtnClick = () => {
        if (!editTable) {
            let uuID = shortUUIDObj.new();
            let section = {
                id: uuID,
                key: "FormGroup",
                title: title,
                border: "",
                backgroundColor: "",
                row: [
                    [
                        {
                            id: `table-${uuID}`,
                            key: 'Table',
                            title: title,
                            data: {
                                maxRow: maxRow,
                                minRow: minRow,
                                headings: rows.map(row => ({ name: row.name, type: row.type, options: row.options })),
                                isStatic: false
                            }
                        },
                    ]
                ]
            }
            handleSave(section);
        }
        else {
            let tempEditTable = { ...editTable }
            let { tableId } = tempEditTable;
            let tempRow = [...tempEditTable.row];
            let tempTableRows = tempRow.reduce((acc, cur) => {
                let tempRow = cur.map(item => {
                    if (item.id === tableId) {
                        let tempItem = { ...item };
                        let tempItemData = { ...tempItem.data };
                        tempItemData.maxRow = maxRow;
                        tempItemData.minRow = minRow;
                        tempItemData.headings = rows;
                        tempItem.data = tempItemData;
                        return tempItem;
                    }
                    return item;
                });
                return [...acc, ...tempRow];
            }, []);
            tempRow[editElementIndexes.i] = tempTableRows;
            let section = {
                ...tempEditTable,
                title: title,
                row: tempRow
            }
            handleEditTableTemplate(section, rows);
        }

        setTitle('');
        setMaxRow(5);
        setMinRow(1);
        setRows(initialRows)
        setShow(false);
    }

    const onAddColumnClick = () => {
        setRows([
            ...rows,
            {
                id: shortUUIDObj.new(),
                name: '',
                type: 'text'
            }
        ])
    }

    const onRemoveClick = (id) => {
        let tempRows = [...rows];
        setRows(tempRows.filter(row => (row.id || row.name) !== id));
    }

    const handleUpdateColumn = (index, key, value) => {
        let tempRows = [...rows];
        let tempRow = tempRows[index];

        tempRow[key] = value;
        if (value === "select") {
            tempRow.options = [{ value: '' }]
        }
        tempRows[index] = tempRow;

        setRows(tempRows)
    }

    const handleAddOptionInColumn = (index, value) => {
        let tempRows = [...rows];
        let tempRow = tempRows[index];
        let options = tempRow.options ?? [];
        options.push({ value });
        tempRow.options = options;
        tempRows[index] = tempRow;

        setRows(tempRows)
    }

    const handleRemoveOptionInColumn = (index, optIdx) => {
        let tempRows = [...rows];
        let tempRow = tempRows[index];
        let options = tempRow.options ?? [];
        options.splice(optIdx, 1);

        tempRow.options = options;
        tempRows[index] = tempRow;

        setRows(tempRows);
    }
    const handleMaxRowsChange = (e) => {
        setMaxRow(e.target.value)
    }

    const handleMinRowsChange = (e) => {
        setMinRow(e.target.value)
    }

    const handleTitleChange = (value) => {
        setTitle(value)
    }

    return (
        <div>
            <div className="flex justify-center my-4">
                <span
                    onClick={() => setShow(true)} className='btn btn-secondary flex items-center justify-center w-fit'>
                    <FaTable className="mr-2 text-white" fill="white" /> Create Table
                </span>
            </div>

            <Modal id="table-create-modal" className="max-w-screen max-h-screen" show={show}>
                <div className="font-bold text-2xl border-b-2 border-gray-300 mb-4">
                    <div>Table {editTable ? "Editor" : "Creator"} </div>
                </div>
                <div>
                    <div className="grid grid-cols-3 gap-3">
                        <div className="col-span-1">Title: </div>
                        <div className="col-span-2">
                            <InputField
                                language={language} className="w-full px-2" type="text" value={title} getInputData={handleTitleChange} placeholder="Enter title" />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-3 mt-2">
                        <div className="col-span-1">Max rows: </div>
                        <div className="col-span-2"><input className="px-3 w-full border" type="number" value={maxRow} onChange={handleMaxRowsChange} /></div>
                    </div>

                    <div className="grid grid-cols-3 gap-3 mt-2">
                        <div className="col-span-1">Min rows: </div>
                        <div className="col-span-2"><input className="px-3 w-full border" type="number" value={minRow} onChange={handleMinRowsChange} /></div>
                    </div>


                    <div className="flex justify-between items-center mt-5 mb-4">
                        <h5 className="text-lg font-semibold">
                            Add Columns
                        </h5>

                        <button className="btn btn-secondary btn-sm" onClick={onAddColumnClick} type="button"><FaPlus className="mr-2" /> Add </button>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Column name</th>
                                <th style={{ width: '85px' }}>Type</th>
                                <th>Options</th>
                                <th>Remove</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                rows.map((row, index) => <tr className="mb-1" key={row.id || row.name}>
                                    <td className="p-1">{index + 1}</td>
                                    <td className="p-1">
                                        <InputField
                                            language={language}
                                            value={row.name}
                                            className="w-full py-1 px-2"
                                            getInputData={(value) => handleUpdateColumn(index, "name", value)}
                                            disabled={index === 0}
                                        />
                                    </td>
                                    <td className="p-1">
                                        <select
                                            value={row.type}
                                            className="w-full"
                                            disabled={index === 0}
                                            onChange={(e) => handleUpdateColumn(index, 'type', e.target.value)}>
                                            {columnSelectTypes.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
                                        </select>
                                    </td>
                                    <td className="p-1">
                                        {
                                            row.type === "select" ?
                                                <>
                                                    <InputField
                                                        elementId="optionInput"
                                                        language={language}
                                                        className="w-full mb-1" placeholder='Enter option'
                                                        onEnterPress={(value) => {
                                                            if (value) {
                                                                handleAddOptionInColumn(index, value);
                                                            }
                                                        }} />

                                                    <div >
                                                        <b className="mt-1 mb-2 text-sm" >Options: </b>
                                                        <ul className="px-2">
                                                            {
                                                                row.options.length < 2 ? <li className="text-muted">No options</li> :
                                                                    row.options.map((option, opt_idx) => {
                                                                        if (opt_idx === 0) return null;

                                                                        return <li className="flex justify-between items-center mb-1" key={option.value}>
                                                                            {option.value}
                                                                            <FaMinus className="cursor-pointer" onClick={() => handleRemoveOptionInColumn(index, opt_idx)} />
                                                                        </li>
                                                                    })
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                                :
                                                <div className="text-muted">N/A</div>
                                        }
                                    </td>
                                    <td className="p-1">

                                        <button className="btn btn-error btn-sm" onClick={() => onRemoveClick(row.id || row.name)}
                                            disabled={index === 0}
                                        ><FaMinus /></button></td>
                                </tr>)
                            }
                        </tbody>
                    </table>

                    <div className="mt-2">
                    </div>
                </div>

                <div>
                    <span className="btn btn-outline mr-2" onClick={handleClose}>
                        Close
                    </span>
                    <span className="btn btn-primary" type="button" onClick={onSaveBtnClick}>
                        Save Changes
                    </span>
                </div>
            </Modal>
        </div>
    )
}

export default TableModal