import { FC, Fragment, useMemo, useRef, useState, useEffect } from "react";
import cls from "classnames";
import UserInfo from "./UserInfo";
import { BsPrinter, BsChevronContract, BsChevronExpand } from "react-icons/bs";
import FormInput from "../../components/reusables/FormInput";
import ReceiverListInput from "../../components/@compose/ReceiverListInput";
import Modal from "../../components/reusables/Modal";

import {
  useMutationHook,
  useQueryHook,
} from "../../hooks/react-query/useQueryHook";
import { useParams } from "react-router-dom";
import Skeleton from "../../components/Skeleton/Skeleton";
import { ILabel } from "../Compose/Compose";
import { useReactToPrint } from "react-to-print";
import { useQueryClient } from "@tanstack/react-query";
import ImagePreviewer from "./ImagePreviewer";
import { VscLoading } from "react-icons/vsc";
import TopBar from "../../components/app/topbar";
import TimeStamp from "../../components/reusables/TimeStamp";
import Swal from "sweetalert2";
import {
  setErrorToastMessage,
  setSuccessToastMessage,
} from "../../_redux/slices/toast";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getTodayDate, nepaliNumber } from "../../_helpers/methods";
import Loader from "../../components/Loader";
import classNames from "classnames";
import { nepaliNumberConverter } from "../../_helpers/template";
import ForwardModal from "./ForwardModal";
import { Gallery } from "react-grid-gallery";
import { setLightBoxImage } from "../../_redux/slices/imagePreview";
import SettleModal from "./SettleModal";

const Mail = ({ isInbox }: IMailProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const attachmentPrintRef = useRef(null);
  const mailPrintRef = useRef(null);
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user);
  const activeFiscalYear = useAppSelector(
    (state) => state.auth.activeFiscalYear
  );

  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [receivers, setReceivers] = useState<ILabel[] | null>(null);
  const [forwardRemarks, setForwardRemarks] = useState<string>("");
  const [forwardRemarkFiles, setForwardRemarksFiles] = useState<string[]>([]);
  const [settlementFiles, setSettlementsFiles] = useState<string[]>([]);

  const [settleRemarks, setSettleRemarks] = useState<string>("");
  const [showDartaModal, setShowDartaModal] = useState<boolean>(false);

  const handleReceiveChange = (value: ILabel[]) => {
    setReceivers(value);
  };

  const handleForwardRemarksChange = (value: string) => {
    setForwardRemarks(value);
  };
  const handleForwardRemarkFilesChange = (value: string[]) => {
    setForwardRemarksFiles(value);
  };
  const handleSettleFilesChange = (value: string[]) => {
    setSettlementsFiles(value);
  };

  const handleSettleRemarksChange = (value: string) => {
    setSettleRemarks(value);
  };

  const { data, isLoading, isFetching } = useQueryHook({
    queryName: "mail",
    params: [["id", id ?? 1]],
    queryRoute: isInbox ? `/inbox/${id}/` : `/paper/${id}/`,
  });

  const fetchMail = (type: "forward" | "settle" | null) => {
    queryClient.invalidateQueries(["mail"]);
    queryClient.invalidateQueries(["inbox-stats"]);

    if (type) {
      let message = "";
      if (type === "forward") {
        message = "पत्र सफलतापूर्वक फरवार्ड गरियो!";
      } else if (type === "settle") {
        message = "पत्र सफलतापूर्वक फर्स्यौट गरियो!!";
      }

      dispatch(setSuccessToastMessage(message));
    }
  };

  const { mutate: forwardMutate, isLoading: isForwardLoading } =
    useMutationHook({
      queryRoute: `inbox/${data?.data?.id}/forward/`,
      options: {
        onSuccess: () => fetchMail("forward"),
        onError: () => dispatch(setErrorToastMessage("पत्र फर्वार्ड असफल भय!")),
      },
    });

  useQueryHook({
    queryName: `read,${id}`,
    queryRoute: `inbox/${id}/read/`,
    options: {
      enabled: Boolean(isInbox && !data?.data?.read),
    },
  });

  const { data: paperStatusData } = useQueryHook({
    queryName: `paper-status_${id}`,
    queryRoute: `/paper/${id}/status/`,
    options: {
      enabled: !isInbox,
    },
  });

  const { mutate: settleMutate, isLoading: isSettleLoading } = useMutationHook({
    queryRoute: `inbox/${data?.data?.id}/settle/`,
    options: {
      onSuccess: () => fetchMail("settle"),
      onError: () => dispatch(setErrorToastMessage("पत्र फर्स्यौट असफल भय!")),
    },
  });

  const handleForwardMail = () => {
    let data = {
      receiver: receivers?.map((receiver) => receiver.value).join(""),
      remarks: forwardRemarks,
      images: forwardRemarkFiles,
    };
    forwardMutate(data);
  };

  const handleSettleMail = () => {
    let data = {
      remarks: settleRemarks,
      images: settlementFiles,
    };
    settleMutate(data);
  };

  const handleAttachmentPrint = useReactToPrint({
    content: () => {
      return attachmentPrintRef.current ?? null;
    },
    onAfterPrint: () => {
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
      } catch (err) {}
    },
  });

  const handleMailPrint = useReactToPrint({
    content: () => {
      return mailPrintRef.current ?? null;
    },
    onAfterPrint: () => {
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
      } catch (err) {}
    },
  });

  const handleRefresh = () => {
    fetchMail(null);
  };
  // Archive Feature
  const { mutate: ArchiveMail } = useMutationHook({
    queryRoute: "inbox/archive/",
    options: {
      onSuccess: (data) => {
        try {
          Swal.fire("संग्रहित!", "पत्राचार संग्रहित भएको छ.", "success");
          queryClient.invalidateQueries(["mail", ["id", id]]);

          return data;
        } catch (error) {
          console.log("ERROR Archiving", error);
        }
      },
      onError: (error) => {
        console.log(error, "error");
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  const handleOnArchiveClick = () => {
    Swal.fire({
      title: "के तपाई यो पत्र संग्रहित गर्न चाहनुहुन्छ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#60A5FA",
      cancelButtonColor: "#f87171",
      confirmButtonText: "सुनिश्चित गर्नुहोस्",
      cancelButtonText: "रद्द गर्नुहोस्",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ArchiveMail({
          papers: [id],
        });
      }
    });
  };

  const defaultDartaValues: any = useMemo(() => {
    if (data?.data) {
      return {
        date: "",
        paper_count: nepaliNumberConverter(activeFiscalYear),
        chalani_number: data?.data?.chalani_number,
        received_date: "",
        sender: data?.data?.sender_name_np,
        address: "",
        subject: data?.data?.subject,
        receiving_department: {
          label:
            data?.data?.receiver_fullname_np || data?.data?.receiver_name_np,
          value: data?.data?.receiver_id,
        },
        settlement_branch: {
          label:
            data?.data?.settlement_branch ||
            data?.data?.receiver_fullname_np ||
            data?.data?.receiver_name_np,
          value: data?.data?.settled_by || data?.data?.receiver_id,
        },
        attachment: data?.data?.hardcopy_preview,
        remarks: "",
      };
    }
  }, [activeFiscalYear, data?.data]);

  const handleShowDartaModal = () => setShowDartaModal(true);
  const handleHideDartaModal = () => {
    console.log("clicked");
    setShowDartaModal(false);
  };

  const handleGalleryImageClick = (index: number, item: any) => {
    dispatch(setLightBoxImage(item.src));
  };

  return (
    <div>
      <TopBar
        showBackButton
        showRefresh
        // showDelete
        showArchive={isInbox}
        isLoading={isFetching}
        handleRefresh={handleRefresh}
        handleOnArchiveClick={handleOnArchiveClick}
      />

      <div className="mail-content-container" ref={mailPrintRef}>
        {/* Mail Preview Section */}
        <section className={cls("bg-white p-4 pt-0 w-full")}>
          <div className="flex items-center justify-between">
            <h2 className="text-3xl font-semibold mb-4 ml-14">
              {isLoading ? (
                <Skeleton.Text className="h-[45px] w-[300px]" />
              ) : (
                <>
                  {data?.data?.darta_number ? (
                    <span
                      className="mr-1 tooltip tooltip-bottom"
                      data-tip={`दर्ता नं.: ${nepaliNumber(
                        data.data.darta_number
                      )}`}
                    >
                      #{nepaliNumber(data.data.darta_number)} |{" "}
                    </span>
                  ) : null}
                  {data?.data?.subject}
                </>
              )}
            </h2>

            <div className="flex items-center space-x-4 hide-in-print">
              <span
                role="button"
                onClick={() => setExpandAll((prev: boolean) => !prev)}
                className="text-slate-600"
              >
                {expandAll ? (
                  <BsChevronContract title="Collapse All" fontSize={24} />
                ) : (
                  <BsChevronExpand title="Expand All" fontSize={24} />
                )}
              </span>
              <span
                role="button"
                onClick={handleAttachmentPrint}
                title="Print"
                className="text-slate-600 hide-in-print"
              >
                <BsPrinter fontSize={24} />
              </span>

              <span
                role="button"
                onClick={handleMailPrint}
                title="Print"
                className="text-green-600 hide-in-print"
              >
                <BsPrinter fontSize={24} />
              </span>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="flex col-span-7 ">
              {/* Mail Sender */}
              {isInbox ? (
                <UserInfo
                  from={{
                    name:
                      data?.data?.organization_name ??
                      data?.data?.sender_organization_name,
                    id: data?.data?.sender_id,
                  }}
                  to={{
                    name:
                      data?.data?.receiver_fullname_np ||
                      data?.data?.receiver_name_np ||
                      data?.data?.receiver ||
                      data?.data?.organization_name,
                    id: data?.data?.receiver_id,
                  }}
                />
              ) : (
                <UserInfo
                  from={{
                    name:
                      user?.fullname_np ||
                      user?.organization?.fullname_np ||
                      user?.organization?.name_np,
                  }}
                  to={{
                    name:
                      data?.data?.receiver_fullname_np ||
                      data?.data?.receiver_name_np ||
                      data?.data?.receiver ||
                      data?.data?.organization_name,
                    id: data?.data?.receiver_id,
                  }}
                />
              )}

              <div className="px-2">
                {data?.data?.archived && (
                  <div className="badge badge-error mr-2">संग्रहित</div>
                )}
                {data?.data?.settled && (
                  <div className="badge border-green-500 bg-green-500 text-white">
                    फर्स्यौट भएको
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end col-span-5">
              {!isLoading &&
                isInbox &&
                !data?.data?.settled &&
                !data?.data?.archived && (
                  <section className="hide-in-print bg-white text-white font-semibold mr-10 flex gap-x-3">
                    {!data?.data?.darta_number && (
                      <label
                        htmlFor="darta-modal"
                        role="button"
                        className={cls(
                          " btn border font-semibold border-sky-400 bg-white hover:bg-sky-400 hover:border-white hover:text-white text-sky-400  rounded-box"
                        )}
                        onClick={handleShowDartaModal}
                      >
                        दर्ता गर्नुहोस
                      </label>
                    )}
                    <label
                      htmlFor="forward-modal"
                      role="button"
                      aria-disabled={isForwardLoading}
                      className={cls(
                        " btn border font-semibold border-blue-400 bg-white hover:bg-blue-400 hover:border-white hover:text-white text-blue-400 rounded-box",
                        isForwardLoading ? "btn-disabled" : ""
                      )}
                    >
                      {isForwardLoading ? (
                        <VscLoading className="spin" />
                      ) : (
                        "फरवार्ड गर्नुहोस"
                      )}
                    </label>

                    <label
                      htmlFor="settle-modal"
                      className="btn btn-primary font-semibold  text-white rounded-box"
                    >
                      {isSettleLoading ? (
                        <VscLoading className="spin" />
                      ) : (
                        "फर्स्यौट गर्नुहोस"
                      )}
                    </label>
                  </section>
                )}

              {/* Timestamp */}
              {data?.data?.created_at && (
                <TimeStamp timeStamp={data.data.created_at} />
              )}
            </div>
          </div>

          <hr className="border border-slate-300 mb-5" />

          <div className="mx-14">
            {/* Mail Message */}
            <div className="mb-4">{data?.data?.message}</div>

            {/* Mail Preview */}
            {expandAll && isLoading ? (
              <Skeleton />
            ) : (
              <div
                className="print-img-previewer-container min-h-[60vh] relative bg-slate-100 p-8"
                ref={attachmentPrintRef}
              >
                <ImagePreviewer images={data?.data?.hardcopy_preview || []} />
              </div>
            )}
          </div>
        </section>
        {/* Mail Remarks Section */}
        {data?.data?.remarks?.length > 0 &&
          typeof data?.data?.remarks !== "string" && (
            <section className="border-2 bg-white p-4 mt-2 mx-14">
              {data?.data?.remarks?.map((remark: any, idx: number) => {
                return (
                  <Fragment key={idx}>
                    <div className="py-4 border-b">
                      <div className="w-full flex justify-between">
                        <div>
                          <UserInfo
                            from={{
                              name: remark.forwarder__fullname_np,
                              id: remark.forwarder_id,
                            }}
                            to={{
                              name: remark.receiver__fullname_np,
                              id: remark.receiver_id,
                            }}
                          />
                          <div className="badge badge-secondary">
                            फरवार्ड गरिएको
                          </div>
                        </div>

                        <TimeStamp timeStamp={remark.created_at} />
                      </div>

                      {expandAll && (
                        <div className="p-4 min-h-[10vh]">{remark.remarks}</div>
                      )}

                      <Gallery
                        images={remark.images.map((i: string) => ({
                          src: i,
                        }))}
                        onClick={handleGalleryImageClick}
                      />
                    </div>
                  </Fragment>
                );
              })}
              <div className="page-break" />
            </section>
          )}

        {/* settled  */}
        {isInbox ? (
          <>
            {data?.data?.settled && data?.data?.settlement_remark && (
              <section className="border-2 bg-white p-4 mt-2 mx-14">
                <div className="py-4 border-b">
                  <div className="flex justify-between">
                    <UserInfo
                      from={{
                        name:
                          data?.data?.settlement_remark
                            ?.forwarder__fullname_np ||
                          data?.data?.settlement_remark?.forwarder__name_np,
                        id: data?.data?.settlement_remark?.forwarder_id,
                      }}
                      to={{
                        name:
                          data?.data?.settlement_remark
                            ?.receiver__fullname_np ||
                          data?.data?.settlement_remark?.receiver__name_np,
                        id: data?.data?.settlement_remark?.receiver_id,
                      }}
                    />

                    <TimeStamp timeStamp={data?.data?.updated_at} />
                  </div>

                  <div className="badge border-green-500 bg-green-500 text-white">
                    फर्स्यौट भएको
                  </div>
                  {expandAll && (
                    <div className="p-4 min-h-[10vh] space-y-2">
                      {data?.data?.settlement_remark?.remarks}
                      <Gallery
                        images={data?.data?.images.map((i: string) => ({
                          src: i,
                        }))}
                        onClick={handleGalleryImageClick}
                      />
                    </div>
                  )}
                </div>
              </section>
            )}
          </>
        ) : (
          <>
            <section className="border-2 bg-white p-4 mt-2 mx-14">
              {paperStatusData?.data?.map((remark: any, idx: number) => {
                return (
                  <Fragment key={idx}>
                    <div className="py-4 border-b">
                      <div className="flex justify-between">
                        <UserInfo
                          from={{
                            name:
                              remark.forwarder__fullname_np ||
                              remark.forwarder__name_np,
                            id: remark.forwarder_id,
                          }}
                          to={{
                            name:
                              remark.receiver__fullname_np ||
                              remark.receiver__name_np,
                            id: remark.receiver_id,
                          }}
                        />

                        <TimeStamp timeStamp={remark.created_at} />
                      </div>
                      {remark.settled ? (
                        <div className="badge border-green-500 bg-green-500 text-white">
                          फर्स्यौट भएको
                        </div>
                      ) : (
                        <div className="badge badge-secondary text-white">
                          फर्स्यौट नभएको
                        </div>
                      )}
                      {expandAll && remark.settlement_remarks && (
                        <div>
                          <div className="p-4 min-h-[10vh]">
                            {remark.settlement_remarks}
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </section>
          </>
        )}
        <Modal id="forward-modal" showClose>
          <ForwardModal
            receivers={receivers}
            handleReceiversChange={handleReceiveChange}
            handleForwardRemarksChange={handleForwardRemarksChange}
            handleForwardRemarkFilesChange={handleForwardRemarkFilesChange}
            handleForwardMail={handleForwardMail}
          />
        </Modal>

        <Modal id="settle-modal" showClose>
          <SettleModal
            handleSettleRemarksChange={handleSettleRemarksChange}
            handleSettleMail={handleSettleMail}
            handleSettleFilesChange={handleSettleFilesChange}
          />
        </Modal>

        <Modal
          id="darta-modal"
          className={classNames("max-w-[70vw]")}
          showClose
          show={showDartaModal}
          handleHide={handleHideDartaModal}
        >
          <DartaModal
            data={defaultDartaValues}
            hideModal={handleHideDartaModal}
            receiverOrganization={{
              label:
                data?.data?.receiver_fullname_np ||
                data?.data?.receiver_name_np,
              value: data?.data?.receiver_id,
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Mail;

interface IMailProps {
  isInbox?: boolean;
}

const DartaModal: FC<{
  data: any;
  receiverOrganization: { label?: string; value?: number };
  hideModal: () => void;
}> = ({ data, receiverOrganization, hideModal }) => {
  const initialDartaValue = {
    date: getTodayDate(),
    paper_count: 0,
    chalani_number: 0,
    received_date: getTodayDate(),
    sender: "",
    address: "",
    subject: "",
    receiving_department: receiverOrganization,
    settlement_branch: receiverOrganization,
    file_upload: null,
    attachment: [],
    remarks: "",
  };

  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { mutate: patchPaper } = useMutationHook({
    queryRoute: `inbox/${id}/`,
    method: "patch",
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["mail"]);
      },
    },
  });

  const { mutate, isLoading } = useMutationHook({
    env: "REACT_APP_DARTACHALANI_URL",
    queryRoute: "/darta/",
    options: {
      onSuccess: (response) => {
        hideModal();
        patchPaper({
          darta_number: response?.data?.serial_number,
        });
        dispatch(setSuccessToastMessage("सफलतापूर्वक दर्ता गरियो।"));
      },
      onError: () => {
        dispatch(setErrorToastMessage("Something went wrong!"));
      },
    },
  });
  const [dartaData, setDartaData] = useState<{ [key: string]: any }>(
    initialDartaValue
  );

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (data) {
      let newData = { ...data };
      if (data.date === "") newData.date = getTodayDate();
      if (data.received_date === "") newData.received_date = getTodayDate();

      setDartaData(newData);
    }
  }, [data]);

  const handleInputChange = (key: string, value: any) => {
    let prevData: { [key: string]: any } = { ...dartaData };
    prevData[key] = value;
    validateForm(dartaData);
    setDartaData(prevData);
  };

  const validateForm = (data: any) => {
    let errorsObj = { received_date: "", date: "" };
    let hasError = false;
    if (!data?.received_date) {
      errorsObj.received_date = "कृपया मिति छान्नुहोस्।";
      hasError = true;
    }
    if (!data?.date) {
      errorsObj.date = "कृपया मिति छान्नुहोस्।";
      hasError = true;
    }

    setErrors(errorsObj);
    return hasError;
  };

  const handleSubmit = () => {
    try {
      const data = { ...dartaData };
      data.receiving_department_id = Number(
        dartaData?.receiving_department?.value
      );
      data.receiving_department = dartaData?.receiving_department?.label;
      data.settlement_branch_id = Number(dartaData?.settlement_branch?.value);
      data.settlement_branch = dartaData?.settlement_branch?.label;

      const hasError = validateForm(data);
      if (hasError) return;
      mutate({ ...data, organization: user?.organization?.id });
    } catch (error) {}
  };
  return (
    <div className="py-4">
      <h1 className="text-3xl mb-1 text-[#60A5FA] font-semibold">दर्ता</h1>
      <form>
        <fieldset className="grid grid-cols-6 gap-4 mb-2">
          <legend className="text-[#60A5FA] font-bold text-lg mb-2">
            पत्र प्राप्त भएको
          </legend>
          <div className="col-span-2">
            <FormInput
              id="date"
              type="Date"
              label="दर्ता मिति* :"
              defaultValue={data && data?.date ? data.date : getTodayDate()}
              error={errors.date}
              getInputData={(value) => handleInputChange("date", value)}
              isDisabled={isLoading}
            />
          </div>
          <div>
            <FormInput
              id="paper_count"
              type="Text"
              label="पत्र संख्या* :"
              defaultValue={(data && data?.paper_count) || "up"}
              getInputData={(value) => handleInputChange("paper_count", value)}
              isDisabled={isLoading}
            />
          </div>
          <div>
            <FormInput
              id="chalani_number"
              type="Text"
              subType="number"
              label="च.नं* :"
              defaultValue={data && data?.chalani_number}
              getInputData={(value) =>
                handleInputChange("chalani_number", value)
              }
              isDisabled={isLoading}
            />
          </div>
          <div className="col-span-2">
            <FormInput
              id="received_date"
              type="Date"
              label="मिति* :"
              error={errors.received_date}
              defaultValue={
                data && data?.received_date
                  ? data.received_date
                  : getTodayDate()
              }
              getInputData={(value) =>
                handleInputChange("received_date", value)
              }
              isDisabled={isLoading}
            />
          </div>
        </fieldset>
        <fieldset className="grid grid-cols-3 gap-x-4">
          <legend className="text-[#60A5FA] font-bold text-lg mb-2">
            पत्रको विवरण
          </legend>
          <div>
            <FormInput
              id="sender"
              type="Text"
              subType="text"
              label="पत्र पठाउने ब्यक्ति वा कार्यालयको नाम* :"
              defaultValue={data && data?.sender}
              getInputData={(value) => handleInputChange("sender", value)}
              isDisabled={isLoading}
            />
          </div>
          <div>
            <FormInput
              id="address"
              type="Text"
              subType="text"
              label="ठेगाना* :"
              defaultValue={data && data?.address}
              getInputData={(value) => handleInputChange("address", value)}
              isDisabled={isLoading}
            />
          </div>
          <div>
            <FormInput
              id="subject"
              type="Text"
              subType="text"
              label="बिषय* :"
              defaultValue={data && data?.subject}
              getInputData={(value) => handleInputChange("subject", value)}
              isDisabled={isLoading}
            />
          </div>
          <div>
            <ReceiverListInput
              value={dartaData?.receiving_department}
              defaultLabel="बुझाउने फाँट* :"
              onChange={(values) =>
                handleInputChange("receiving_department", values)
              }
              isMulti={false}
              isDisabled={isLoading}
              allowSelfSelect
              key={dartaData?.receiving_department?.value}
            />
          </div>
          <div>
            <ReceiverListInput
              value={dartaData?.settlement_branch}
              defaultLabel="फर्स्यौट शाखा* :"
              onChange={(values) =>
                handleInputChange("settlement_branch", values)
              }
              isMulti={false}
              isDisabled={isLoading}
              allowSelfSelect
              key={dartaData?.settlement_branch?.value}
            />
          </div>
        </fieldset>

        <fieldset className="grid grid-cols-3 gap-x-4">
          <div className="">
            <label className="text-gray-700 mb-1 font-bold">पत्र:</label>
            {data?.attachment?.map((src: string, i: number) => {
              if (!src) return null;
              return (
                <img
                  key={i}
                  className="h-48 border-2 rounded object-contain bg-slate-50"
                  src={src}
                  alt={src.split("/")[src.split("/").length - 1]}
                />
              );
            })}
          </div>
          <div className="col-span-2">
            <FormInput
              id="remarks"
              type="Text"
              subType="textarea"
              className="h-48"
              label="कैफियत :"
              defaultValue={data && data?.remarks}
              getInputData={(value) => handleInputChange("remarks", value)}
              isDisabled={isLoading}
            />
          </div>
        </fieldset>
        <div className="flex justify-end">
          <button
            type="button"
            className="btn btn-primary text-white"
            onClick={() => handleSubmit()}
          >
            {isLoading ? <Loader isLoading /> : "दर्ता गर्नुहोस"}
          </button>
        </div>
      </form>
    </div>
  );
};
