import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useQueryHook } from '../../../hooks/react-query/useQueryHook';
import { useAppSelector } from '../../../hooks/redux';
import { generatePreview } from '../../../_helpers/template';
import SifarishPreviewModal from '../../SifarishPreviewModal/SifarishPreviewModal';

export default function PreviewModal({ paperId, setPreviewPaperId }) {
	const printRef = useRef(null);
	const [show, setShow] = useState();
	const [previewData, setPreviewData] = useState('');
	const user = useAppSelector(state => state?.auth?.user);

	const handleClose = () => {
		setPreviewPaperId(null);
	}

	const { data: paperData, isLoading } = useQueryHook({
		queryName: 'paper',
		params: [['id', paperId ?? 1]],
		queryRoute: `/paper/${paperId}/`,
		options: {
			enabled: Boolean(paperId),
		},
	});


	useEffect(() => {
		setShow(Boolean(paperId));
		if (show) {
			if (paperData) {
				const tempPreviewData = generatePreview(
					paperData.values,
					paperData.form_values,
					{},
					user
				);
				setPreviewData(tempPreviewData)
			}
		}
	}, [paperData, paperId, show, user]);

	const handlePrint = useReactToPrint({
		content: () => {
			return printRef.current ?? null;
		},
		onAfterPrint: () => {
			try {
				//Need to detect if the print is cancelled, if cancelled do not increment count
			} catch (err) { }
		},
	});

	return (
		<SifarishPreviewModal
			show={show}
			handleClose={handleClose}
			innerRef={printRef}
			previewData={previewData}
			previewFormData={paperData?.form_values}
			handlePrint={handlePrint}
			showPrint={true}
			config={paperData?.values?.config}
			meta={paperData?.values?.meta}
			paperId={paperData?.id}
			preview={true}
		/>
	);
}