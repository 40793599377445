import { AiOutlineCalendar } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FaUserTie } from "react-icons/fa";


import TopBar from "../../components/app/topbar";
import { Link } from "react-router-dom";
import PageTitle from "../../components/app/PageTItle";

export default function Settings() {

    const settingsMenus = [
        {
            label: 'आर्थिक वर्ष',
            icon: <AiOutlineCalendar fontSize={48} />,
            link: 'fiscal-year'
        }, {
            label: 'वडा कार्यालयहरु',
            icon: <BsBuilding fontSize={48} />,
            link: 'ward-offices'
        }, {
            label: 'नगरपालिकाको विवरण',
            icon: <HiOutlineOfficeBuilding fontSize={48} />,
            link: 'nagarpalika'
        }, {
            label: 'ITO प्रयोगकर्ता',
            icon: <FaUserTie fontSize={48} />,
            link: 'ito-users'
        },
    ]
    return (
        <>
            <TopBar
                showBackButton
            />

            <div className="p-4">
                <div className="mb-3">
                    <PageTitle title="सेटिंग्स" />
                </div>

                <div className="settings-content-container">
                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
                        {
                            settingsMenus.map((menu) => {
                                return (
                                    <SettingsCard key={menu.label} icon={menu.icon} label={menu.label} link={menu.link} />
                                )
                            })
                        }
                    </div>

                </div>
            </div>

        </>
    )
}

const SettingsCard = ({ icon, label, link }: { icon: any; label: string, link: string }) => {
    return (
        <Link to={link} className="group flex flex-col justify-center items-center p-4 border rounded-md shadow cursor-pointer hover:bg-blue-500 hover:text-white transition">
            <div className="mb-3 text-blue-500 group-hover:text-white">
                {icon}
            </div>

            <div className="text-xl font-semibold text-center text-slate-600 group-hover:text-white">
                {label}
            </div>
        </Link>
    )
}