import dayjs from "dayjs";
import { Key, useCallback, useMemo, useState } from "react";
import Table, {
  tableDataType,
  tableHeadType,
} from "../../components/reusables/Table";
import { useQueryClient } from "@tanstack/react-query";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";

//
import { FiMail } from "react-icons/fi";
import { IoStar } from "react-icons/io5";

import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";

//
import TopBar from "../../components/app/topbar";
import Avatar from "../../components/reusables/Avatar";
import PreviewModal from "../../components/reusables/PreviewModal";
import UploadDocumentModal from "../../components/reusables/UploadDocumentModal";
import { nepaliNumber } from "../../_helpers/methods";
import Loader from "../../components/app/Loader";
import { useAppDispatch } from "../../hooks/redux";

//
const LIMIT = 50;
var adbs = require("ad-bs-converter");

/**
 * 
 */
export default function StarredPage() {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [listStyle, setListStyle] = useState(false);
  const [selectedItemsId, setSelectedItemsId] = useState<Key[]>([]);
  const [previewPaperId, setPreviewPaperId] = useState<undefined | Key>();

  // UnStar feature
  const { mutate: unStarMail } = useMutationHook({
    queryRoute: '/inbox/unpin/',
    options: {
      onSuccess: (data) => {
        try {
          queryClient.invalidateQueries(['starred-mail']);
          setSelectedItemsId([]);
          dispatch(setSuccessToastMessage('ताराङ्कित पत्रबाट हटाइयो।'));

          return data;
        } catch (error) {
          console.log('ERROR star mails', error);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        setErrorToastMessage('Something went wrong');
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  const handleUnstarClick = useCallback((id: Key) => {
    unStarMail({ papers: [id] });
  }, [unStarMail]);

  const handleItemSelect = useCallback((id: Key) => {
    const itemIdx = selectedItemsId.findIndex((selectedItemId) => selectedItemId === id);
    if (itemIdx >= 0) {
      let tempSelectedItemsId = [...selectedItemsId];
      tempSelectedItemsId.splice(itemIdx, 1);
      setSelectedItemsId(tempSelectedItemsId)
    }
    else {
      let tempSelectedItemsId = [...selectedItemsId];
      tempSelectedItemsId.push(id);
      setSelectedItemsId(tempSelectedItemsId);
    }
  }, [selectedItemsId]);

  const headers: tableHeadType[] = useMemo(() => {
    return [
      {
        id: 1,
        cell: (value: tableDataType) => (
          <span className="flex space-x-2 items-center">
            <input
              type="checkbox"
              name="select1"
              id="select1"
              className="cursor-pointer checkbox h-4 w-4 rounded-sm ml-2 block"
              defaultChecked={selectedItemsId.includes(value?.id)}
              onClick={() => handleItemSelect(value?.id)}
              key={`${selectedItemsId}`}
            />
            <label htmlFor="" className="label !ml-0 !p-0">
              <input type="checkbox" name="" id="" hidden />
              <span className="cursor-pointer !ml-0 p-2 text-yellow-400  hover:bg-yellow-200 hover:ease-in-out duration-500 hover:rounded-full" onClick={() => handleUnstarClick(value?.id)}>
                <IoStar fontSize={17} />
              </span>
            </label>
          </span>
        ),
        noLink: true,
        dataClassName: "w-[5%] border-l-[3px]",
      },
      {
        id: 2,
        cell: (value: tableDataType) => (
          <div className="flex items-center justify-start space-x-2">
            <Avatar size="sm" name={value?.sender} id={String(value?.sender_id)} />
            <span className="text-sm">{value?.sender}</span>
          </div>
        ),
        dataClassName: "w-[20%]",
      },
      {
        id: 3,
        // title: 'subject',
        cell: (value: tableDataType) => (
          <>
            {
              value.darta_number && <div className="tooltip tooltip-bottom" data-tip={`दर्ता नं.: ${nepaliNumber(value.darta_number)}`}><span>#{nepaliNumber(value.darta_number)}</span> <span> &nbsp;| &nbsp;</span></div>
            }

            <span>{value.subject}</span>

            {
              value.message && <> <span> &nbsp;- &nbsp;</span> <span className="text-sm">{value.message?.substring(0, 80)}</span></>
            }
          </>
        ),
        dataClassName: "w-[65%]",
      },
      {
        id: 4, cell: (value: tableDataType) => {
          const { ne } = adbs.ad2bs(dayjs(value.date).format("YYYY/M/D"));
          const nepaliDate = `${ne.year}/${ne.month.padStart(2, "०")}/${ne.day.padStart(2, "०")}`;

          const time = dayjs(value.date).format('hh:mm A');
          const isToday = dayjs(value.date).format("YYYY/MM/DD") === dayjs().format("YYYY/MM/DD");
          return (
            <span className='text-slate-500 flex justify-end w-full pr-4'>
              <div className="tooltip tooltip-left" data-tip={`${nepaliDate} ${time}`}>
                {
                  isToday ?
                    <span className="text-sm text-slate-600">{time}</span>
                    :
                    <span className="text-sm text-slate-600">
                      {nepaliDate}
                    </span>
                }
              </div>
            </span>
          )
        },
        dataClassName: "w-[10%]",
      },
    ];
  }, [handleItemSelect, handleUnstarClick, selectedItemsId]);

  const handleRefresh = () => {
    queryClient.invalidateQueries(['starred-mail']);
  }

  const handleInboxSuccess = (data: any) => {
    setTotal(data?.data?.count);
    return data;
  }

  const { data: inboxData, isLoading, isFetching } = useQueryHook({
    queryName: 'starred-mail',
    queryRoute: `inbox/`,
    params: [["pinned", true], ["limit", LIMIT], ["offset", offset * LIMIT]],
    options: {
      onSuccess: handleInboxSuccess
    }
  });

  const handleNextPage = () => {
    setOffset((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setOffset((prev) => prev - 1);
  };

  const generateData = (data: any) => {
    if (data?.results?.length) {
      const final = data?.results?.map((result: any) => {
        return {
          id: result.id,
          paper_id: result.paper_id,
          sender: result.sender_fullname_np || result.sender_fullname_np || result.sender_organization_name,
          sender_id: result.sender_id,
          subject: result.subject,
          darta_number: result.darta_number,
          message: result.message,
          read: result.read,
          priority: result.priority,
          darta_id: result.darta_id, //Todo: darta info in msg
          date: result.created_at,
        };
      });
      return final;
    }
    return [];
  };

  // Read Feature
  const { mutate: ReadMails } = useMutationHook({
    queryRoute: 'inbox/mark-read/',
    options: {
      onSuccess: (data) => {
        try {
          queryClient.invalidateQueries(['starred-mail']);
          setSelectedItemsId([]);
          dispatch(setSuccessToastMessage('पत्रलाई सफलतापुर्वक "हेरिएको" सुचिमा राखियो।'));

          return data;
        } catch (error) {
          console.log('ERROR reading mails', error);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        setErrorToastMessage('Something went wrong');
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  const handleOnReadClick = () => {
    if (selectedItemsId.length) {
      ReadMails({
        papers: selectedItemsId
      });
    }
    else {
      dispatch(setErrorToastMessage('कृपया यो कार्यको निम्ति पत्र छान्नुहोस्।'));
    }
  }

  // UnRead Feature
  const { mutate: UnreadMails } = useMutationHook({
    queryRoute: 'inbox/mark-unread/',
    options: {
      onSuccess: (data) => {
        try {
          queryClient.invalidateQueries(['starred-mail']);
          setSelectedItemsId([]);
          dispatch(setSuccessToastMessage('पत्रलाई "नहेरिएको" सुचिमा राखियो।'));

          return data;
        } catch (error) {
          console.log('ERROR unreading mails', error);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        setErrorToastMessage('Something went wrong');
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  const handleOnUnreadClick = () => {
    if (selectedItemsId.length) {
      UnreadMails({
        papers: selectedItemsId
      });
    }
    else {
      dispatch(setErrorToastMessage('कृपया यो कार्यको निम्ति पत्र छान्नुहोस्।'));
    }
  }

  const finalData = inboxData?.data ? generateData(inboxData.data) : [];

  let render = null;

  if (isLoading) {
    render = (
      <div className="no-top-content-container">
        <div className="bg-white flex justify-center items-center flex-col rounded m-3 py-20">
          <Loader size='64px' />
        </div>
      </div>
    );
  } else if (finalData.length < 1) {
    render = (
      <div className="no-top-content-container">
        <div className="bg-white flex justify-center items-center flex-col rounded m-3 py-20">
          <div className="mb-6 p-9 w-[190px] h-[190px] rounded-full bg-gray-200 flex justify-center items-center">
            <FiMail size={82} color="gray" />
          </div>

          <div className="text-xl">तपाईंको कुनै पनि पत्राचार छैन ।</div>
        </div>
      </div>
    );
  } else {
    render = (
      <div className="no-top-content-container">
        {/* Table */}
        <Table
          headers={headers}
          data={finalData}
          className=""
          noHeader
          compact={listStyle}
          rowLinkDestination="inbox"
        />

        {/* preview Modal */}
        {/* // TODO new page for inbox/:id ?? */}
        <PreviewModal
          paperId={previewPaperId}
          setPreviewPaperId={setPreviewPaperId}
        />
        <UploadDocumentModal paperId={previewPaperId} />
      </div>
    );
  }


  return (
    <>
      <TopBar
        pagination={{
          limit: LIMIT,
          offset: offset,
          total: total,
          onNext: handleNextPage,
          onPrev: handlePrevPage,
        }}

        showBackButton
        showRefresh
        // showDelete
        showMarkAsRead
        showMarkAsUnRead
        isLoading={isFetching}

        handleRefresh={handleRefresh}
        handleOnReadClick={handleOnReadClick}
        handleOnUnreadClick={handleOnUnreadClick}
      />

      {render}
    </>
  );
}
