import { createSlice } from '@reduxjs/toolkit';
import AuthServices from '../../apis/services/AuthServices';
import { clearLocalTokens, setLocalTokens } from '../../_helpers/token-handler';
import { AppDispatch } from '../store';
import { setErrorToastMessage, setSuccessToastMessage } from './toast';
import { LOCAL_ACCESS_TOKEN_NAME } from '../../constants/constants';

/**
 *
 */

interface Iuser {
	token: {
		[key: string]: any;
	};
	user: null | { [key: string]: any };
	isLoggedIn: boolean;
	activeFiscalYear: null | string;
}

const initialState: Iuser = {
	token: {},
	user: null,
	isLoggedIn: false,
	activeFiscalYear: null
};

/**
 *
 */
export const AuthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.isLoggedIn = true;
		},
		setUserProfile: (state, action) => {
			state.user = {
				...(action.payload ?? {}),
				...state.user,
			};
		},
		setActiveFiscalYear: (state, action) => {
			state.activeFiscalYear = action.payload;
		},
		removeUser: (state) => {
			state.user = null;
			state.isLoggedIn = false;
		},
		setUserLogin: (state, action) => {
			state.isLoggedIn = true;
			state.token = action.payload;
		},
		setUserLogout: (state) => {
			state.user = null;
			state.isLoggedIn = false;
		},
	},
});

export const { setUser, setUserProfile, removeUser, setUserLogin, setUserLogout, setActiveFiscalYear } = AuthSlice.actions;
export default AuthSlice.reducer;

export const loginUser =
	(tokens: { access: string; refresh: string }, navigate: any, redirectTo?: string) =>
		async (dispatch: AppDispatch) => {
			try {
				if (!tokens) throw new Error('Error');
				setLocalTokens(tokens.access, tokens.refresh);
				dispatch(setUserLogin(tokens));

				if (navigate) {
					if (redirectTo) {
						navigate(redirectTo);
					} else {
						navigate('/');
					}
				}
			} catch (err) {
				console.log(err);
				dispatch(setErrorToastMessage('Login failed'))
			}
		};

export const logoutUser = () => async (dispatch: AppDispatch) => {
	clearLocalTokens();
	dispatch(setUserLogout());
};

export const handleGetUserData = () => async (dispatch: AppDispatch) => {
	try {
		const data = await AuthServices.getUserData();
		if (!data) throw new Error('Not logged in');
		dispatch(setUser(data));
		return true;
	} catch (err) {
		dispatch(setUserLogout());
		return false;
	}
};

export const handleGetUserProfileData = () => async (dispatch: AppDispatch) => {
	try {
		const data = await AuthServices.getUserProfileData();
		if (!data) throw new Error('Not logged in');
		dispatch(setUserProfile(data));
		return true;
	} catch (err) {
		return false;
	}
};

export const handleSetActiveFiscalYear = (activeFiscalYear: any) => async (dispatch: AppDispatch) => {
	dispatch(setActiveFiscalYear(activeFiscalYear?.data?.title))
}

