import FormInput from '../../components/reusables/FormInput';

//
import { IoMdSend } from 'react-icons/io';
import { useState, useMemo } from 'react';
// import Editor from '../../components/reusables/Editor';
import DocumentUpload from '../../components/@compose/DocumentUpload';
import ReceiverListInput from '../../components/@compose/ReceiverListInput';
import { useAppDispatch } from '../../hooks/redux';
import { VscLoading } from 'react-icons/vsc';
// import Modal from '../../components/reusables/Modal';
import { IFile } from '../../components/@compose/DocumentUpload/DocumentUpload';
import { setErrorToastMessage, setSuccessToastMessage } from '../../_redux/slices/toast';
import { useNavigate } from 'react-router-dom';
import { useMutationHook } from '../../hooks/react-query/useQueryHook';
import { useQueryClient } from '@tanstack/react-query';
import { DEFAULT_MAIL_PRIORITY, MAIL_PRIORITY } from '../../constants/constants';
import TopBar from '../../components/app/topbar';

export interface ILabel {
	value: string;
	label: string;
}

/**
 *
 */
export default function Compose() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();

	const [subject, setSubject] = useState<string>('');
	const [receivers, setReceivers] = useState<ILabel[] | null>(null);
	const [priority, setPriority] = useState<string>(DEFAULT_MAIL_PRIORITY);
	const [message, setMessage] = useState<string | undefined>(undefined);
	const [files, setFiles] = useState<IFile[]>([]);

	const handleMessageChange: any = (value: string) => {
		setMessage(value);
	};

	//
	const { mutate, isLoading } = useMutationHook({
		queryRoute: '/paper/send/',
		options: {
			onSuccess: (data) => {
				try {
					dispatch(setSuccessToastMessage('Patrachar Successfully sent!'));
					queryClient.invalidateQueries(["sent"]);
					navigate('/sent');
					return data;
				} catch (error) {
					console.log('ERROR Composing', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				setErrorToastMessage('Something went wrong');
			},
		},
	});

	const handleSubjectChange = (value: string) => {
		setSubject(value);
	};


	const handleCompose = () => {
		try {
			if (!receivers) return;
			const body: IComposeBodyProps = {
				subject: subject,
				receiver: (receivers || [])?.map((receiver) => receiver.value),
				hardcopy_preview: files.map((file) => file.file),
				priority: priority,
				is_draft: false,
			};
			if (message) {
				body.message = message;
			}

			mutate(body);
		} catch (error) {
			console.log(error);
		}
	};

	const handleReceiveChange = (value: ILabel[]) => {
		console.log(value, 'value')
		setReceivers(value);
	};


	const handleDocumentUpload = (files: IFile[]) => {
		setFiles(files);
	};

	const handlePriorityChange = (event: any) => {
		setPriority(event.target.value)
	}

	const isValidToSend: boolean = useMemo(
		() => (subject && receivers && files.length > 0 ? true : false),
		[subject, receivers, files]
	);

	return (
		<div>
			<TopBar showBackButton />

			<div className="bg-white h-full p-4 scroll-content">
				<div className='grid grid-cols-12 gap-16'>
					<div className='col-span-6'>
						<ReceiverListInput value={receivers} onChange={handleReceiveChange} />
					</div>

					<div className='col-span-6'>
						{/* Priority */}
						<div>
							<div>
								<FormInput id="priority" defaultValue={priority} label="प्राथमिकता" type="Radio" getInputData={handlePriorityChange} options={MAIL_PRIORITY} noMarginBottom />
							</div>
						</div>
					</div>
				</div>

				<div className="mb-5">
					<FormInput id="subject" label="बिषय" type="Text" className='h-[2.4rem]' getInputData={handleSubjectChange} />
				</div>

				<div>
					<DocumentUpload onUpload={handleDocumentUpload} isMulti properties={{
						accept: {
							'image/*': ['.jpeg', '.png']
						}
					}} />
				</div>
				{/* Remarks */}
				<div>
					<div className="mb-5">
						<FormInput id="message" label="टिप्पणी" type="Text" subType='textarea' getInputData={handleMessageChange} /> </div>
				</div>

				<div className="mt-4 flex">
					<button disabled={isLoading || !isValidToSend} className="btn btn-primary" onClick={handleCompose}>
						{isLoading ? (
							<VscLoading className="spin" />
						) : (
							<>
								<IoMdSend className="mr-2" /> पठाउनुहोस्
							</>
						)}
					</button>
					<div className="w-[150px] hidden text-red-600"></div>

				</div>
			</div>
		</div>
	);
}

interface IComposeBodyProps {
	subject: string;
	receiver: string[];
	hardcopy_preview: string[];
	message?: string;
	priority: string;
	is_draft: boolean;
}
