import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

//
import AppHeader from '../../components/app/header';
import AppSidebar from '../../components/app/sidebar';

//
import './DashLayout.css';
import Footer from '../../components/app/footer';

/**
 *
 */
export default function DashLayout() {
	const [showMenu, setShowMenu] = useState(() => {
		const showMenuLocalStorage = localStorage.getItem('showSideMenu');
		if (showMenuLocalStorage) {
			return showMenuLocalStorage === 'true' ? true : false;
		}
		return true;
	});

	const handleToggleMenu = () => {
		setShowMenu((prev) => {
			let newState = !prev;
			localStorage.setItem('showSideMenu', newState);
			return newState;
		});
	};

	return (
		<div className="dashlayout">
			<AppHeader handleToggleMenu={handleToggleMenu} />

			<div className="pr-4 flex justify-between body w-screen">
				<AppSidebar showMenu={showMenu} setShowMenu={setShowMenu} />

				<div className={classNames('transition-all ease-in-out rounded-xl bg-white h-full', showMenu ? 'w-full' : 'main-content-sm')}>
					<Outlet />
					<Footer />
				</div>
			</div>
		</div>
	);
}
