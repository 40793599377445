import { Link, useSearchParams } from "react-router-dom";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";
import UserInfo from "../Mail/UserInfo";
import {
  FaCalendar,
  FaClock,
  FaExclamationCircle,
  FaPhone,
} from "react-icons/fa";
import dayjs from "dayjs";
import { VscLoading } from "react-icons/vsc";
import { IoLocation } from "react-icons/io5";
import { MdMail, MdOutlineLocationCity } from "react-icons/md";
import DisplayNepaliDate from "../../components/DisplayNepaliDate";

export default function CheckStatus() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { data: nd } = useQueryHook({
    queryName: "nagarpalika_public",
    queryRoute: `/nagarpalika/`,
  });

  const nagarpalikaData = nd?.data?.results?.length ? nd?.data?.results[0] : {};

  const handleOnError = () => {
    console.log("error");
    return { error: "invalid Token", data: "" };
  };

  const handleOnSuccess = () => {
    console.log("success");
    return {
      data: "boi",
    };
  };

  const { data, isLoading } = useQueryHook({
    queryName: `status ${token}`,
    queryRoute: `check-status/?token=${token}`,
    options: {
      onError: handleOnError,
      onSuccess: handleOnSuccess,
    },
  });

  let content = null;

  if (isLoading) {
    content = (
      <div className="flex w-full h-full justify-between items-center">
        <VscLoading className="animate-spin" fontSize={54} />
      </div>
    );
  }

  if (!data?.data) {
    content = (
      <div className="flex py-12 flex-col justify-start items-center gap-12 h-full">
        <div className="flex gap-4 items-center">
          <FaExclamationCircle className="text-red-500" fontSize={54} />

          <h2 className="text-4xl font-sans font-bold">
            असुविधाको लागि क्षमाप्रार्थी।
          </h2>
        </div>

        <div className="flex flex-col gap-4">
          <p className="max-w-3xl text-xl leading-relaxed mx-auto block">
            प्रविष्ट गरिएको टोकन मान्य छैन ।
          </p>
          <p className="max-w-3xl text-xl leading-relaxed mx-auto">
            तपाइँले प्रविष्ट गर्नु भएको टोकन भेटिएन । दर्ता स्थिती हेर्न उपलब्ध
            भएको मान्य टोकन राखी पुन: प्रयास गर्नुहोस् । धन्यबाद !
          </p>

          <p className="max-w-3xl leading-relaxed text-left mt-12 text-lg">
            <p className="font-extrabold text-xl">थप जानकारीको लागि :</p>
            <p className="flex gap-2 items-center font-normal">
              <MdOutlineLocationCity />

              {nagarpalikaData.name_np}
            </p>
            <p className="flex gap-2 items-center font-normal">
              <IoLocation />
              <span>
                {nagarpalikaData.district_np}, {nagarpalikaData.province_np},{" "}
                {nagarpalikaData.address_np}
              </span>
            </p>
            <p className="flex gap-2 items-center font-normal">
              <FaPhone className="rotate-90" />
              {nagarpalikaData.phone_np}
            </p>
          </p>
        </div>
      </div>
    );
  } else {
    const patrachar = data.data;
    content = (
      <>
        <div className="flex flex-col gap-6 p-4">
          <h1 className="font-medium text-center text-4xl font-sans">
            बिषय: {patrachar.subject}
          </h1>

          <div className="py-4 px-8 rounded-md flex flex-col lg:flex-row justify-between w-full gap-4 bg-white shadow-md">
            <div className="flex-[2] flex justify-between items-center gap-4">
              <h3 className="font-semibold text-md">बुझाउने:</h3>
              <div>
                <div className="text-2xl font-sans">
                  {patrachar.form_values.sender}
                </div>
                <div className="">{patrachar.form_values.address}</div>
              </div>
            </div>

            <div className="flex-1 flex justify-center">
              <div className="h-1 w-full lg:w-1 lg:h-auto bg-slate-400" />
            </div>

            <div className="flex-[2] flex justify-between items-center gap-4">
              <h3 className="font-semibold text-md">पाउने शाखा:</h3>

              <div className="text-xl lg:text-2xl text-right font-sans">
                {patrachar.form_values.receiving_department} शाखा
              </div>
            </div>

            <div className="flex-1 flex justify-center">
              <div className="h-1 w-full lg:w-1 lg:h-auto bg-slate-400" />
            </div>

            <div className="flex-[2] flex justify-between items-center gap-4">
              <h3 className="font-semibold text-md">फर्स्यौट गर्ने शाखा:</h3>
              <div>
                <div className="text-xl lg:text-2xl text-right font-sans">
                  {patrachar.form_values.settlement_branch} शाखा
                </div>
              </div>
            </div>
          </div>
        </div>

        {patrachar.remarks && (
          <div className="flex flex-col gap-10 p-4 relative">
            <div className="h-full w-1 bg-blue-400 absolute top-0 left-1/2 rounded-lg" />

            <div className="w-full max-w-[800px] mx-auto shadow-lg p-6 rounded relative bg-blue-100">
              <div className="flex flex-col sm:flex-row justify-between items-start">
                <div className="font-bold text-lg">
                  तपाईंको पत्र दर्ता नम्बर {patrachar?.darta_number} मा दर्ता
                  गरिएको छ।
                </div>

                <div className="text-xs text-gray-600 font-bold flex flex-row sm:flex-col">
                  <div className="flex items-center gap-2">
                    <FaCalendar />

                    <DisplayNepaliDate
                      date={patrachar.form_values.created_at}
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <FaClock />

                    {dayjs(patrachar.form_values.created_at).format("hh:mm A")}
                  </div>
                </div>
              </div>
            </div>

            {patrachar.remarks.map((remarks: IRemarks, index: number) => (
              <StatusCard key={index} remarks={remarks} />
            ))}

            {patrachar?.settlement_remarks?.length && (
              <div className="w-full max-w-[800px] mx-auto shadow-lg p-6 rounded relative bg-green-200">
                <div className="flex flex-col sm:flex-row justify-between items-start">
                  <div className="max-w-lg">
                    <p className="text-lg">
                      {patrachar.settlement_remarks[0].settlement_remarks}
                    </p>
                    <hr className="border-2 border-green-400 my-4 rounded-lg" />

                    <p>
                      तपाईंको दर्ता नम्बर {patrachar.darta_number} को पत्र{" "}
                      <strong className="font-sans">
                        {patrachar.settlement_remarks[0].receiver__fullname_np}
                      </strong>
                      बाट फर्स्यौट भएको छ । थप जानकारीको लागि कार्यालयमा सम्पर्क
                      गर्नु होला ।
                    </p>
                  </div>

                  <div className="text-xs text-gray-600 font-bold flex flex-row sm:flex-col">
                    <div className="flex items-center gap-2">
                      <FaCalendar />

                      <DisplayNepaliDate
                        date={patrachar.form_values.created_at}
                      />
                    </div>

                    <div className="flex items-center gap-2">
                      <FaClock />

                      {dayjs(patrachar.form_values.created_at).format(
                        "hh:mm A"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <div className="bg-slate-50">
        <Link
          to="/"
          className="container py-4 flex justify-center items-center mx-auto"
        >
          <div className="flex justify-center items-center gap-4">
            <img src="/assets/icons/nep_logo.png" className="w-24" alt="" />

            <div className="flex flex-col gap-2">
              <h4 className="text-2xl font-bold text-red-600">
                {nagarpalikaData.fullname_np || nagarpalikaData.name_np}को
              </h4>

              <h5 className="m-0 text-xl font-semibold text-blue-600">
                {nagarpalikaData.province_np}, नेपाल
              </h5>
            </div>
          </div>
        </Link>
      </div>

      <div className="bg-blue-400 min-h-[calc(100vh-112px)] p-4 flex">
        <div className="container mx-auto flex flex-1 flex-col gap-8 p-4 bg-slate-50 rounded-md">
          {content}
        </div>
      </div>

      <div className="bg-slate-50">
        <div className="container py-4 flex gap-4 flex-col sm:flex-row justify-between items-center mx-auto">
          <div className="flex gap-4">
            <img
              src="/assets/icons/nep_logo.png"
              className="w-14 h-12"
              alt=""
            />

            <div className="flex flex-col">
              <h4 className="m-0 text-md font-bold text-red-600">
                {nagarpalikaData.fullname_np || nagarpalikaData.name_np}को
              </h4>
              <h5 className="m-0 text-sm font-semibold text-blue-600">
                {nagarpalikaData.province_np}, नेपाल
              </h5>
            </div>
          </div>

          <div className="flex flex-col text-sm text-center">
            <span className="">थप जानकारीको लागि</span>
            <span className="flex gap-2 justify-center items-center">
              <FaPhone className="rotate-90" />
              {nagarpalikaData.phone_np || nagarpalikaData.phone_en}
            </span>
            <span className="flex gap-2 justify-center items-center">
              <MdMail />
              {nagarpalikaData.email}
            </span>
          </div>

          <div>
            <span className="text-sm flex flex-row sm:flex-col gap-2">
              Powered by:
              <a
                className="block text-green-500 hover:text-green-400"
                href="https://ideabreed.com.np/"
                target="_blank"
                rel="noreferrer"
              >
                Idea Breed IT Solution Pvt. Ltd.
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const StatusCard = ({ remarks }: IStatusCardProps) => {
  const time = dayjs(remarks.created_at).format("hh:mm A");

  return (
    <div className="w-full max-w-[800px] mx-auto shadow-lg p-6 rounded relative bg-blue-200">
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <UserInfo
          from={{
            name: `${remarks.forwarder__fullname_np} शाखा`,
            id: remarks.forwarder_id,
          }}
          to={{
            name: `${remarks.receiver__fullname_np} शाखा`,
            id: remarks.receiver_id,
          }}
        />

        <div className="text-xs text-gray-600 font-bold flex flex-row sm:flex-col">
          <div className="flex items-center gap-2">
            <FaCalendar />

            <DisplayNepaliDate date={remarks.created_at} />
          </div>

          <div className="flex items-center gap-2">
            <FaClock />

            {time}
          </div>
        </div>
      </div>

      <div>{remarks.remarks}</div>
    </div>
  );
};

interface IStatusCardProps {
  remarks: IRemarks;
}

interface IRemarks {
  remarks: string;
  forwarder__fullname_np: string;
  receiver__fullname_np: string;
  receiver_id: number;
  forwarder_id: number;
  created_at: string;
}
