import { FC, Key, ReactNode, ReactElement } from "react";
import cls, { Argument } from "classnames";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

export interface tableHeadTypes {
  id: Key;
  title?: string;
  noLink?: boolean;
  cell?: (value: tableDataTypes, index: number) => ReactElement | ReactNode;
  className?: Argument;
  dataClassName?: Argument;
}

export interface tableDataTypes {
  id: Key;
  [key: string]: any;
}

export type TableProps = {
  data: tableDataTypes[];
  headers: tableHeadTypes[];
  noHeader?: boolean;
  className?: Argument;
  compact?: boolean;
  isLoading?: boolean;
  rowLinkDestination?: string;
};

const Table: FC<TableProps> = ({
  data,
  headers = [],
  noHeader = false,
  className = "",
  compact = false,
  isLoading = false,
  rowLinkDestination
}) => {
  return (
    <div className={cls("", className)}>
      <table className={cls("table  w-full", compact && "table-compact")}>
        {!noHeader && (
          <thead>
            <tr>
              {headers.map((head: tableHeadTypes) => (
                <th key={head.id} className={cls(head.className)}>
                  {head.title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <Loader isLoading={isLoading} isTable>
            {data.map((item: tableDataTypes) => (
              <tr key={item.id}>
                {headers.map((head: tableHeadTypes, index: number) => (
                  <td
                    key={head.id}
                    className={cls(head.dataClassName, "px-0 py-2 ",
                      item.priority ? item.priority : '',
                      item.read ? 'bg-white' : 'bg-slate-100 font-medium')}
                  >
                    {head.cell ? (<span>
                      {
                        head.noLink ?
                          head.cell(item, index)
                          : <Link
                            to={`/${rowLinkDestination ?? 'sent-mail'}/${item.id}`}
                            className={cls(
                              "flex items-center",
                              item.seen && "text-semibold"
                            )}
                          >
                            {head.cell(item, index)}
                          </Link>
                      }
                    </span>
                    ) : (
                      head.title && item[head.title]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </Loader>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
