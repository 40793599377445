import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Loader from "../../components/app/Loader";
import TopBar from "../../components/app/topbar";
import FormElements from "../../components/Forms";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";
import { useEffect, useState } from "react";

export default function PreviewPage() {
    const { paperId } = useParams();
    const queryClient = useQueryClient();

    const { data, isLoading } = useQueryHook({ queryName: 'preview', params: [['id', paperId ?? 1]], queryRoute: `/paper/draft/${paperId}/` });

    const onTemplateSave = () => {
        queryClient.invalidateQueries(['preview', ['id', paperId ?? 1]])
    }

    const [chalaniData, setChalaniData] = useState({});

    const handleChalaniInputChange = (name: string, value: string) => {
        setChalaniData({
            ...chalaniData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (data) {
            setChalaniData({
                receiving_department: data.data?.form_values?.receiving_department,
                address: data.data?.form_values?.address,
                sending_medium: data.data?.form_values?.sending_medium,
                related_branch: data.data?.form_values?.related_branch,
                paper_count: data.data?.form_values?.paper_count,
                remarks: data.data?.form_values?.remarks,
            });
        }
    }, [data])

    return (
        <div className="w-full min-w-fit">
            {
                isLoading ?
                    <div className="p-8 flex justify-center">
                        <Loader />
                    </div>
                    :
                    <>
                        <TopBar
                            showBackButton
                        />
                        <div className="px-6 scroll-content">
                            {data?.data &&
                                <>
                                    <FormElements data={data.data?.values}
                                        vals={data.data?.form_values}
                                        singleValue={data?.data ?? {}}
                                        onTemplateSave={onTemplateSave}
                                        chalaniNumber={data.data?.chalani_number}
                                        chalaniCreatedOrg={data.data?.created_by_fullname}
                                        chalaniData={chalaniData}
                                        onChalaniInputChange={handleChalaniInputChange}
                                        disableChalaniForm={data.data?.chalani_number}
                                    />
                                </>}
                        </div>
                    </>
            }
        </div>
    )
}