import classNames from "classnames";
import { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

//
import { ISidebarData } from "../../app/sidebar/_data";

/**
 *
 */
export default function SubMenu({
  item,
  currentPath,
  showMenu,
}: ISubMenuProps) {
  const location = useLocation();
  const isMenuOpen = useMemo(() => {
    if (!item.subNav) return false;

    const index = item.subNav.findIndex(
      (nav: ISidebarData) => nav.path === currentPath
    );

    if (index !== -1) return true;

    return false;
  }, [currentPath, item.subNav]);

  const [subnav, setSubnav] = useState(isMenuOpen);

  return (
    <>
      <li className={classNames("mb-1 hover:bg-[#eaf1fb] rounded-r-2xl", showMenu ? "" : 'mr-1')}>
        {item.component ? (
          <item.component showMenu={showMenu} />
        ) : (
          <NavLink
            to={item.path}
            onClick={() => item.subNav && setSubnav(!subnav)}
            className={({ isActive }) => {
              let showActive = false;
              if (item.activePaths) {
                item.activePaths.forEach((path: string) => {
                  if (location.pathname.includes(path)) {
                    showActive = true;
                  }
                });
              }

              if ((isActive && item.path) || showActive) {
                return "bg-[#eaf1fb] font-medium indicator w-full rounded-r-2xl";
              }
              else {
                return "font-light";
              }
            }}
            end
          >
            <div
              className={classNames("flex items-center w-full py-3 rounded-r-lg",
                showMenu ? "gap-1 pl-8 pr-3" : "justify-center mini-icons")}
              data-tip={showMenu ? null : item.title}
            >
              {item.icon}

              {
                showMenu &&
                <span className="ml-1">{item.title}</span>
              }
            </div>

            <div className="chevron">
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                  ? item.iconClosed
                  : null}
            </div>
          </NavLink>
        )}
      </li >

      <div className="submenu">
        {subnav &&
          item.subNav.map((item: ISidebarData, index: number) => (
            <div className="dropdown" key={index}>
              <NavLink
                to={item.path ?? "#"}
                className={({ isActive }) =>
                  isActive && item.path ? "navbar__link--active" : ""
                }
              >
                <div className="flex items-center w-full" data-tip={item.title}>
                  {item.icon}

                  {
                    showMenu &&
                    <span>{item.title}</span>
                  }
                </div>
              </NavLink>
            </div>
          ))}
      </div>
    </>
  );
}

interface ISubMenuProps {
  item: any;
  currentPath: string;
  showMenu: Boolean;
}
