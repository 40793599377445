import { useState } from 'react';
import { useGetOrganizationQuery } from '../../../hooks/react-query/useOrganizationQuery';
import { ILabel } from '../../../Pages/Compose/Compose';
import FormInput from '../../reusables/FormInput';
import { useAppSelector } from '../../../hooks/redux';

export default function ReceiverListInput({ isMulti = true, value, allowSelfSelect, isDisabled, isSakha, ...props }: IReceiverListInputProps) {
	const [organizations, setOrganizations] = useState({});
	const label = props.defaultLabel ?? 'पाउने';
	const user = useAppSelector((state) => state.auth.user);

	//
	const handleSuccess = (data: any) => {
		let results = data?.results;
		if (results) {
			if (allowSelfSelect) {
				results = results.reduce((acc: any, cur: any) => {
					acc[cur.id] = cur.fullname_np || cur.name_np;
					return acc;
				}, {});
			}
			else {
				results = results.filter((result: any) => result.id !== user?.organization?.id);
				results = results.reduce((acc: any, cur: any) => {
					acc[cur.id] = cur.fullname_np || cur.name_np;
					return acc;
				}, {});
			}

			setOrganizations(results);
		}
		return data;
	};

	useGetOrganizationQuery({
		isSakha: isSakha,
		handleSuccess,
	});

	const handleReceiveChange = (value: any) => {
		props.onChange(value);
	};

	return (
		<div>
			<FormInput
				id="receiver"
				className={props.className}
				label={label}
				type="Select"
				options={organizations}
				defaultValue={value}
				getInputData={handleReceiveChange}
				placeholder="Select Receipent"
				isMulti={isMulti}
				isDisabled={isDisabled}
			/>
		</div>
	);
}

interface IReceiverListInputProps {
	defaultLabel?: string | null;
	value: ILabel[] | null;
	onChange: (value: ILabel[]) => void;
	className?: string;
	isMulti?: boolean;
	isDisabled?: boolean;
	allowSelfSelect?: boolean;
	isSakha?: boolean;
}
