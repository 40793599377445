import React, { useEffect, useState } from 'react'
import InputField from '../../components/reusables/@InputElements/InputField';
import { useForm } from 'react-hook-form';
import Loader from '../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setSuccessToastMessage } from '../../_redux/slices/toast';
import { useMutationHook } from '../../hooks/react-query/useQueryHook';
import TopBar from '../../components/app/topbar';
import PageTitle from '../../components/app/PageTItle';
import DocumentUpload from '../../components/@compose/DocumentUpload';
import { AiFillCloseCircle } from 'react-icons/ai';

export default function NagarpalikaForm() {
    const language = useAppSelector(state => state.language.language);
    const nagarpalikaInfo = useAppSelector(state => state?.auth?.user?.organization)
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();


    const [mainLogo, setMainLogo] = useState("");
    const [campaignLogo, setCampaignLogo] = useState("");
    const [defaultMainLogo, setDefaultMainLogo] = useState("");
    const [defaultCampaignLogo, setDefaultCampaignLogo] = useState("");


    useEffect(() => {
        setMainLogo(nagarpalikaInfo?.main_logo);
        setCampaignLogo(nagarpalikaInfo?.campaign_logo);
        setDefaultMainLogo(nagarpalikaInfo?.main_logo);
        setDefaultCampaignLogo(nagarpalikaInfo?.campaign_logo);
        setValue("name_np", nagarpalikaInfo?.fullname_np);
        setValue("name_en", nagarpalikaInfo?.fullname_en);
        setValue("phone_en", nagarpalikaInfo?.phone_en);
        setValue("email", nagarpalikaInfo?.email);
        setValue("address_np", nagarpalikaInfo?.address_np);
        setValue("address_en", nagarpalikaInfo?.address_en);
        setValue("province_np", nagarpalikaInfo?.province_np);
        setValue("province_en", nagarpalikaInfo?.province_en);
        setValue("district_np", nagarpalikaInfo?.district_np);
        setValue("district_en", nagarpalikaInfo?.district_en);
        setValue("slogan_en", nagarpalikaInfo?.slogan_en);
        setValue("slogan_np", nagarpalikaInfo?.slogan_np);
    }, [nagarpalikaInfo, setValue]);

    const { mutate: editNagarpalika, isLoading } = useMutationHook({
        queryRoute: `/nagarpalika/1/`,
        method: 'patch'
    });

    const onSubmit = async (data: any) => {
        try {
            const body = {
                ...data,
                main_logo: mainLogo,
                campaign_logo: campaignLogo,
            }

            editNagarpalika(body);
            dispatch(setSuccessToastMessage("Nagarpalika edited successfully!"));
        } catch (err) {
            dispatch(setSuccessToastMessage("Something went wrong!"));
        }
    };

    const handleFileLoad = (id: string, files: any) => {
        const file = files[0];
        if (!file) return;
        if (id === "main_logo") {
            setMainLogo(file.file);
        } else {
            setCampaignLogo(file.file);
        }
    };
    return (
        <>
            <TopBar showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="नगरपालिका" />

                </div>


                <div className="settings-content-container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='grid grid-cols-2 gap-4'>
                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Main Logo
                                </label>
                                <div>
                                    <div
                                        className='flex w-full'
                                    >
                                        <DocumentUpload onUpload={(files) => handleFileLoad('main_logo', files)} properties={{
                                            accept: {
                                                'image/*': ['.jpeg', '.png']
                                            }
                                        }}
                                            image
                                        />



                                    </div>
                                    {defaultMainLogo && (
                                        <div className='relative w-48 border rounded-md p-4 group'>
                                            <img
                                                src={defaultMainLogo}
                                                alt="/"
                                                className=''
                                            />

                                            <div className='absolute top-1 right-1 opacity-0 transition group-hover:opacity-100'>
                                                <AiFillCloseCircle className='text-red-400 cursor-pointer' fontSize={24} onClick={() => { setMainLogo(''); setDefaultMainLogo('') }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Campaign Logo
                                </label>
                                <div>
                                    <DocumentUpload onUpload={(files) => handleFileLoad('campaign_logo', files)} properties={{
                                        accept: {
                                            'image/*': ['.jpeg', '.png']
                                        }
                                    }}
                                        image />
                                </div>

                                {defaultCampaignLogo && (
                                    <div className='relative w-48 border rounded-md p-4 group'>
                                        <img
                                            src={defaultCampaignLogo}
                                            alt="/"
                                            className=''
                                        />

                                        <div className='absolute top-1 right-1 opacity-0 transition group-hover:opacity-100'>
                                            <AiFillCloseCircle className='text-red-400 cursor-pointer' fontSize={24} onClick={() => { setCampaignLogo(''); setDefaultCampaignLogo('') }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='grid grid-cols-4 gap-4'>
                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Name(english)
                                </label>
                                <div>
                                    <InputField
                                        elementId='fullname_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.fullname_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Name(nepali)
                                </label>
                                <div>
                                    <InputField
                                        elementId='fullname_np'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.fullname_np}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Address(english)
                                </label>
                                <div>
                                    <InputField
                                        elementId='address_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.address_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Address(nepali)
                                </label>
                                <div>
                                    <InputField
                                        elementId='address_np'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.address_np}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Province(english)
                                </label>
                                <div>
                                    <InputField
                                        elementId='province_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.province_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Province(nepali)
                                </label>
                                <div>
                                    <InputField
                                        elementId='province_np'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.province_np}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    District(english)
                                </label>
                                <div>
                                    <InputField
                                        elementId='district_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.district_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    District(nepali)
                                </label>
                                <div>
                                    <InputField
                                        elementId='district_np'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.district_np}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Email
                                </label>
                                <div>
                                    <InputField
                                        elementId='email'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.email}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Phone no.
                                </label>
                                <div>
                                    <InputField
                                        elementId='phone_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.phone_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Slogan.(english)
                                </label>
                                <div>
                                    <InputField
                                        elementId='slogan_en'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.slogan_en}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                            <div
                                className="mb-3 col-span-1"
                            >
                                <label className='block font-semibold mb-1'>
                                    Slogan.(nepali)
                                </label>

                                <div>
                                    <InputField
                                        elementId='slogan_np'
                                        register={register}
                                        setValue={setValue}
                                        className='wardform-input textbox_req border'
                                        value={nagarpalikaInfo?.slogan_np}
                                        language={language}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <button
                                className='flex items-center rounded-xl bg-blue-400 text-center text-white cursor-pointer hover:ease-in-out duration-500 py-2 px-3'
                                disabled={isLoading}
                                type="submit"
                            >
                                {
                                    isLoading ?
                                        <Loader isLoading
                                            className="!p-0"
                                        />
                                        :
                                        "सम्पादन गर्नुहोस"
                                }

                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}