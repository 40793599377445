import { useEffect, useState } from "react"
import InputField from "../reusables/@InputElements/InputField";
import { useSelector } from "react-redux";
import { FaEdit, FaMinus, FaPlus } from "react-icons/fa";

export default function ReferenceContainer({ defaultValue, setValue }) {
    const language = useSelector(state => state.language.language);

    const [hasReferences, setHasReferences] = useState(false);
    const [references, setReferences] = useState([]);
    const [reference, setReference] = useState('');
    const [editReferenceIdx, setEditReferenceIdx] = useState(null);

    useEffect(() => {
        if (defaultValue && defaultValue?.length > 0) {
            setHasReferences(true);
            setReferences(defaultValue);
        }
    }, [defaultValue])

    const handleHasReferenceChange = (event) => {
        setHasReferences(event.target.checked);

        if (!event.target.checked) {
            setReferences([]);
            setValue('references', [])
            setReference(null);
            setEditReferenceIdx(null);
        }
    }

    const handleChangeReference = (text) => setReference(text);

    const handleAddReference = () => {
        if (!reference || !reference.trim()) return;
        const tempReferences = [...references];

        if (editReferenceIdx) {
            tempReferences[editReferenceIdx] = reference;
            setEditReferenceIdx(null);
        }
        else {
            tempReferences.push(reference);
        }
        setReferences(tempReferences);
        setReference('');
        setValue('references', tempReferences)
    }

    const handleDeleteReference = (idx) => {
        const newReferences = [...references];
        newReferences.splice(idx, 1);
        setReferences(newReferences)
        setValue('references', newReferences)
    }

    const handleEditReference = (idx) => {
        setEditReferenceIdx(idx);
        setReference(references[idx]);
    }

    return (
        <div className="mb-4">
            <div className="flex items-center text-lg font-semibold">
                बोधार्थ<div className="ml-2"><input type="checkbox" className="cursor-pointer" onChange={handleHasReferenceChange} /></div>
            </div>

            {
                hasReferences &&
                <div className="grid grid-cols-4 mt-3">
                    <div className="col-span-1">
                        <div className="flex mb-1">
                            <InputField
                                language={language} className="w-full px-2" type="text" value={reference} getInputData={handleChangeReference} placeholder="कार्यालयको नाम, ठेगाना" />
                            <button type="button" className="btn-sm bg-secondary text-white rounded ml-1 hover:bg-blue-600" onClick={handleAddReference}><FaPlus /></button>
                        </div>
                        <ol>
                            {
                                references.map((reference, index) => {
                                    return <li key={index} className="grid grid-cols-2 gap-3 mb-1">
                                        <span className="col-span-1">
                                            -  {reference}
                                        </span>

                                        <span className="col-span-1">
                                            <button type="button" className="btn-sm border text-blue-500 border-blue-500  hover:bg-blue-500 hover:text-white rounded mr-1" onClick={() => handleEditReference(index)}><FaEdit /></button>

                                            <button type="button" className="btn-sm border text-red-500 border-red-500 hover:bg-red-500  hover:text-white rounded" onClick={() => handleDeleteReference(index)}><FaMinus /></button>
                                        </span>
                                    </li>
                                })
                            }
                        </ol>
                    </div>
                </div>
            }
        </div>
    )
}
