import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//
import store from "./_redux/store";

//
import App from "./App";

//
import "./index.css";
import "nepali-datepicker-reactjs/dist/index.css";
import "react-image-lightbox/style.css";
import LightBoxContainer from "./components/LightBoxContainer";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
          <LightBoxContainer />
        </Router>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
