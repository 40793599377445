import { FC } from "react";
import cls from "classnames";

import {
  IoArchiveOutline,
  IoDocuments,
  IoDocumentAttachOutline,
  IoDocumentLockOutline,
} from "react-icons/io5";
import { useQueryClient } from "@tanstack/react-query";
import { nepaliNumber } from "../../../_helpers/methods";


export type InboxTabType = 'ALL' | "NOT FORTUATED" | "FORTUATED" | "ARCHIVED";

interface TabBarProps {
  tab: InboxTabType;
  setTab: (tab: InboxTabType) => void;
}

const TabBar: FC<TabBarProps> = ({ tab, setTab }) => {
  const handleTabChange = (tab: InboxTabType) => {
    setTab(tab);
  };
  const queryClient = useQueryClient();

  const cacheInboxStatsData: any = queryClient.getQueryData(['inbox-stats']);

  return (
    <div className="grid grid-cols-4">
      <div className="relative">
        <button
          className={cls(
            "flex items-center w-full text-left p-4",
            "text-slate-500 hover:bg-[#eaf1fb]",
            tab === "ALL"
              ? "font-medium !text-blue-500 bg-[#eaf1fb] before:content-[''] before:block before:h-[3px] before:absolute before:mx-2 before:my-0 before:rounded-t-[3px] before:bottom-0 before:inset-x-0 before:bg-blue-500"
              : " ",
            "transition-all duration-200"
          )}
          onClick={() => handleTabChange("ALL")}
        >
          <IoDocuments fontSize={18} />
          <span className="pl-4">सबै पत्रहरु</span>
          {
            Boolean(cacheInboxStatsData?.data?.total) &&
            <span className="ml-3 badge bg-slate-400 border-slate-400">
              {nepaliNumber(cacheInboxStatsData?.data?.total)}</span>
          }
        </button>
      </div>
      <div className="relative">
        <button
          className={cls(
            "flex items-center w-full text-left p-4",
            "text-slate-500 hover:bg-[#eaf1fb] ",
            tab === "NOT FORTUATED"
              ? "font-medium !text-blue-500 bg-[#eaf1fb] before:content-[''] before:block before:h-[3px] before:absolute before:mx-2 before:my-0 before:rounded-t-[3px] before:bottom-0 before:inset-x-0 before:bg-blue-500"
              : " ",
            "transition-all duration-200"
          )}
          onClick={() => handleTabChange("NOT FORTUATED")}
        >
          <IoDocumentAttachOutline fontSize={18} />
          <span className="pl-4">फर्स्यौट नभएका पत्रहरु</span>

          {
            Boolean(cacheInboxStatsData?.data?.unsettled) &&
            <span className="ml-3 badge badge-secondary text-white">{
              nepaliNumber(cacheInboxStatsData.data.unsettled)}</span>
          }
        </button>
      </div>
      <div className="relative">
        <button
          className={cls(
            "flex items-center w-full text-left p-4",
            "text-slate-500 hover:bg-[#eaf1fb]",
            tab === "FORTUATED"
              ? "font-medium !text-blue-500 bg-[#eaf1fb] before:content-[''] before:block before:h-[3px] before:absolute before:mx-2 before:my-0 before:rounded-t-[3px] before:bottom-0 before:inset-x-0 before:bg-blue-500"
              : " ",
            "transition-all duration-200"
          )}
          onClick={() => handleTabChange("FORTUATED")}
        >
          <IoDocumentLockOutline fontSize={18} />
          <span className="pl-4">फर्स्यौट भएका पत्रहरु</span>

          {
            Boolean(cacheInboxStatsData?.data?.settled) &&
            <span className="ml-3 badge border-green-500 bg-green-500 text-white">{nepaliNumber(cacheInboxStatsData?.data?.settled)}</span>
          }
        </button>
      </div>
      <div className="relative">
        <button
          className={cls(
            "flex items-center w-full text-left p-4",
            "text-slate-500 hover:bg-[#eaf1fb]",
            tab === "ARCHIVED"
              ? "font-medium !text-blue-500 bg-[#eaf1fb] before:content-[''] before:block before:h-[3px] before:absolute before:mx-2 before:my-0 before:rounded-t-[3px] before:bottom-0 before:inset-x-0 before:bg-blue-500"
              : " ",
            "transition-all duration-200"
          )}
          onClick={() => handleTabChange("ARCHIVED")}
        >
          <IoArchiveOutline fontSize={18} />
          <span className="pl-4">Archive</span>
          {
            Boolean(cacheInboxStatsData?.data?.archived) &&
            <span className="ml-3 badge border-red-500 bg-red-500 text-white">{nepaliNumber(cacheInboxStatsData?.data?.archived)}</span>
          }
        </button>
      </div>
    </div>
  );
};

export default TabBar;
