import FormGroup from "./_new/FormGroup";

function Elements({
  elements,
  language,
  register,
  control,
  errors,
  setValue,
  values = {},
  category,
  isPublic,
  handleDeleteTable,
  handleEditTable
}) {
    const element = (ele, index) => {
    switch (ele.key) {
      case "FormGroup":
        return (
          <FormGroup
            key={`${ele.title}_${ele.id}`}
            data={ele}
            register={register}
            errors={errors}
            values={values}
            setValue={setValue}tableId
            control={control}
            systemLanguage={language}
            category={category}
            isPublic={isPublic}
            entity={ele.entity}
            handleDeleteTable={(tableId) => handleDeleteTable(tableId, ele)}
            handleEditTable={(tableId) => handleEditTable(tableId, ele, index)}
          />
        );
      default:
        return null;
    }
  };
  return elements && elements.length > 0
    ? elements.map((ele, index) => element(ele, index))
    : null;
}

export default Elements;
