import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';

//
import AuthServices, { ILoginProps } from '../../apis/services/AuthServices';

//
import { useAppDispatch } from '../../hooks/redux';

//
import { loginUser } from '../../_redux/slices/auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { USER_PROFILE_TOKEN_NAME } from '../../constants/constants';
import { useQueryHook } from '../../hooks/react-query/useQueryHook';
import packageJson from '../../../package.json';

const ssoTitle = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO_TITLE;
const sso =
	process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true"

/**
 *
 */
export default function LoginPage() {
	const location = useLocation();
	const token = new URLSearchParams(location?.search).get('token');
	const [isSSOLoading, setIsSSOLoading] = useState<boolean>(token ? true : false);
	const [passwordType, setPasswordType] = useState<string>('password');

	const { register, handleSubmit, watch } = useForm();
	const [apiError, setApiError] = useState<string | null>(null);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const email = watch('email');
	const password = watch('password');
	const canSubmit = email && password;

	const { data } = useQueryHook({
		queryName: 'nagarpalika_public',
		queryRoute: `/nagarpalika/`,
	});

	const nagarpalikaData = data?.data?.results?.length ? data?.data?.results[0] : {};

	const { mutate, isLoading } = useMutation(
		(credentials: ILoginProps) => {
			return AuthServices.login(credentials);
		},
		{
			onSuccess: (data) => {
				try {
					let tokens = data?.data?.data;

					dispatch(loginUser(tokens, navigate));
				} catch (error) {
					console.log('ERROR Logging in', error);
				}
			},
			onError: (error: any) => {
				setApiError('Email or password is incorrect!');
			},
		}
	);
	const onSubmit = handleSubmit((data) => {
		setApiError(null)
		mutate({
			email: data.email,
			password: data.password,
		});
	});

	useEffect(() => {
		const getTokens = async () => {
			try {
				const response = await axios.get(process.env.REACT_APP_BACKEND_API + `/oauth/login/?token=${token}`);
				dispatch(loginUser(response.data.data, navigate));
				setIsSSOLoading(false);
			}
			catch (error) {
				setIsSSOLoading(false);
			}
		};
		if (token) {
			localStorage.setItem(USER_PROFILE_TOKEN_NAME, token)
			getTokens();
		}
	}, [token, dispatch, navigate]);

	const redirectSSO = async () => {
		const redirectLink = document.createElement('a');
		// redirectLink.target = '_blank';
		let link = `${process.env.REACT_APP_SSO_URL}/o/consent/${process.env.REACT_APP_APPLICATION_ID}`;
		if (process.env.NODE_ENV === "development") {
			link += `?development=true`
		}
		redirectLink.href = link
		redirectLink.click();
	};

	useEffect(() => {
		if (sso) {
			const showLoginPage = localStorage.getItem('showLoginPage');
			if (!showLoginPage) {
				localStorage.setItem('showLoginPage', 'true');
				redirectSSO();
			}
		}
	}, []);

	return (
		<Loader
			isLoading={isSSOLoading}
			fontSize={64}
			className="h-screen w-screen grid place-content-center"
			iconClassName="text-[#D24DA6]"
		>
			<div className="bg-[#bee0f6] relative isolate">
				<div style={{
					backgroundImage: "url('/assets/images/nepal-map.svg')",
					position: 'absolute',
					inset: 0,
					opacity: 0.4,
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					zIndex: '-1',
				}} />

				<form onSubmit={onSubmit}>
					<div className="container mx-auto flex justify-center align-items-center min-h-screen">
						<div className="card max-w-[480px] mx-2 glassmorphism col-span-2 shadow-md h-max my-auto pt-8 px-6 pb-8">
							<div className="flex flex-col items-center mb-4">
								<img src="/assets/icons/nep_logo.png" className="w-1/4 mb-4" alt="" />
								<h4 className="text-2xl font-bold text-red-600">
									{nagarpalikaData.fullname_np || nagarpalikaData.name_np}
								</h4>
								<h5 className="text-4xl font-semibold text-blue-600">पत्राचार प्रणाली</h5>
							</div>
							<div className="flex flex-col mb-3">
								<label>इमेल</label>
								<input className="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2" {...register('email')} type="email" />
							</div>

							<div className="flex flex-col mb-2 relative">
								<label>पासवर्ड</label>
								<input className="border border-[#cccccc] w-full p-2 h-auto rounded focus:outline-none focus:border-blue-500 focus:border-2" {...register('password')} type={passwordType} />
								{passwordType === 'password' ? (
									<FaEye
										className="absolute bottom-3.5 right-4"
										role="button"
										title="show password"
										onClick={() => setPasswordType('text')}
									/>
								) : (
									<FaEyeSlash
										className="absolute bottom-3.5 right-4"
										role="button"
										title="hide password"
										onClick={() => setPasswordType('password')}
									/>
								)}
							</div>

							<div>{apiError && <div className="text-red-500 text-semibold">{apiError}</div>}</div>
							<div className="flex items-stretch  mt-3">
								<button className="btn btn-primary grow text-white" type="submit" disabled={isLoading || !canSubmit}>
									{isLoading ? <Loader isLoading className="!py-0" /> : 'Login'}
								</button>
							</div>

							{
								sso &&
								<div>
									<div className='my-6 text-center'>
										<span className='px-4'>OR</span>
									</div>

									<div className='flex items-stretch'>
										<button
											className="btn border-primary text-primary bg-white hover:bg-primary hover:text-white	hover:border-white grow"
											type="button"
											onClick={redirectSSO}
											disabled={isLoading}
										>
											{ssoTitle ?? 'Login with SSO'}
										</button>
									</div>
								</div>
							}

							<span className='mt-6 text-center text-sm'>Powered by:

								<a className="block text-green-500 hover:text-green-400" href="https://ideabreed.com.np/" target="_blank" rel="noreferrer">Idea Breed IT Solution Pvt. Ltd.</a>
							</span>
						</div>
					</div>
				</form>
			</div>
			<div className='absolute bottom-0 right-0 text-slate-500 px-3'>v{packageJson.version}</div>
		</Loader>
	);
}
