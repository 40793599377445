import axios from 'axios';

//
import { authHeader } from '../../_helpers/auth-header';

/**
 *
 */
export function Axios(options?: any) {
	options = options ?? {};
	let baseURL = options.env ? process.env[options.env] : process.env.REACT_APP_BACKEND_API;

	return axios.create({
		baseURL: baseURL,
		headers: {
			...authHeader(options.multipart)
		}
	});
}


// reference: https://semaphoreci.com/blog/api-layer-react
