import { useParams } from 'react-router-dom';
import Loader from '../../components/app/Loader';
import TopBar from '../../components/app/topbar';
import FormElements from '../../components/Forms';
import { useQueryHook } from '../../hooks/react-query/useQueryHook';
import { useEffect, useState } from 'react';
import FormInput from '../../components/reusables/FormInput';
import { useAppSelector } from '../../hooks/redux';
import { nepaliNumberConverter } from '../../_helpers/template';

function Template() {
	const { templateId } = useParams();
	const users = useAppSelector((state) => state.auth?.user?.organization?.users);
	const activeFiscalYear = useAppSelector((state) => state.auth.activeFiscalYear);

	const [label, setLabel] = useState<string>('');
	const [signatory, setSignatory] = useState<any>(null);
	const [signatoriesOptions, setSignatoriesOptions] = useState({});

	const [chalaniData, setChalaniData] = useState({});

	useEffect(() => {
		let defaultChalaniData = {
			receiving_department: '',
			address: '',
			sending_medium: '',
			related_branch: '',
			paper_count: '',
			remarks: '',
		};

		if (activeFiscalYear) {
			defaultChalaniData = {
				...defaultChalaniData,
				paper_count: nepaliNumberConverter(activeFiscalYear),
			};
		}
		setChalaniData(defaultChalaniData)

	}, [activeFiscalYear])

	useEffect(() => {
		if (users?.length) {
			let tempOptions: any = {};
			users.forEach((user: any) => {
				if (user.role === 'ward_admin') {
					tempOptions[`${user.fullname_np}_${user.post_np}`] = `${user.post_np}_${user.fullname_np}`;
				} else if (user.role === 'ward_kawa') {
					if (user.is_active) {
						tempOptions[`${user.fullname_np}_${user.post_np}`] = `${user.post_np}_${user.fullname_np}`;
					}
				} else {
					tempOptions[`${user.fullname_np}_${user.post_np}`] = `${user.post_np}_${user.fullname_np}`;
					if (user.post_en === 'CAO') {
						setSignatory({ value: `${user.fullname_np}_${user.post_np}`, label: `${user.post_np}_${user.fullname_np}` });
					}
				}
			});

			if (Object.values(tempOptions).length === 1) {
				Object.entries(tempOptions).forEach(([key, value]) => {
					setSignatory({ value: key, label: value });
				});
			}
			setSignatoriesOptions(tempOptions);
		}
	}, [users]);

	const { data, isLoading } = useQueryHook({
		queryName: `template_${templateId}`,
		queryRoute: `/template/${templateId}/`,
	});

	const handleLabelChange = (value: string) => {
		setLabel(value);
	};

	const handleSignatoryChange = (value: any) => {
		setSignatory(value);
	};

	useEffect(() => {
		if (data?.data?.label) {
			setLabel(data?.data?.label);
		}
	}, [data?.data]);

	const handleChalaniInputChange = (name: string, value: string) => {
		setChalaniData({
			...chalaniData,
			[name]: value,
		});
	};

	return (
		<div className="w-full">
			<TopBar showBackButton />

			<div className="px-6 scroll-content">
				{isLoading ? (
					<div className="p-8 flex justify-center">
						<Loader />
					</div>
				) : (
					<>
						{data?.data && (
							<>
								<div className="border-b-2 border-slate-200">
									<div className="grid grid-cols-12">
										<div className="col-span-4">
											<label>
												<FormInput
													id="label"
													label="बिषय"
													type="Text"
													defaultValue={label}
													getInputData={handleLabelChange}
													className="h-[2.4rem]"
												/>
											</label>
										</div>

										<div className="col-span-4" />

										<div className="col-span-4">
											<label>
												<FormInput
													id="signatory"
													label="हस्ताक्षरकर्ता"
													type="Select"
													defaultValue={signatory}
													getInputData={handleSignatoryChange}
													isMulti={false}
													options={signatoriesOptions}
												/>
											</label>
										</div>
									</div>
								</div>

								<FormElements
									data={data.data}
									label={label}
									signatory={signatory}
									chalaniData={chalaniData}
									onChalaniInputChange={handleChalaniInputChange}
								/>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default Template;
