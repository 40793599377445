import React, { FC, useState } from 'react';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import 'nepali-datepicker-reactjs/dist/index.css';
import classNames from 'classnames';

interface DatePickerType {
	elementId: string | number;
	defaultValue?: string;
	handleDateChange: any;
	isDisabled?: boolean;
}

const DatePicker: FC<DatePickerType> = ({ elementId, defaultValue, handleDateChange, isDisabled }) => {
	const [date, setDate] = useState<string>(defaultValue ? defaultValue : '');

	const handleInputChange = (value: string) => {
		if (isDisabled) return;

		setDate(value);
		handleDateChange(value);
	};

	return (
		<div>
			<div className={isDisabled ? 'pointer-events-none' : ''}>
				<NepaliDatePicker
					inputClassName={classNames(isDisabled ? 'bg-gray-200' : '', "input input-bordered w-full border")}
					className=""
					value={date}
					onChange={handleInputChange}
					options={{ calenderLocale: 'ne', valueLocale: 'en' }}
				/>
			</div>
		</div>
	);
};

export default DatePicker;
