import { useEffect } from "react";
import Lightbox from "react-image-lightbox";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { setLightBoxImage } from "../_redux/slices/imagePreview";
import LightBoxDownloadBtn from "./LightBoxDownloadBtn";

function LightBoxContainer() {
  const dispatch = useAppDispatch();

  const image = useAppSelector((state) => state.image.image);
  const handleClose = () => dispatch(setLightBoxImage(""));

  useEffect(() => {
    return () => dispatch(setLightBoxImage(null));
  }, [dispatch]);

  return (
    <div>
      {!!image && (
        <Lightbox
          mainSrc={image}
          mainSrcThumbnail={image}
          toolbarButtons={[<LightBoxDownloadBtn image={image} />]}
          onCloseRequest={handleClose}
        />
      )}
    </div>
  );
}

export default LightBoxContainer;
