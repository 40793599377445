import dayjs from "dayjs";
import NepaliDate from "nepali-date-converter";

export default function DisplayNepaliDate({ date }: { date: string }) {
  const [yyyy, mm, dd] = dayjs(date)
    .format("YYYY-MM-DD")
    .split("-")
    .map((number) => parseInt(number));

  return (
    <div>{new NepaliDate(new Date(yyyy, mm, dd)).format("YYYY-MM-DD")}</div>
  );
}
