import classNames from 'classnames';
import React, { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';

/**
 *
 */
function ImagePreviewer({ images }: IImagePreviewerProps) {
	const firstImage = images[0];
	const hasMoreThanOneImage = images.length > 1;

	const [onDisplayImage, setOnDisplayImage] = useState(firstImage);

	return (
		<div className="print-img-previewer">
			<div className="grid gap-1 grid-cols-12 hide-in-print">
				<div className={hasMoreThanOneImage ? 'col-span-10' : 'col-span-12'}>
					<div className="flex justify-center mb-4 relative">
						{onDisplayImage ? (
							<>
								<div className="absolute top-0 right-0">
									<div>
										<a
											href={onDisplayImage}
											className="block bg-slate-200 p-2 rounded-md hover:bg-slate-400 hover:text-slate-50 hover:transition-all"
											download="file"
										>
											<AiOutlineDownload fontSize={24} />
										</a>
									</div>
								</div>

								<img src={onDisplayImage} alt="first file" className="cursor-pointer w-full max-w-[1120px]" />
							</>
						) : (
							<p className="text-center font-bold">पत्र अपलोड गरिएको छैन</p>
						)}
					</div>
				</div>
				{hasMoreThanOneImage && (
					<div className="col-span-2">
						<div className="flex flex-col bg-slate-100 rounded p-2 overflow-y-auto max-h-[650px] sideImagScroll">
							{images.length > 0 ? (
								images.map((image: string, index: number) => {
									return (
										<>
											<div
												key={image}
												className={classNames(
													'border-4 rounded-lg hover:shadow-lg mb-3',
													onDisplayImage === image && ' border-blue-400 border-2'
												)}
											>
												<img
													className="carousel-item object-cover cursor-pointer rounded"
													src={image}
													alt="imagess"
													onClick={() => setOnDisplayImage(image)}
												/>
											</div>
										</>
									);
								})
							) : (
								<p className="text-center font-bold">पत्र अपलोड गरिएको छैन</p>
							)}
						</div>
					</div>
				)}
			</div>

			{/* For print */}
			{images.map((image: string, index: number) => {
				return (
					<div key={image}>
						<img className="print-mail-img hidden" src={image} alt="imagess" />
					</div>
				);
			})}
		</div>
	);
}

export default ImagePreviewer;

interface IImagePreviewerProps {
	images: string[];
}
