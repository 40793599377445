/**
 * 
 */
export default function RadioComponent(props: ISelectProps) {
    const options = Object.entries(props.options || {}).map(([value, label]) => ({ value: value, label: label }));
    const checkedValue = props.selectedValue || 'medium';

    return (
        <div className="radio-inputDiv">
            {
                options.map(option => {
                    return (
                        <label className="mr-6 flex items-center">
                            <input className="mr-1" id={props.elementId} type="radio" value={option.value} onClick={props.handleRadioChange} checked={checkedValue === option.value} />
                            <span className={`${option.value}-label`}>{option.label}</span>
                        </label>
                    )
                })
            }
        </div>
    )
}

interface ISelectProps {
    elementId: string;
    options: { [value: string | number]: string };
    selectedValue?: any;
    register?: any;
    validations?: any;
    handleRadioChange?: (value: any) => void
}