import cls, { Argument } from 'classnames';
import { FC } from 'react';

interface ISkeleton {
	className?: Argument;
}

const Skeleton: FC<ISkeleton> = ({ className }) => {
	return (
		<div className="animate-pulse !w-full">
			<div className={cls('rounded-sm !w-full space-y-2 p-2', className)}>
				<Text className="!w-full h-[28px]" />
				<Text className="w-[200px] h-[28px] mb-4" />

				<div className='flex justify-center mb-6 !w-full'>
					<div className={cls('h-[500px] !w-full bg-slate-300 rounded')}></div>
				</div>


				<Text className="!w-full h-[100px]" />
				<Text className="!w-full h-[100px]" />
				<Text className="!w-full h-[100px]" />
			</div>
		</div>
	);
};

const Text: FC<ISkeleton> = ({ className }) => {
	return (
		<div className="animate-pulse">
			<div className={cls('h-2 w-32 bg-slate-300 rounded', className)}></div>
		</div>
	);
};

const Box: FC<ISkeleton> = ({ className }) => {
	return (
		<div className="animate-pulse">
			<div className={cls('h-24 w-32 bg-slate-300 rounded', className)}></div>
		</div>
	);
};


const Circle: FC<ISkeleton> = ({ className }) => {
	return (
		<div className="animate-pulse">
			<div className={cls('rounded-full bg-slate-300 h-10 w-10', className)}></div>
		</div>
	);
};

export default Object.assign(Skeleton, { Text, Circle, Box });
