import { AiOutlineFile } from "react-icons/ai";
import InboxSidebarItem from "../@sidebar/InboxSidebarItem";
import { RiSendPlane2Line } from "react-icons/ri";
import { IoStar } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";

/**
 * 
 */
const SidebarData: ISidebarData[] = [
  // {
  //   id: 1,
  //   title: "ड्यासवोर्ड",
  //   path: "/",
  //   icon: <AiOutlineHome />
  // },
  {
    id: 2,
    component: InboxSidebarItem,
  },
  {
    id: 3,
    // title: "ताराङ्कित पत्रहरु",
    title: "Starred",
    path: "/starred",
    icon: <IoStar className="text-slate-500 " />,
  },
  {
    id: 4,
    // title: "ड्राफ्ट पत्रहरु",
    title: "Draft",
    path: "/draft",
    icon: <AiOutlineFile />,
    activePaths: ['preview']

  },
  {
    id: 5,
    // title: "पठाइएका पत्रहरु",
    title: "Sent",
    path: "/sent",
    icon: <RiSendPlane2Line />,
    activePaths: ['sent-mail']
  },
  {
    id: 6,
    // title: "सेटिंग्स",
    title: "Settings",
    path: "/settings",
    icon: <FiSettings />,
    permittedRoles: ['ito_admin'],
    dontShow: Boolean(process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true"),
    activePaths: ['settings']
  },
];

export default SidebarData;

export interface ISidebarData {
  id: number;
  title?: string;
  component?: any;
  icon?: any;
  path?: string;
  permittedRoles?: any;
  subNav?: ISidebarData[];
  dontShow?: boolean;
  activePaths?: string[]
}
