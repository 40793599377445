import { Axios } from "../configs/axiosConfig";

const AuthServices = {
	async login(params: ILoginProps) {
		const response = await Axios().post('/auth/jwt/create/', params);
		return response;
	},

	async getUserData() {
		try {
			const response = await Axios().get('/user/me/');
			return response.data.data;
		} catch (error) {
			console.log(error);
		}
	},

	async getUserProfileData() {
		try {
			const response = await Axios({
				env: 'REACT_APP_SSO_API'
			}).get(`/profile/info/`);
			return response?.data?.data;
		} catch (error) {
			console.log(error);
		}
	},
};

export interface ILoginProps {
	email: string;
	password: string;
}

export default AuthServices;
