import * as React from "react";
import deepEqual from "fast-deep-equal";
import * as PropTypes from "prop-types";

import setUnicodeWithGivenPosition from "../../_helpers/language/newtype";
import { getCurrentCursorPosition, getDifference, normalizeHtml, setCurrentCursorPosition } from "../../_helpers/template";

function replaceCaret(el) {
  // Place the caret at the end of the element
  const target = document.createTextNode("");
  el.appendChild(target);
  // do not move caret if element was not focused
  const isTargetFocused = document.activeElement === el;
  if (target !== null && target.nodeValue !== null && isTargetFocused) {
    var sel = window.getSelection();
    if (sel !== null) {
      var range = document.createRange();
      range.setStart(target, target.nodeValue.length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    if (el instanceof HTMLElement) el.focus();
  }
}

/**
 * A simple component for an html element with editable contents.
 */
export default class ContentEditable extends React.Component {
  lastHtml = this.props.html;
  isFirst = true;

  cursorPosition;
  el =
    typeof this.props.innerRef === "function"
      ? { current: null }
      : React.createRef();

  getEl = () =>
    (this.props.innerRef && typeof this.props.innerRef !== "function"
      ? this.props.innerRef
      : this.el
    ).current;

  render() {
    const { tagName, html, innerRef, ...props } = this.props;

    // eslint-disable-next-line react/no-danger-with-children
    return React.createElement(
      tagName || "div",
      {
        ...props,
        ref:
          typeof innerRef === "function"
            ? (current) => {
              innerRef(current);
              this.el.current = current;
            }
            : innerRef || this.el,
        onInput: this.emitChange,
        // onBlur: this.props.onBlur || this.emitChange,
        // onKeyUp: this.props.onKeyUp || this.emitChange,
        // onKeyDown: this.props.onKeyDown || this.emitChange,
        onKeyPress: this.props.onKeyPress || this.onKeyPress,
        contentEditable: !this.props.disabled,
        dangerouslySetInnerHTML: { __html: html },
      },
      this.props.children
    );
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    const el = this.getEl();

    // We need not rerender if the change of props simply reflects the user's edits.
    // Rerendering in this case would make the cursor/caret jump

    // Rerender if there is no element yet... (somehow?)
    if (!el) return true;

    // ...or if html really changed... (programmatically, not by user edit)
    if (normalizeHtml(nextProps.html) !== normalizeHtml(el.innerHTML)) {
      return true;
    }

    // Handle additional properties
    return (
      props.disabled !== nextProps.disabled ||
      props.tagName !== nextProps.tagName ||
      props.className !== nextProps.className ||
      props.innerRef !== nextProps.innerRef ||
      props.placeholder !== nextProps.placeholder ||
      !deepEqual(props.style, nextProps.style)
    );
  }

  componentDidUpdate() {
    const el = this.getEl();
    if (!el) return null;

    // Perhaps React (whose VDOM gets outdated because we often prevent
    // rerendering) did not update the DOM. So we update it manually now.
    if (this.props.html !== el.innerHTML) {
      el.innerHTML = this.props.html;
    }
    this.lastHtml = this.props.html;
    this.cursorPosition
      ? setCurrentCursorPosition(this.cursorPosition, "preview")
      : replaceCaret(el);
  }

  emitChange = (originalEvt) => {
    const el = this.getEl();
    let isMerged;
    if (!el) return null;

    let html = el.innerHTML;
    if (!this.props.isEnglish) {
      let tempHtml = this.lastHtml;
      let HtmlLengthBool = false;

      if (
        this.isFirst &&
        originalEvt.nativeEvent.data &&
        originalEvt.nativeEvent.data !== " "
      ) {
        tempHtml = new DOMParser().parseFromString(tempHtml, "text/html").body
          .innerHTML;
        HtmlLengthBool = true;
        this.isFirst = false;
      }

      if (
        (html.length > tempHtml.length ||
          HtmlLengthBool ||
          originalEvt.nativeEvent.inputType === "insertText") &&
        originalEvt.nativeEvent.inputType !== "insertParagraph"
      ) {
        if (!this.isFirst) HtmlLengthBool = false;
        let pos = getDifference(tempHtml, html);

        if (pos.result === "&") {
          html = normalizeHtml(html);
          pos = getDifference(tempHtml, html);
        }

        originalEvt.target.value = html;

        if (
          (pos.result === originalEvt.nativeEvent.data &&
            originalEvt.nativeEvent.data !== " ") ||
          pos.result === "ÿ"
        ) {
          const data = setUnicodeWithGivenPosition(
            originalEvt,
            pos.result,
            pos.position,
            "traditional"
          );

          isMerged = data.isMerged;
          html = data.value;
        }
      } else if (originalEvt.nativeEvent.inputType === "insertParagraph") {
      }
    }

    if (this.props.onChange && html !== this.lastHtml) {
      const evt = Object.assign({}, originalEvt, {
        target: {
          value: html,
        },
      });
      this.props.onChange(evt);
    }

    this.cursorPosition = isMerged
      ? getCurrentCursorPosition() - 1
      : getCurrentCursorPosition();
    this.lastHtml = normalizeHtml(html);
  };

  onKeyPress = (originalEvt) => {
    if (originalEvt.which === 13) {
      // // originalEvt.preventDefault();
      // document.execCommand("formatblock", false, "span");
      // const selectedElement = document.getSelection().focusNode.parentNode;
      // selectedElement.classList = "";
      // return false;
    }
  };

  static propTypes = {
    html: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tagName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  };
}
