import React, { useState } from "react";
import { useEffect } from "react";
import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../reusables/@InputElements/InputField";

function Name({
  language,
  register,
  errors = {},
  data,
  elementId,
  value,
  properties = {},
  setValue,
  isPublic,
  entity,
}) {
  const [nameValue, setNameValue] = useState(value);
  const fNameValidation = dynamicValidator(properties?.fName, language);
  const mNameValidation = dynamicValidator(properties?.mName, language);
  const lNamevalidation = dynamicValidator(properties?.lName, language);

  useEffect(() => {
    setNameValue(value);
  }, [value]);

  if (isPublic && entity === "private") {
    return null;
  }

  return (
    <div className="vital-add-info vital-name py-2" key={nameValue}>
      <label className="block font-semibold mb-1" htmlFor="">
        {data.label ? (
          data.label
        ) : (
          <React.Fragment>
            {language === "english" ? "नाम (अंग्रेजीमा)" : "नाम (नेपालीमा)"}
          </React.Fragment>
        )}
        <input id="memberId" name="memberId" type="hidden" value="" />
        <input id="memberDefCd" name="memberDefCd" type="hidden" value="" />
      </label>

      <div className="relative mb-2">
        <div className="flex">
          <InputField
            type="text"
            className={` ${properties?.fName?.isRequired ? "textbox_req" : ""} ${errors[elementId]?.firstName ? "is-invalid" : ""
              } `}
            language={language}
            elementId={`${elementId}.firstName`}
            placeholder={language === "english" ? "First Name" : "अगाडिको नाम"}
            maxLength={50}
            register={register}
            value={nameValue?.firstName}
            style={{ width: "125px" }}
            properties={properties}
            validation={fNameValidation}
            setValue={setValue}
          />

          <InputField
            language={language}
            className={`${properties?.mName?.isRequired ? "textbox_req" : ""} ${errors[elementId]?.middleName ? "is-invalid" : ""
              } `}
            placeholder={language === "english" ? "Middle Name" : "बिचको नाम"}
            style={{ width: "100px" }}
            type="text"
            elementId={`${elementId}.middleName`}
            value={nameValue?.middleName}
            register={register}
            setValue={setValue}
            properties={properties}
            validation={mNameValidation}
          />

          <InputField
            language={language}
            className={`jumper ${properties?.lName?.isRequired ? "textbox_req" : ""
              } ${errors[elementId]?.lastName ? "is-invalid" : ""} `}
            jumpto="firstNameEng"
            placeholder={language === "english" ? "Last Name" : "थर"}
            style={{ width: "125px" }}
            type="text"
            elementId={`${elementId}.lastName`}
            value={nameValue?.lastName}
            register={register}
            setValue={setValue}
            properties={properties}
            validation={lNamevalidation}
          />
        </div>

        {
          errors[elementId] &&
          <div className="absolute error-text">
            कृपया यी फिल्डहरु भर्नुहोस्
          </div>
        }
      </div>

    </div>
  );
}

export default Name;
