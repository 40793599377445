import dayjs from 'dayjs';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { FaEdit, FaEye } from 'react-icons/fa';
import { FiFile } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import TopBar from '../../components/app/topbar';
import Table, { tableDataType, tableHeadType } from '../../components/reusables/Table';
import SifarishPreviewModal from '../../components/SifarishPreviewModal/SifarishPreviewModal';
import { useMutationHook, useQueryHook } from '../../hooks/react-query/useQueryHook';
import { useAppSelector } from '../../hooks/redux';
import { generatePreview } from '../../_helpers/template';
import { setErrorToastMessage } from '../../_redux/slices/toast';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { IoTrashOutline } from 'react-icons/io5';
import Swal from 'sweetalert2';
import { nepaliNumber } from '../../_helpers/methods';
import NepaliDate from 'nepali-date-converter';
import { chalaniDataProps } from '../Template/ChalaniForm';
import Loader from '../../components/app/Loader';

const LIMIT = 50;
var adbs = require('ad-bs-converter');

/**
 *
 */
export default function Draft() {
	const printRef = useRef();
	const actionRef = useRef<any>();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const todayNepaliDate = new NepaliDate().format('YYYY-MM-DD');

	const [chalaniNum, setChalaniNum] = useState(null);
	const [chalaniCreatedOrg, setChalaniCreatedOrg] = useState('');

	const [paperCount, setPaperCount] = useState('');
	const [previewPaperId, setPreviewPaperId] = useState<undefined | Key>();
	const [offset, setOffset] = useState(0);
	const [total, setTotal] = useState(0);
	const [show, setShow] = useState(false);
	const [previewData, setPreviewData] = useState<any>(null);
	const [tableTitles, setTableTitles] = useState({});

	const user = useAppSelector((state) => state?.auth?.user);

	const handlePrint = useReactToPrint({
		content: () => {
			return printRef.current || null;
		},
		onAfterPrint: () => {
			try {
				// dispatch(incrementPaperCountHandler(params.paperId));
			} catch (err) {
				console.log(err, 'Error after print');
			}
		},
	});

	const handleIsSaved = () => {
		console.log('save');
	};

	const handleClose = () => {
		setShow(false);
	};

	const handleIssueId = (issueId: string) => {
		console.log(issueId);
	};

	let render = null;

	const [deleteDraftId, setDeleteDraftId] = useState(null);

	const { mutate: DeleteDraft } = useMutationHook({
		queryRoute: `/paper/draft/${deleteDraftId}/`,
		method: 'delete',
		options: {
			onSuccess: (data) => {
				queryClient.invalidateQueries(['draft']);
				setDeleteDraftId(null);
			},
			onError: (error) => {
				console.log(error, 'error');
				dispatch(setErrorToastMessage('Something went wrong'));
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	useEffect(() => {
		if (deleteDraftId) {
			DeleteDraft({});
		}
	}, [DeleteDraft, deleteDraftId]);

	const handleOnDeleteClick = (selectedItemsId: any) => {
		Swal.fire({
			title: 'के तपाई यो पत्र delete गर्न चाहनुहुन्छ?',
			// text: "You want to delete this Patrachar!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#60A5FA',
			cancelButtonColor: '#f87171',
			confirmButtonText: 'सुनिश्चित गर्नुहोस्',
			cancelButtonText: 'रद्द गर्नुहोस्',
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				setDeleteDraftId(selectedItemsId);
			}
		});
	};

	const headers: tableHeadType[] = useMemo(() => {
		return [
			{
				id: 0,
				cell: (value: tableDataType) => (
					<div className="flex space-x-2 items-center font-medium pl-5">
						{value.chalani_number && (
							<div className="tooltip tooltip-bottom" data-tip={`चलानी नं.: ${nepaliNumber(value.chalani_number)}`}>
								<span>च नं.: {nepaliNumber(value.chalani_number)}</span>
							</div>
						)}
					</div>
				),
			},
			{
				id: 1,
				cell: (value: tableDataType) => (
					<div className="flex space-x-2 items-center font-medium pl-5">
						<span>{value?.title}</span>
					</div>
				),
				dataClassName: 'w-[30%]',
			},
			{
				id: 2,
				dataClassName: 'w-[30%]',
				cell: (value: tableDataType) => <span className="text-slate-500 text-center">{value.label ?? '-'}</span>,
			},
			{
				id: 3,
				cell: (value: tableDataType) => {
					const { ne } = adbs.ad2bs(dayjs(value.date).format('YYYY/M/D'));
					const nepaliDate = `${ne.year}/${ne.month.padStart(2, '०')}/${ne.day.padStart(2, '०')}`;

					const time = dayjs(value.date).format('hh:mm A');
					const isToday = dayjs(value.date).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD');
					return (
						<span className="text-slate-500 mr-4">
							<div className="tooltip tooltip-bottom" data-tip={`${nepaliDate} ${time}`}>
								{isToday ? (
									<span className="text-sm text-slate-600">{time}</span>
								) : (
									<span className="text-sm text-slate-600">{nepaliDate}</span>
								)}
							</div>
						</span>
					);
				},
				dataClassName: 'w-[20%]',
			},
			{
				id: 4,
				cell: (value: tableDataType) => (
					<div className="mr-4">
						<button
							className="btn btn-primary btn-sm text-white mr-3"
							onClick={() => {
								setPreviewData(value);
								setChalaniNum(value.chalani_number);
								setChalaniCreatedOrg(value.chalani_created_fullname);
								setPaperCount(value.form_values?.paper_count);
								setShow(true);
							}}
						>
							<FaEye />
						</button>

						<Link to={`/preview/${value?.id}`}>
							<label className="btn btn-secondary btn-sm text-white" onClick={() => setPreviewPaperId(value.id)}>
								<FaEdit />
							</label>
						</Link>
						{!value.chalani_number && (
							<button className="btn btn-error ml-3 btn-sm text-white" onClick={() => handleOnDeleteClick(value.id)}>
								<IoTrashOutline />
							</button>
						)}
					</div>
				),
				dataClassName: 'w-[10%]',
				noLink: true,
			},
		];
	}, []);

	//
	const handleSuccess = (data: any) => {
		setTotal(data?.data?.count);
		return data;
	};

	const handleError = () => { };

	const {
		data: draftData,
		isLoading,
		isFetching,
	} = useQueryHook({
		queryName: 'draft',
		queryRoute: '/paper/draft/',
		params: [
			['limit', LIMIT],
			['offset', offset * LIMIT],
		],
		options: {
			onSuccess: handleSuccess,
			onError: handleError,
		},
	});

	const generateData = (data: any) => {
		if (data?.results) {
			const final = data?.results?.map((result: any) => {
				return {
					id: result.id,
					title: result?.values?.title,
					date: result.created_at,
					read: !!result.chalani_number,
					...result,
				};
			});
			return final;
		}
		return [];
	};

	const finalData = draftData?.data ? generateData(draftData.data) : [];

	if (isLoading) {
		render = (
			<div className="no-top-content-container">
				<div className="h-2/4 bg-white flex justify-center items-center flex-col rounded m-3">
					<Loader size="64px" />
				</div>
			</div>
		);
	} else if (finalData.length < 1) {
		render = (
			<div className="no-top-content-container">
				<div className="h-2/4 bg-white flex justify-center items-center flex-col rounded m-3 p-14">
					<div className="mb-6 p-9 w-[190px] h-[190px] rounded-full bg-gray-200 flex justify-center items-center">
						<FiFile size={82} color="gray" />
					</div>

					<div className="text-xl">तपाईंसँग कुनै पनि सुरक्षित गरिएका ड्राफ्टहरू छैनन्।</div>
				</div>
			</div>
		);
	} else {
		render = (
			<div className="no-top-content-container">
				<Table headers={headers} data={finalData} rowLinkDestination="preview" noHeader compact />
			</div>
		);
	}

	const handleNextPage = () => {
		setOffset((prev) => prev + 1);
	};

	const handlePrevPage = () => {
		setOffset((prev) => prev - 1);
	};

	//
	const { mutate: PatchPaper } = useMutationHook({
		queryRoute: `/paper/draft/${previewData?.id}/`,
		method: 'patch',
		options: {
			onSuccess: (data) => {
				try {
					if (actionRef.current?.shouldPrint) {
						actionRef.current.shouldPrint = false;
						handlePrint();
					}
					return data;
				} catch (error) {
					console.log('ERROR Composing', error);
				}
			},
			onError: (error) => {
				console.log(error, 'error');
				setErrorToastMessage('Something went wrong');
			},
		},
		axiosOptions: {
			multipart: false,
		},
	});

	const handleTemplateSave = async (val = {}, preview: any, isFromSaveAndPrint = true) => {
		const body = {
			values: previewData?.values,
			form_values: { ...val },
			__save_and_print: false,
		};
		if (isFromSaveAndPrint) {
			body.__save_and_print = true;
			if (actionRef.current) {
				actionRef.current.shouldPrint = true;
			}
		}

		PatchPaper(body);
	};

	const [issueId, setIssueId] = useState('');
	const [isGenerateLoading, setIsGenerateLoading] = useState(false);

	useEffect(() => {
		if (previewData) {
			setIssueId(previewData.issue_id);
			setChalaniNum(previewData.chalani_number);
			setChalaniCreatedOrg(previewData.chalani_created_fullname);
			setPaperCount(previewData?.form_values?.paper_count);
		}
	}, [previewData]);

	//
	const { mutateAsync: PatchGeneratePaper } = useMutationHook({
		queryRoute: `/paper/draft/${previewData?.id}/`,
		method: 'patch',
		axiosOptions: {
			multipart: false,
		},
	});
	const { mutateAsync: GenerateChalani } = useMutationHook({
		env: 'REACT_APP_DARTACHALANI_URL',
		queryRoute: '/chalani/',
	});

	const handleGenerateClick = async (val = { label: '' }, preview: any, isFromSaveAndPrint = true) => {
		try {
			setIsGenerateLoading(true);
			const body = {
				values: previewData?.values,
				form_values: { ...val },
				__save_and_print: true,
			};
			const response = await PatchGeneratePaper(body);
			setIssueId(response.issue_id);

			let generateChalaniObject = (({
				receiving_department,
				related_branch,
				remarks,
				sending_medium,
				address,
				paper_count,
			}: chalaniDataProps): chalaniDataProps => ({
				receiving_department,
				related_branch,
				remarks,
				sending_medium,
				address,
				paper_count,
			}))(val);

			generateChalaniObject.subject = val.label;
			generateChalaniObject.organization = user?.organization?.id;
			generateChalaniObject.date = todayNepaliDate;
			generateChalaniObject.paper_id = response.id;

			const res = await GenerateChalani(generateChalaniObject);
			setChalaniNum(res.data?.serial_number);
			setChalaniCreatedOrg(res.data?.chalani_created_fullname);

			setPaperCount(generateChalaniObject.paper_count ?? '');

			setIsGenerateLoading(false);
		} catch (error) {
			setIsGenerateLoading(false);
			dispatch(setErrorToastMessage('Something went wrong!'));
		}
	};

	const handleRefresh = () => {
		queryClient.invalidateQueries(['draft']);
	};

	return (
		<div ref={actionRef}>
			<TopBar
				pagination={{
					offset: offset,
					limit: LIMIT,
					total: total,
					onNext: handleNextPage,
					onPrev: handlePrevPage,
				}}
				isLoading={isFetching}
				showRefresh
				showBackButton
				handleRefresh={handleRefresh}
			/>

			{render}

			{previewData && (
				<SifarishPreviewModal
					show={show}
					preview={true}
					chalaniNum={chalaniNum}
					chalaniCreatedOrg={chalaniCreatedOrg}
					paperCount={paperCount}
					isLoading={isGenerateLoading}
					wardAdmin={false}
					innerRef={printRef}
					paper={previewData?.id}
					meta={previewData?.meta}
					paperExtra={previewData?.extra}
					issueId={issueId}
					config={previewData?.values?.config}
					previewFormData={previewData?.form_values}
					previewData={generatePreview(previewData?.form_values, previewData?.form_values, tableTitles, user)}
					isEnglish={previewData?.values?.category_title === 'English Format'}
					handleClose={handleClose}
					handlePrint={handlePrint}
					handleIssueId={handleIssueId}
					handleIsSaved={handleIsSaved}
					handleTemplateSave={handleTemplateSave}
					handleGenerateClick={handleGenerateClick}
					showCreateNew={false}
					showGenerate={!chalaniNum}
					showPrint={true}
					showSave={false}
					showUpdate={false}
					showSaveAndPrint={false}
					showUpdateAndPrint={false}
					showEdit={true}
				/>
			)}
		</div>
	);
}
