import TopBar from "../../components/app/topbar";
import PageTitle from "../../components/app/PageTItle";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";

//
import { FaAddressCard, FaEdit, FaPhone, FaTrash } from 'react-icons/fa';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useAppDispatch } from "../../hooks/redux";
import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";
import Loader from "../../components/Loader";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

/**
 * 
 */
export default function WardOffices() {

    const { data: wardOffices, isLoading } = useQueryHook({
        queryName: 'ward-offices',
        queryRoute: 'ward/',
    });

    console.log(wardOffices, 'wardOffices')

    return (
        <>
            <TopBar
                showBackButton />

            <div className="p-4">
                <div className="flex justify-between mb-3">
                    <PageTitle title="वडा कार्यालयहरु" />


                    <Link to="new" className="flex items-center rounded-xl bg-blue-500 text-center text-white hover:drop-shadow-xl hover:bg-blue-600 cursor-pointer hover:ease-in-out duration-500 px-3"
                    >
                        <AiFillPlusCircle fontSize={18} className="mr-2" />

                        नयाँ वडा कार्याल
                    </Link>
                </div>


                <div className="settings-content-container">
                    <div className="grid grid-cols-4 gap-4">
                        {wardOffices?.data?.results?.map((wardOffice: any) => <WardOfficeCard wardDetail={wardOffice} key={wardOffice.id} />)}
                    </div>
                </div>
            </div>
        </>
    )
}

const WardOfficeCard = ({ wardDetail }: { wardDetail: any }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const { mutate: deleteWardOffice, isLoading } = useMutationHook({
        queryRoute: `/ward/${wardDetail.id}/`,
        method: 'delete',
        options: {
            onSuccess: () => {
                dispatch(setSuccessToastMessage('वार्ड सफलतापूर्वक हटाइयो'));
                queryClient.invalidateQueries(['ward-offices']);
            },
            onError: () => {
                dispatch(setErrorToastMessage('Something went wrong!'))
            }
        }
    })
    const handleNavigateToWard = () => {
        navigate(`/settings/ward-office/${wardDetail.id}`)
    }

    const handleEditBtnClick = () => {
        navigate(`/settings/ward-offices/edit/${wardDetail.id}`);
    }

    const handleDeleteBtnClick = (selectedItemsId: any) => {
        Swal.fire({
            title: 'के तपाई यो वडा delete गर्न चाहनुहुन्छ?',
            // text: "You want to delete this Patrachar!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#60A5FA',
            cancelButtonColor: '#f87171',
            confirmButtonText: 'सुनिश्चित गर्नुहोस्',
            cancelButtonText: 'रद्द गर्नुहोस्',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteWardOffice({});
            }
        });
    };

    return (
        <div className="card shadow border relative flex flex-row justify-between items-center p-5" >

            <div className="flex flex-col justify-center items-center text-blue-500 hover:text-blue-700 cursor-pointer transition-color" onClick={handleNavigateToWard}>
                <FaAddressCard fontSize={48} />

                <div className="font-semibold">
                    {wardDetail.fullname_np || wardDetail.name_np}
                </div>
            </div>

            <div>
                <div className="flex items-center mb-2"><HiOutlineLocationMarker className="text-blue-400 mr-2" /> {wardDetail.address_np}</div>
                <div className="flex items-center mb-2"><FaPhone className="text-blue-400 mr-2" /> {wardDetail.phone_np}</div>
                <div className="flex">
                    <FaEdit onClick={handleEditBtnClick} className="text-blue-500 mr-2 cursor-pointer" />

                    <FaTrash onClick={handleDeleteBtnClick} className="text-red-500 cursor-pointer" />
                </div>
            </div>

            {
                isLoading &&
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-slate-200 bg-[#FFFFFF80] rounded">
                    <Loader isLoading fontSize={32} />
                </div>
            }
        </div>
    )
}