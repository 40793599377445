import { combineReducers } from "@reduxjs/toolkit";
import languageReducer from "./languages";
import toastReducer from "./toast";
import authReducer from "./auth";
import imagePreviewReducer from "./imagePreview";

const rootReducers = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  language: languageReducer,
  image: imagePreviewReducer,
});

export default rootReducers;
