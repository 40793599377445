import { VscLoading } from 'react-icons/vsc';

import './loader.css';

export default function Loader({ size = '32px', color = '#D24DA6' }) {
	return (
		<div className='loader-wrapper'>
			<VscLoading className='spin' size={size} color={color} />
		</div>
	);
}
