import cls from "classnames";
import { useNavigate } from "react-router-dom";

//
import {
  IoArchiveOutline,
  IoRefreshOutline,
  IoTrashOutline,
  IoMailOutline,
  IoMailUnreadOutline,
  IoChevronBack,
} from "react-icons/io5";
import { MdList, MdViewList } from "react-icons/md";
import { VscLoading } from "react-icons/vsc";

//
import Pagination from "../@topbar/pagination/Pagination";
//
import LanguageSelector from "../@Header/LanguageSelector/LanguageSelector";

/**
 * 
 */
function TopBar({
  isLoading,
  isListView,
  pagination,

  //
  showBackButton,
  showSelectAll,
  showRefresh,
  showDelete,
  showArchive,
  showMarkAsRead,
  showMarkAsUnRead,
  showViewOption,
  showLanguageSelector = true,

  //
  toggleListView,
  handleOnReadClick,
  handleOnUnreadClick,
  handleOnArchiveClick,
  handleRefresh,
}: ITopBarProps) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  }
  return (
    <div className={cls("flex justify-between items-center py-2 h-[53px]")}>
      {/* left elements */}
      <div className={cls("flex items-center space-x-4 pl-1")}>
        {
          showBackButton &&
          <span
            data-tip="go back"
            className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
            onClick={handleGoBack}
          >
            <IoChevronBack fontSize={18} />
          </span>
        }

        {showSelectAll &&
          <label
            htmlFor="select-all"
            className={cls(
              "p-2 label hover:bg-slate-100 flex tooltip tooltip-bottom hover:ease-in-out duration-500 hover:rounded-full"
            )}
            data-tip="Select"
          >
            <input
              type="checkbox"
              name="select-all"
              id="select-all"
              className="cursor-pointer checkbox h-4 w-4 rounded-sm"
            />
          </label>
        }
        {
          showRefresh &&
          <>
            {
              isLoading ?
                <span
                  data-tip="Refresh"
                  className={"cursor-pointer tooltip tooltip-bottom !ml-0 p-2 bg-green-50 rounded-full"}
                  onClick={handleRefresh}
                >
                  <VscLoading fontSize={18} className="animate-[spin_600ms_ease-in-out_infinite] text-green-800" />
                </span>
                :
                <span
                  data-tip="Refresh"
                  className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:ease-in-out duration-500 hover:rounded-full hover:bg-slate-100"
                  onClick={handleRefresh}
                >
                  <IoRefreshOutline fontSize={18} />
                </span>
            }


          </>

        }

        {showDelete &&
          <span
            data-tip="Delete"
            className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
          >
            <IoTrashOutline fontSize={18} />
          </span>
        }

        {showArchive &&
          <span
            data-tip="Archive"
            className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
            onClick={handleOnArchiveClick}
          >
            <IoArchiveOutline fontSize={18} />
          </span>
        }

        {showMarkAsRead &&
          <span
            data-tip="Mark as read"
            className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
            onClick={handleOnReadClick}
          >
            <IoMailOutline fontSize={18} />
          </span>
        }

        {showMarkAsUnRead &&
          <span
            data-tip="Mark as Unread"
            className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
            onClick={handleOnUnreadClick}
          >
            <IoMailUnreadOutline fontSize={18} />
          </span>
        }

      </div>
      {/* right elements */}
      <div className={cls("flex items-center space-x-5")}>
        {pagination
          &&
          <Pagination {...pagination} isLoading={isLoading} />
        }


        {showViewOption && <span
          data-tip="Compact"
          role="button"
          onClick={toggleListView}
          className="cursor-pointer tooltip tooltip-bottom !ml-0 p-2 hover:bg-slate-100 hover:ease-in-out duration-500 hover:rounded-full"
        >
          {isListView ? (
            <MdList fontSize={18} />
          ) : (
            <MdViewList fontSize={18} />
          )}
        </span>
        }

        {
          showLanguageSelector &&
          <div className="!mr-3 !ml-1">
            <LanguageSelector />
          </div>
        }

      </div>
    </div>
  )
}

export default TopBar;

type paginationType = {
  offset: number;
  total: number;
  limit: number;
  onNext: () => void;
  onPrev: () => void;
}
interface ITopBarProps {
  isLoading?: boolean;
  isListView?: boolean;
  pagination?: paginationType;

  showBackButton?: boolean;
  showSelectAll?: boolean;
  showRefresh?: boolean;
  showDelete?: boolean;
  showArchive?: boolean;
  showMarkAsRead?: boolean;
  showMarkAsUnRead?: boolean;
  showViewOption?: boolean;
  showLanguageSelector?: boolean;

  handleOnReadClick?: () => void;
  handleOnUnreadClick?: () => void;
  handleOnArchiveClick?: () => void;
  toggleListView?: () => void;
  handleRefresh?: () => void;
}