import NepaliDate from "nepali-date-converter";
import React, { useEffect, useState } from "react";
import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../reusables/@InputElements/InputField";

const Date = ({
  label,
  language,
  register,
  errors = {},
  elementId,
  value,
  setValue,
  properties = {
    year: {},
    month: {},
    day: {}
  },
  isPublic,
  entity,
}) => {
  const [y, setY] = useState('');
  const [m, setM] = useState('');
  const [d, setD] = useState('');
  useEffect(() => {
    if (elementId?.startsWith("date_today")) {
      let [y, m, d] = ["", "", ""];
      [y, m, d] = new NepaliDate().format("YYYY/MM/DD", "np").split("/");
      setValue && setValue(`${elementId}.year`, y);
      setY(y);
      setValue && setValue(`${elementId}.month`, m);
      setM(m);
      setValue && setValue(`${elementId}.day`, d);
      setD(d);
    }
  }, [elementId, setValue])

  if (isPublic && entity === "private") {
    return null;
  }

  const yearValidation = dynamicValidator({
    ...properties?.year,
    year: true,
  }, language);
  const monthValidation = dynamicValidator({
    ...properties?.month,
    month: true
  }, language);
  const dayValidation = dynamicValidator({
    ...properties?.day,
    day: true
  }, language);

  return (
    <div className="flex items-center py-2">
      <label className="font-semibold mr-2" htmlFor={elementId}>
        {label} :{" "}
      </label>

      <div className="relative mb-2">

        <div className="flex">
          <div className="input-calendar first ">
            <InputField
              type={"number"}
              language={language}
              register={register}
              setValue={setValue}
              elementId={`${elementId}.year`}
              value={value?.year ? value.year : y}
              placeholder="YYYY"
              maxLength="4"
              minLength="4"
              validation={yearValidation}
              className={` date1LOC year   ${properties?.year?.isRequired ? "textbox_req" : ""
                }  ${errors[elementId] && errors[elementId]["year"] ? "is-invalid" : ""
                }`}
              style={{
                width: "58px",
                marginRight: "0.4rem",
              }}
              disabled={properties.readOnly}
            />
          </div>
          <div className="input-calendar">
            <InputField
              type={"number"}
              language={language}
              register={register}
              setValue={setValue}
              elementId={`${elementId}.month`}
              value={value?.month ? value.month : m}
              placeholder="MM"
              validation={monthValidation}
              maxLength="2"
              minLength="2"
              className={` date1LOC month   ${properties?.month?.isRequired ? "textbox_req" : ""
                }  ${errors[elementId] && errors[elementId]["month"] ? "is-invalid" : ""
                }`}
              style={{
                width: "38px",
                marginRight: "0.4rem",
              }}
              disabled={properties.readOnly}
            />
          </div>
          <div className="input-calendar">
            <InputField
              type={"number"}
              language={language}
              register={register}
              setValue={setValue}
              elementId={`${elementId}.day`}
              value={value?.day ? value.day : d}
              placeholder="DD"
              maxLength="2"
              minLength="2"
              validation={dayValidation}
              className={` date1LOC day   ${properties?.day?.isRequired ? "textbox_req" : ""
                }  ${errors[elementId] && errors[elementId]["day"] ? "is-invalid" : ""
                }`}
              style={{
                width: "38px",
              }}
              disabled={properties.readOnly}
            />
          </div>
        </div>

        <div className="error-text">
          {errors[elementId] && <span>
            कृपया मान्य मिति राख्नुहोस्
          </span>}
        </div>
      </div>
    </div>
  );
};

export default Date;
