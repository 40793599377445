import { Fragment, useState, useRef, useEffect } from "react";
import { FaPrint } from "react-icons/fa";
import { AiOutlineFile, AiFillEdit } from "react-icons/ai";
import { FiSave } from "react-icons/fi";

// import QRCode from "qrcode.react";
import {
  generateHeaderPreview,
  nepaliNumberConverter,
  normalizeHtml,
} from "../../_helpers/template";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import ContentEditable from "./ContentEditable";
import Modal from "../reusables/Modal";
import Loader from "../app/Loader";
import { DefaultWardHeader } from "./DefaultWardLayout";
import { NagarpalikaFooter, NagarpalikaHeader } from "./NagarpalikaLayout";
import { BiBoltCircle } from "react-icons/bi";
import { useQueryClient } from "@tanstack/react-query";
import { nepaliNumber } from "../../_helpers/methods";

const SifarishPreviewModal = ({
  show,
  handleClose,
  paper,
  previewData,
  chalaniNum,
  chalaniCreatedOrg,
  paperCount,
  previewFormData,
  handlePrint,
  innerRef,
  handleTemplateSave,
  handleGenerateClick,
  config = {},
  preview,
  meta,
  isEnglish,
  paperExtra,
  handleIsSaved,
  issueId,
  handleIssueId,
  wardAdmin,
  showPrint,
  showEdit,
  showUpdateAndPrint,
  showGenerate,
  showSave,
  showUpdate,
  showSaveAndPrint,
  showCreateNew,
  isLoading,
}) => {
  const EnvInHeader = process.env.REACT_APP_EN_IN_HEADER === "true";
  const user = useAppSelector((state) => state?.auth?.user);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const location = useLocation();
  const fiscal = {};
  const navigate = useNavigate();
  const [previewOuterHtml, setPreviewOuterHtml] = useState(
    normalizeHtml(previewData)
  );

  useEffect(() => {
    const normalizedHtml = normalizeHtml(previewData);
    if (normalizeHtml) {
      setPreviewOuterHtml(normalizedHtml);
    }
  }, [previewData]);

  const [printSelect, setPrintSelect] = useState("preview");
  const [headerProps, setHeaderProps] = useState({});

  let wardAdminRole = "";
  let wardAdminName = "";

  const queryClient = useQueryClient();
  const defaultTemplate = queryClient.getQueryData(["defaultTemplate"]);

  // let ward = nagarpalika?.ward?.filter(
  //   (ward) => ward?.id === user?.profile?.ward
  // );
  const inputRef = useRef();

  const [isSaveAndPrintLoading, setIsSaveAndPrintLoading] = useState(false);

  useEffect(() => {
    setHeaderProps({
      organization_address_np: user?.organization?.address_np,
      organization_address_en: user?.organization?.address_en,
      isEnglish: isEnglish,
      date_today_en: previewFormData?.date_today_en,
      date_today: previewFormData?.date_today,
      chalaniNum: isEnglish ? chalaniNum : nepaliNumber(chalaniNum),
      chalaniCreatedOrg: chalaniCreatedOrg,
      organization_code: user?.organization?.code,
      paper_count: nepaliNumberConverter(paperCount),
      issueId: EnvInHeader ? issueId : null,
    });
  }, [
    chalaniCreatedOrg,
    chalaniNum,
    isEnglish,
    paperCount,
    previewFormData?.date_today,
    previewFormData?.date_today_en,
    user?.organization?.address_en,
    user?.organization?.address_np,
    user?.organization?.code,
    issueId,
    EnvInHeader,
  ]);

  const handleSave = async () => {
    if (!handleTemplateSave) return;
    await handleTemplateSave(previewFormData, previewOuterHtml, false);
  };
  const onGenerateClick = () => {
    handleGenerateClick &&
      handleGenerateClick(previewFormData, previewOuterHtml);
  };

  const handleSaveAndPrint = async () => {
    try {
      setIsSaveAndPrintLoading(true);
      if (!handleTemplateSave) return setIsSaveAndPrintLoading(false);

      await handleTemplateSave(previewFormData, previewOuterHtml, true);
      setIsSaveAndPrintLoading(false);
    } catch (err) {
      console.log(err, "err ");
      setIsSaveAndPrintLoading(false);
    }
  };

  if (paperExtra) {
    if (paperExtra?.paper_category === "English Format") {
      wardAdminName =
        paperExtra.ward_kawa_fullname_en || paperExtra.ward_admin_fullname_en;
      wardAdminRole = paperExtra.ward_kawa_fullname_en
        ? "Act. Chairman"
        : "Ward Admin";
    } else {
      wardAdminName =
        paperExtra.ward_kawa_fullname_np || paperExtra.ward_admin_fullname_np;
      wardAdminRole = paperExtra.ward_kawa_fullname_np
        ? "वडा का.वा"
        : "वडा अध्यक्ष";
    }
  } else if (wardAdmin) {
    let [AdminName, AdminRole] = wardAdmin.split("_");
    wardAdminRole = AdminRole;
    wardAdminName = AdminName;
  }

  const onHandlePrintClick = async () => {
    if (!issueId) {
      try {
        console.log(paper, "paper");
      } catch (err) {
        console.log(err);
      }
    }
    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  return (
    <Modal
      className={`w-screen max-w-screen-2xl h-[90vh]`}
      show={show}
      handleHide={handleClose}
      showClose
    >
      <div className="flex relative h-[720px]">
        <div
          defaultActiveKey="preview"
          className={`page-div border-0 flex-column p-0 flex justify-center gap-4 flex-1 overflow-auto bg-slate-50 rounded-l-xl`}
        >
          {previewData ? (
            <div
              className="page"
              ref={printSelect === "preview" ? innerRef : undefined}
            >
              {config.hasHeader && (
                <Fragment>
                  {["sakha", "nagarpalika"].includes(
                    user?.organization?.type
                  ) ? (
                    <NagarpalikaHeader
                      user={user}
                      template={{
                        data: {
                          header:
                            defaultTemplate?.data?.additional_info
                              ?.nagarpalika_header,
                        },
                      }}
                      headerProps={headerProps}
                    />
                  ) : (
                    <>
                      {defaultTemplate?.data?.header ? (
                        <div
                          key={chalaniNum}
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: generateHeaderPreview(
                              defaultTemplate,
                              headerProps,
                              user
                            ),
                          }}
                        ></div>
                      ) : (
                        user && (
                          <DefaultWardHeader
                            user={user}
                            isEnglish={isEnglish}
                            fiscal={fiscal}
                            previewFormData={previewFormData}
                            chalaniNum={chalaniNum}
                            paperCount={paperCount}
                          />
                        )
                      )}
                    </>
                  )}
                </Fragment>
              )}

              {!EnvInHeader && issueId && (
                <div className="absolute top-[20cm] left-[-70px] rotate-90 z-5 text-[0.9rem]">
                  {issueId}
                </div>
              )}

              <table className="w-full content">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <ContentEditable
                          id="preview"
                          autoComplete="off"
                          autoCorrect="off"
                          autocapiatlise="off"
                          spellCheck="false"
                          innerRef={inputRef}
                          html={
                            previewOuterHtml ? previewOuterHtml : previewData
                          }
                          disabled={preview ? true : false}
                          onChange={(e) => {
                            let tempValue = e.target.value;
                            setPreviewOuterHtml(tempValue);
                          }}
                          className="py-4 px-6"
                          isEnglish={isEnglish}
                          tagName="div"
                        />
                      </div>

                      {config.hasHeader && (
                        <div className="col-12 d-flex justify-content-end">
                          <div className="text-right">
                            <div className="w-full border-bottom border-dark border-dotted mb-2"></div>
                            <div>{wardAdminName}</div>
                            <div className="text-red">{wardAdminRole}</div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>

                {config.hasFooter && (
                  <tfoot>
                    <tr>
                      <td>
                        <div className="footer-space">&nbsp;</div>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
              {config.hasFooter && (
                <>
                  {["sakha", "nagarpalika"].includes(
                    user?.organization?.type
                  ) ? (
                    <div className="paper-footer-container">
                      <NagarpalikaFooter defaultTemplate={defaultTemplate} />
                    </div>
                  ) : (
                    <>
                      <div>
                        {defaultTemplate?.data?.footer ? (
                          <div className="paper-footer-container">
                            <div className="footer">
                              <div
                                className="w-full"
                                dangerouslySetInnerHTML={{
                                  __html: defaultTemplate?.data?.footer,
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : (
                          user &&
                          user.profile && (
                            <div className="paper-footer-container">
                              <div className="footer">
                                <div className="page-footer flex-wrap pb-2">
                                  <hr className="border-top border-dark w-full d-block mx-4 mb-2" />
                                  {}
                                  <p>
                                    {isEnglish ? "Email: " : "इमेल: "}
                                    {meta?.ward_email
                                      ? meta?.ward_email
                                      : user?.profile?.ward_info?.email}
                                  </p>
                                  <p>
                                    {isEnglish
                                      ? `ward secretary: `
                                      : `वडा सचिब: `}
                                    {isEnglish
                                      ? meta?.ward_user[0]?.phone_en
                                        ? meta?.ward_user[0]?.phone_en
                                        : user?.profile?.ward_info
                                            ?.ward_user_phone_en
                                      : meta?.ward_user[0]?.phone_np
                                      ? meta?.ward_user[0]?.phone_np
                                      : user?.profile?.ward_info
                                          ?.ward_user_phone_np}
                                  </p>
                                  <p>
                                    {isEnglish
                                      ? `Ward Admin: `
                                      : `वडा अध्यक्ष: `}
                                    {isEnglish
                                      ? meta?.ward_admins[0]?.phone_en
                                        ? meta?.ward_admins[0]?.phone_en
                                        : user?.profile?.ward_info
                                            ?.ward_admin_phone_en
                                      : meta?.ward_admins[0]?.phone_np
                                      ? meta?.ward_admins[0]?.phone_np
                                      : user?.profile?.ward_info
                                          ?.ward_admin_phone_np}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            paper
          )}
        </div>

        <div className="min-w-[300px] bg-slate-100 h-full rounded-r-xl flex justify-start flex-col items-stretch gap-4 p-5">
          {showPrint && (
            <button
              className="btn btn-success px-3 mx-2 text-white"
              type="button"
              onClick={() => {
                if (chalaniNum) {
                  onHandlePrintClick();
                } else {
                  setShowConfirmation("print");
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FaPrint className="mr-3" fontSize={20} />

                  <span className="w-28 text-left">प्रिन्ट गर्नुहोस्</span>
                </>
              )}
            </button>
          )}
          {showEdit && (
            <button
              className="btn bg-white border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white hover:border-white  px-3 mx-2"
              type="button"
              onClick={() => {
                if (location.pathname === "/draft") {
                  navigate(`/preview/${paper}`);
                } else {
                  handleClose();
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <AiFillEdit className="mr-3" fontSize={20} />

                  <span className="w-28 text-left">सम्पादन गर्नुहोस्</span>
                </>
              )}
            </button>
          )}
          {showUpdateAndPrint && (
            <button
              className="btn btn-success px-3 mx-2 text-white"
              type="button"
              onClick={() => {
                if (chalaniNum) {
                  handleSaveAndPrint();
                } else {
                  setShowConfirmation("save");
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FaPrint className="mr-3" fontSize={20} />
                  <span className="w-28 text-left">
                    अपडेट र प्रिन्ट गर्नुहोस्
                  </span>
                </>
              )}
            </button>
          )}
          {showSave && (
            <button
              className="btn bg-green-600 border-green-600 text-white hover:bg-green-400 hover:border-green-400 px-3 mx-2"
              type="button"
              onClick={() => {
                handleSave();
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FiSave className="mr-3" fontSize={20} />

                  <span className="w-28 text-left">सुरक्षित गर्नुहोस्</span>
                </>
              )}
            </button>
          )}
          {showGenerate && (
            <button
              className="
							btn bg-white border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white hover:border-white mx-2"
              type="button"
              onClick={onGenerateClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <BiBoltCircle fontSize={20} className="mr-3" />

                  <span className="w-28 text-left">चलानी बनाउनुहोस्</span>
                </>
              )}
            </button>
          )}
          {showUpdate && (
            <button
              className="btn bg-white border-sky-600 text-sky-600 hover:bg-sky-600 hover:border-sky-600 hover:text-white px-3 mx-2"
              type="button"
              onClick={handleSave}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FiSave className="mr-3" fontSize={20} />

                  <span className="w-28 text-left">अपडेट गर्नुहोस्</span>
                </>
              )}
            </button>
          )}

          {showSaveAndPrint && (
            <button
              type="button"
              className="btn btn-success px-3 mx-2 text-white"
              onClick={() => {
                if (chalaniNum) {
                  handleSaveAndPrint();
                } else setShowConfirmation("save");
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FaPrint className="mr-3" fontSize={20} />

                  <span className="w-28 text-left">प्रिन्ट गर्नुहोस्</span>
                </>
              )}
            </button>
          )}
          {showCreateNew && (
            <button
              className="btn btn-accent px-3 mx-2"
              type="button"
              onClick={() => {
                navigate("/templates/1");
              }}
              disabled={isSaveAndPrintLoading}
            >
              <AiOutlineFile className="mr-3" fontSize={20} />

              <span className="w-28 text-left">नयाँ सिर्जना गर्नुहोस्</span>
            </button>
          )}
        </div>
      </div>
      <Modal
        show={showConfirmation}
        handleHide={() => setShowConfirmation(false)}
      >
        <h2 className="text-xl font-bold">
          के तपाई चलानी नं. बिना प्रिन्ट गर्न चाहनुहुन्छ?
        </h2>
        <div className="flex justify-end space-x-4 mt-8">
          <button
            className="btn btn-error"
            type="button"
            onClick={() => setShowConfirmation(false)}
          >
            <span className="text-left">रद्द गर्नुहोस्</span>
          </button>

          <button
            className="btn btn-success text-white"
            type="button"
            onClick={() => {
              showConfirmation === "save"
                ? handleSaveAndPrint()
                : handlePrint();
              setShowConfirmation(false);
            }}
          >
            <span className="text-left">प्रिन्ट गर्नुहोस्</span>
          </button>
        </div>
      </Modal>
    </Modal>
  );
};

export default SifarishPreviewModal;
