import NepaliDate from "nepali-date-converter";
import { nepaliNumbers } from "../_datas";
import { NEPAL_SAMBAT } from "../_datas/nepal_sambat";
import { nepaliNumber } from "./methods";

export const generatePreview = (paper, formData, titles = {}, user) => {
  let nagarpalikaName_en = user?.nagarpalika_info?.nagarpalika_name_en;
  let nagarpalikaName_np = user?.nagarpalika_info?.nagarpalika_name_np;
  let nagarpalikaAddress_en = user?.nagarpalika_info?.nagarpalika_address_en;
  let nagarpalikaAddress_np = user?.nagarpalika_info?.nagarpalika_address_np;
  let org_name_en = user?.organization?.name_en;
  let org_name_np = user?.organization?.name_np;

  let tempPreviewData = paper.preview || paper.previewData;
  let metaData = paper.meta ?? user?.nagarpalika_info;

  let curlyBraceGraberRegex = /\{\{(.*?)\}\}/g; // {{text1}}
  tempPreviewData = tempPreviewData.replace(curlyBraceGraberRegex, (value) => {
    let key = value.replace(/[^0-9a-zA-Z_-]+/g, ""); // text1

    if (key.startsWith("fullname")) {
      return generateUneditable(
        formData[key] &&
          `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`,
        key
      );
    } else if (key.includes("_logo")) {
      return formData[key];
    } else if (key.startsWith("date")) {
      if (typeof formData[key] === "object") {
        let joinedDate = `${formData[key]?.year} | ${formData[key]?.month} | ${formData[key]?.day}`;
        try {
          if (key.includes("date_today")) {
            let englishified = englishNumberConverter(joinedDate);
            let hasNepalSambat = false;
            let isEnglish = joinedDate[key]?.year <= new Date().getFullYear();
            if (englishified.length > 8) {
              hasNepalSambat = true;
              let [yyyy, mm, dd] = englishified.split(" | ");
              let joinedDateAD;
              if (!isEnglish) {
                joinedDateAD = new NepaliDate(+yyyy, +(mm - 1), +dd).getAD();
                joinedDateAD = `${joinedDateAD.year}-${(
                  "0" +
                  (joinedDateAD.month + 1)
                ).slice(-2)}-${("0" + joinedDateAD.date).slice(-2)}`;
              }
              let nepalSambatDate = NEPAL_SAMBAT[joinedDateAD];
              if (nepalSambatDate) {
                joinedDate = `<span style='font-weight: 500; font-size:1.1rem'>${joinedDate}`;
                joinedDate += `<span><br/> ने.सं. :- ${nepalSambatDate}</span></span>`;
              }
            }
            if (hasNepalSambat) {
              return `<span style='font-family:"ui-sans-serif"'>${joinedDate}</span>`;
            }
          }
        } catch (error) {
          console.log(error, "error nepal sambat");
        }
        return generateUneditable(joinedDate, key);
      } else {
        return generateUneditable(formData[key], key);
      }
    } else if (key.startsWith("dob")) {
      if (typeof formData[key] === "object") {
        let joinedDate = `${formData[key]?.year}/${formData[key]?.month}/${formData[key]?.day}`;
        return generateUneditable(joinedDate, key);
      } else {
        return generateUneditable(formData[key], key);
      }
    } else if (key === "nagarpalika_name_np") {
      return generateUneditable(nagarpalikaName_np, key);
    } else if (key === "nagarpalika_name_en") {
      return generateUneditable(nagarpalikaName_en, key);
    } else if (key === "nagarpalika_address_np") {
      return generateUneditable(nagarpalikaAddress_np, key);
    } else if (key === "nagarpalika_address_en") {
      return generateUneditable(nagarpalikaAddress_en, key);
    } else if (key === "ward_name_np") {
      return generateUneditable(org_name_np, key);
    } else if (key === "ward_name_en") {
      return generateUneditable(org_name_en, key);
    } else if (metaData && metaData[key]) {
      return generateUneditable(metaData[key], key);
    }

    // is handled in the template itself
    // else if (key.startsWith('post')) {
    // 	return '';
    // } else if (key.startsWith('admin_name')) {
    // 	return '';
    // }
    else if (key.startsWith("post")) {
      let post;
      try {
        // ward_admin => admin-name_admin-post ,, ram_adhakchya
        post = formData["ward_admin"]?.split("_")[1];
        if (post) {
          return generateUneditable(post, key);
        } else {
          post = "";
        }
      } catch (err) {
        post = "";
      }
      return generateUneditable(post, key);
    } else if (key.startsWith("admin_name")) {
      let admin_name;
      try {
        admin_name = formData["ward_admin"]?.split("_")[0];
        if (admin_name) {
          return admin_name;
        } else {
          admin_name = "";
        }
      } catch (err) {
        admin_name = "";
      }
      return generateUneditable(admin_name, key);
    } else if (key === "relation") {
      let sex = formData["select2"];
      if (sex === "male") {
        return generateUneditable("छोरा", key);
      } else if (sex === "female") {
        return generateUneditable("छोरी", key);
      } else {
        return generateUneditable("पुत्र", key);
      }
    } else if (key === "respect") {
      let sex = formData["select4"];
      if (sex === "male") {
        return generateUneditable("श्रीमान", key);
      } else if (sex === "female") {
        return generateUneditable("श्री", key);
      } else {
        return generateUneditable("श्री", key);
      }
    } else if (key === "age") {
      // 1997-01-22
      // let age = moment().diff(Object.values(formData['date_of_birth_AD']).join('-'), 'years');
      // return generateUneditable(nepaliNumber(age), key);
      return "-";
    } else if (key === "reference") {
      let references = formData["references"];

      if (!references?.length) return "";

      let referenceContainerHtml =
        '<div><span class="text-semibold">बोधार्थ</span><div class="flex flex-col">';
      references.forEach((reference, index) => {
        referenceContainerHtml += `<span class="mb-1 block">${nepaliNumber(
          index + 1
        )}. ${reference}</span>`;
      });
      referenceContainerHtml += "</div></div>";
      return generateUneditable(referenceContainerHtml, key);
    } else if (key.startsWith("table")) {
      let tableContainerHtml = '<div class="content-container">';
      let tableData = "";
      let tableKeys = Object.keys(formData).filter((key) =>
        key.startsWith("table")
      );
      tableKeys.forEach((tableKey) => {
        let id;
        try {
          const [k, _id] = tableKey.split("-");
          if (_id) {
            id = _id;
            console.log(k);
          }
        } catch (e) {}
        if (id) {
          if (titles[id]) {
            tableData += `<strong class='text-slate-800 underline w-full text-center block'>${titles[id]}</strong>`;
          }
        }
        if (formData[tableKey]?.length > 0) {
          tableData +=
            '<table class="table border border-slate-400 border-collapse table-fixed text-slate-700 w-full" id="table_resize" cellpadding="0" cellspacing="0"><tr>';
          const headings = Object.keys(formData[tableKey][0]);
          // const costumeKey = "table";
          // "table__r__1,2"
          const costumeKey = tableKey;

          // eslint-disable-next-line no-unused-vars
          const [_, relationFields] = costumeKey.split("__r__");
          let name, relation;

          if (relationFields) {
            [name, relation] = relationFields.split("_");
          }
          const sortedHeadings = headings.sort();
          const headingData = sortedHeadings
            .map((heading, headingIndex) =>
              headingIndex === 0
                ? `<th style="width:60px;white-space:break-spaces;" class="bg-slate-200 border border-slate-100">${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")}</th>`
                : `<th class="bg-slate-200 border border-slate-100" style="white-space:break-spaces;">${heading
                    .split("___")[1]
                    .split("####")
                    .join(".")
                    .split("&&&&")
                    .join("'")}</th>`
            )
            .join("");
          tableData += headingData;
          const rows = formData[tableKey].map(
            (rowData) =>
              `<tr>${headings
                .map((heading, headingIndex) =>
                  headingIndex === 0
                    ? `<td style="max-width:25px;width:25px;white-space:break-spaces;" class="border">${rowData[heading]}</td>`
                    : `<td class="border" style="white-space:break-spaces;">${rowData[heading]}</td>`
                )
                .join("")}</tr>`
          );
          if (relationFields) {
            tableData += `</tr>${rows.join("")}</table></div>
                    <div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0">${generateNameRelationBox(
                      formData[tableKey],
                      {
                        name: sortedHeadings[name],
                        relation: sortedHeadings[relation],
                      }
                    )}</table> <br/>`;
          } else {
            tableData += `</tr>${rows.join("")}</table> <br/>`;
          }
        } else {
          return null;
        }
      });
      tableContainerHtml += tableData + "</div>";

      return generateUneditable(tableContainerHtml, key);
    } else {
      return generateUneditable(formData[key], key);
    }
  });

  return tempPreviewData;
};

const generateUneditable = (data, key = "") =>
  `<span class="nonEditable" id="${key}">${data ?? ""}</span>`;

const generateSpan = (data, key = "", className) =>
  `<span id="${key}" className="${className}">${data ?? ""}</span>`;

const generateNameRelationBox = (data, config) => {
  const noOfRow = Math.ceil(data.length / 5);
  const html = [];
  for (let index = 0; index < noOfRow; index++) {
    html.push(
      `<tr>${data
        .map(
          (data, idx) =>
            index === Math.floor(idx / 5) &&
            `<td><div class="text-container"><div class="photobox"></div><div>${
              data[config.name]
            }</div><p>(${data[config.relation]})</p></div></td>`
        )
        .filter((data) => data)
        .join("")}</tr>`
    );
  }
  return html.join("");
};
export const generateEditedPreview = (paper, formData, titles = {}, user) => {
  let nagarpalikaName_en = user?.nagarpalika_info?.nagarpalika_name_en;
  let nagarpalikaName_np = user?.nagarpalika_info?.nagarpalika_name_np;
  let nagarpalikaAddress_en = user?.nagarpalika_info?.nagarpalika_address_en;
  let nagarpalikaAddress_np = user?.nagarpalika_info?.nagarpalika_address_np;
  let ward_name_en = user?.organization?.name_en;
  let ward_name_np = user?.organization?.name_np;

  let metaData = paper.meta ?? user?.nagarpalika_info;
  const keys = Object.keys(formData);
  const tempHtml = new DOMParser().parseFromString(
    paper.previewData,
    "text/html"
  );
  keys.forEach((key) => {
    const keyDom = tempHtml.getElementById(key);
    if (keyDom) {
      if (key.startsWith("fullname")) {
        keyDom.innerHTML = `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`;
      } else if (key.includes("_logo")) {
        return formData[key];
      } else if (key.startsWith("date")) {
        if (typeof formData[key] === "object") {
          if (keyDom) {
            // keyDom.innerHTML = Object.values(formData[key]).join('/ ');
            let joinedDate = `${formData[key]?.year} | ${formData[key]?.month} | ${formData[key]?.day}`;
            try {
              if (key.includes("date_today")) {
                let englishified = englishNumberConverter(joinedDate);
                let hasNepalSambat = false;
                let isEnglish =
                  joinedDate[key]?.year <= new Date().getFullYear();
                if (englishified.length > 8) {
                  hasNepalSambat = true;
                  let [yyyy, mm, dd] = englishified.split(" | ");

                  let joinedDateAD;
                  if (!isEnglish) {
                    joinedDateAD = new NepaliDate(
                      +yyyy,
                      +(mm - 1),
                      +dd
                    ).getAD();
                    joinedDateAD = `${joinedDateAD.year}-${(
                      "0" +
                      (joinedDateAD.month + 1)
                    ).slice(-2)}-${("0" + joinedDateAD.date).slice(-2)}`;
                  }
                  let nepalSambatDate = NEPAL_SAMBAT[joinedDateAD];
                  if (nepalSambatDate) {
                    joinedDate = `<span style='font-weight: 500; font-size:1.1rem'>${joinedDate}`;
                    joinedDate += `<span ><br/> ने.सं. :- ${nepalSambatDate}</span></span>`;
                  }
                }
                if (hasNepalSambat) {
                  joinedDate = `<span style='font-family:"ui-sans-serif"'>${joinedDate}</span>`;
                }
              }
            } catch (error) {
              console.log(error, "date");
            }

            keyDom.innerHTML = joinedDate;
          }
        } else {
          keyDom.innerHTML = formData[key];
        }
      } else if (key.startsWith("dob")) {
        if (keyDom) {
          // keyDom.innerHTML = Object.values(formData[key]).join('/ ');
          keyDom.innerHTML = `${formData[key]?.year}/${formData[key]?.month}/${formData[key]?.day}`;
        } else {
          keyDom.innerHTML = formData[key];
        }
      } else if (key === "nagarpalika_name_np") {
        keyDom.innerHTML = nagarpalikaName_np;
      } else if (key === "nagarpalika_name_en") {
        keyDom.innerHTML = nagarpalikaName_en;
      } else if (key === "nagarpalika_address_np") {
        keyDom.innerHTML = nagarpalikaAddress_np;
      } else if (key === "nagarpalika_address_en") {
        keyDom.innerHTML = nagarpalikaAddress_en;
      } else if (key === "ward_name_np") {
        keyDom.innerHTML = ward_name_np;
      } else if (key === "ward_name_en") {
        keyDom.innerHTML = ward_name_en;
      } else if (metaData && metaData[key]) {
        keyDom.innerHTML = metaData[key];
      }

      // is handled in the template itself
      // else if (key.startsWith('post')) {
      // 	return '';
      // } else if (key.startsWith('admin_name')) {
      // 	return '';
      // }
      else if (key.startsWith("post")) {
        let post;
        try {
          // ward_admin => admin-name_admin-post ,, ram_adhakchya
          post = formData["ward_admin"]?.split("_")[1];
          if (post) {
            keyDom.innerHTML = post;
            return;
          } else {
            post = "";
          }
        } catch (err) {
          post = "";
        }
        keyDom.innerHTML = post;
      } else if (key.startsWith("admin_name")) {
        let admin_name;
        try {
          admin_name = formData["ward_admin"]?.split("_")[0];
          if (admin_name) {
            keyDom.innerHTML = admin_name;

            return;
          } else {
            admin_name = "";
          }
        } catch (err) {
          admin_name = "";
        }
        keyDom.innerHTML = admin_name;
      } else if (key === "relation") {
        let sex = formData["select2"];
        if (sex === "male") {
          keyDom.innerHTML = "छोरा";
        } else if (sex === "female") {
          keyDom.innerHTML = "छोरी";
        } else {
          keyDom.innerHTML = "पुत्र";
        }
      } else if (key === "respect") {
        let sex = formData["select4"];
        if (sex === "male") {
          keyDom.innerHTML = "श्रीमान";
        } else if (sex === "female") {
          keyDom.innerHTML = "श्री";
        } else {
          keyDom.innerHTML = "श्री";
        }
      } else if (key === "age") {
        // 1997-01-22
        // let age = moment().diff(Object.values(formData['date_of_birth_AD']).join('-'), 'years');
        // keyDom.innerHTML = nepaliNumber(age);
        return "-";
      } else if (key === "reference") {
        let references = formData["references"];

        if (!references.length) return "";

        let referenceContainerHtml =
          '<div><span class="text-semibold">बोधार्थ</span><div class="flex flex-col">';
        references.forEach((reference, index) => {
          referenceContainerHtml += `<span class="mb-1 block">${nepaliNumber(
            index + 1
          )}. ${reference}</span>`;
        });
        referenceContainerHtml += "</div></div>";
        return generateUneditable(referenceContainerHtml, key);
      } else if (key.startsWith("table")) {
        let tableContainerHtml = '<div class="content-container">';
        let tableData = "";
        let tableKeys = Object.keys(formData).filter((key) =>
          key.startsWith("table")
        );
        tableKeys.forEach((tableKey) => {
          let id;
          try {
            const [k, _id] = tableKey.split("-");
            if (_id) {
              id = _id;
              console.log(k);
            }
          } catch (e) {}
          if (id) {
            if (titles[id]) {
              tableContainerHtml += `<strong class='text-slate-800 underline w-full text-center block'>${titles[id]}</strong>`;
            }
          }
          if (formData[tableKey]?.length > 0) {
            tableData +=
              '<table class="table border border-slate-400 border-collapse table-fixed text-slate-700 w-full" border="0" id="table_resize" cellpadding = "0" cellspacing="0"><tr>';
            const headings = Object.keys(formData[tableKey][0]);
            // const costumeKey = "table";
            // "table__r__1,2"
            const costumeKey = tableKey;

            // eslint-disable-next-line no-unused-vars
            const [_, relationFields] = costumeKey.split("__r__");
            let name, relation;

            if (relationFields) {
              [name, relation] = relationFields.split("_");
            }
            const sortedHeadings = headings.sort();
            const headingData = sortedHeadings
              .map((heading, headingIndex) =>
                headingIndex === 0
                  ? `<th style="width:60px;white-space:break-spaces;" class="bg-slate-200 border border-slate-100">${heading
                      .split("___")[1]
                      .split("####")
                      .join(".")
                      .split("&&&&")
                      .join("'")}</th>`
                  : `<th class="bg-slate-200 border border-slate-100" style="white-space:break-spaces;">${heading
                      .split("___")[1]
                      .split("####")
                      .join(".")
                      .split("&&&&")
                      .join("'")}</th>`
              )
              .join("");
            tableData += headingData;
            const rows = formData[tableKey].map(
              (rowData) =>
                `<tr>${headings
                  .map((heading, headingIndex) =>
                    headingIndex === 0
                      ? `<td style="max-width:25px;width:25px;white-space:break-spaces;" class="border">${rowData[heading]}</td>`
                      : `<td class="border" style="white-space:break-spaces;">${rowData[heading]}</td>`
                  )
                  .join("")}</tr>`
            );
            if (relationFields) {
              tableData += `</tr>${rows.join("")}</table></div>
            <div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0">${generateNameRelationBox(
              formData[tableKey],
              {
                name: sortedHeadings[name],
                relation: sortedHeadings[relation],
              }
            )}</table> <br/>`;
            } else {
              tableData += `</tr>${rows.join("")}</table> <br/>`;
            }
          } else {
            return null;
          }
        });
        tableContainerHtml += tableData + "</div>";
        keyDom.innerHTML = tableContainerHtml;
      } else {
        keyDom.innerHTML = formData[key];
      }
    }
  });

  return tempHtml.body.innerHTML;
};

export const insertSubStringAtString = (index, string, subString) => {
  let result = string.split("");
  result.splice(index, 0, subString);
  return result.join("");
};

export function nepaliNumberConverter(numString, onlyNumber) {
  if (!numString) return numString;
  let nepaliNumber = "";
  for (let i = 0; i < numString?.length; i++) {
    if (nepaliNumbers.includes(numString[i])) {
      nepaliNumber += numString[i];
    } else if (nepaliNumbers[numString[i]]) {
      nepaliNumber += nepaliNumbers[numString[i]];
    } else {
      if (!onlyNumber) {
        nepaliNumber += numString[i];
      }
    }
  }
  return nepaliNumber;
}

export function englishNumberConverter(numString) {
  let englishNumber = "";
  for (let i = 0; i < numString.length; i++) {
    let num = numString[i];
    let englishNum = nepaliNumbers.indexOf(num);
    if (englishNum < 0) {
      englishNumber += num;
    } else {
      englishNumber += englishNum;
    }
  }
  return englishNumber;
}

export function normalizeHtml(str) {
  return (
    str &&
    str
      .replace(/&nbsp;|\u202F|\u00A0/g, " ")
      .replace(/&lt;/g, "ÿ") // < and <</p> causes a problem because 1st character is equal
      //and it always takes second one so this little hack prevents using  < at any cost
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
  );
}
export function getCurrentCursorPosition() {
  const element = document.getElementById("preview");
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== "Control") {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export function getDifference(a, b) {
  var i = 0;
  var j = 0;
  var result = "";
  let position;
  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) {
      result = b[j];
      position = j;
      break;
    } else i++;
    j++;
  }
  return { result, position };
}

export function setCurrentCursorPosition(
  chars,
  preview = "preview",
  insertCharacter
) {
  if (chars >= 0) {
    const selection = window.getSelection();

    let range = createRange(document.getElementById(preview).parentNode, {
      count: chars,
    });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

export const generateHeaderPreview = (layout, values, user) => {
  let metaData = user?.nagarpalika_info;
  let nagarpalikaName_en = metaData?.nagarpalika_name_en;
  let nagarpalikaName_np = metaData?.nagarpalika_name_np;
  let nagarpalikaAddress_en = metaData?.nagarpalika_address_en;
  let nagarpalikaAddress_np = metaData?.nagarpalika_address_np;

  let organizationData = user?.organization;
  let org_name_en = organizationData?.fullname_en || organizationData?.name_en;
  let org_name_np = organizationData?.fullname_np || organizationData?.name_np;

  const layoutData = layout?.data;

  if (!layoutData) return null;

  let headerPreview = layoutData.header;

  let curlyBraceGraberRegex = /\{\{(.*?)\}\}/g; // {{text1}}
  headerPreview = headerPreview.replace(curlyBraceGraberRegex, (value) => {
    let key = value.replace(/[^0-9a-zA-Z_-]+/g, ""); // text1
    if (key.includes("_logo")) {
      return values[key];
    } else if (key.startsWith("date")) {
      let date = "";
      if (values.isEnglish) {
        if (!values.date_today_en) {
          return "";
        }
        date = `${values?.date_today_en?.year} | ${values?.date_today_en?.month} | ${values?.date_today_en?.day}`;
      } else {
        if (!values.date_today) {
          return "";
        }
        date = `${values?.date_today?.year} | ${values?.date_today?.month} | ${values?.date_today?.day}`;
      }

      if (key.includes("date_today")) {
        let englishified = englishNumberConverter(date);
        let hasNepalSambat = false;
        if (englishified.length > 8) {
          try {
            hasNepalSambat = true;
            let [yyyy, mm, dd] = englishified.split(" | ");
            let dateAD;

            if (!values.isEnglish) {
              dateAD = new NepaliDate(+yyyy, +(mm - 1), +dd).getAD();
              dateAD = `${dateAD.year}-${("0" + (dateAD.month + 1)).slice(
                -2
              )}-${("0" + dateAD.date).slice(-2)}`;
            }
            let nepalSambatDate = NEPAL_SAMBAT[dateAD];
            if (nepalSambatDate) {
              date = `<span style='font-weight: 500; font-size:1.1rem'>${date}`;
              date += `<span><br/> ने.सं. :- ${nepalSambatDate}</span></span>`;
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (hasNepalSambat) {
          return `<span style='font-family:"ui-sans-serif"'>${date}</span>`;
        }
      }

      return generateSpan(date, key);
    } else if (key === "nagarpalika_name_np") {
      return generateSpan(nagarpalikaName_np, key);
    } else if (key === "nagarpalika_name_en") {
      return generateSpan(nagarpalikaName_en, key);
    } else if (key === "nagarpalika_address_np") {
      return generateSpan(nagarpalikaAddress_np, key);
    } else if (key === "nagarpalika_address_en") {
      return generateSpan(nagarpalikaAddress_en, key);
    } else if (key === "ward_name_np") {
      return generateSpan(org_name_np, key);
    } else if (key === "ward_name_en") {
      return generateSpan(org_name_en, key);
    } else if (key === "organization_name_np") {
      return generateSpan(org_name_np, key);
    } else if (key === "organization_name_en") {
      return generateSpan(org_name_en, key);
    } else if (key === "chalaniNum") {
      let value = `${values.chalaniNum || ""} ${
        values.chalaniNum ? values.organization_code || "" : ""
      }`;
      return generateSpan(value, key, "pl-4 block whitespace-nowrap");
    } else if (key === "chalaniNum") {
      let value = `${values.chalaniNum || ""} ${
        values.chalaniNum ? values.organization_code || "" : ""
      }`;
      return generateSpan(value, key, "pl-4 block whitespace-nowrap");
    } else if (key === "en_no") {
      let value = `<span class='font-bold text-xs'>E.N no.: ${values.issueId}</span>`;
      return values.issueId ? generateSpan(value, key) : "";
    } else {
      return generateSpan(values[key], key);
    }
  });

  return headerPreview;
};
