import Select from 'react-select';

/**
 * 
 */
export default function SelectComponent(props: ISelectProps) {

    const options = Object.entries(props.options || {}).map(([value, label]) => ({ value: value, label: label }));
    const isMulti = props.isMulti ?? true;
    const isDisabled = props.isDisabled;
    return (
        <div className="inputDiv">
            <Select
                isMulti={isMulti}
                name="colors"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(values) => props.handleSelectChange && props.handleSelectChange(values)}
                defaultValue={props?.selectValue}
                placeholder="छान्नुहोस्"
                isDisabled={isDisabled}
            />
        </div>
    )
}

interface ISelectProps {
    elementId: string;
    isMulti?: boolean;
    options: any;
    selectValue?: any;
    register?: any;
    validations?: any;
    handleSelectChange?: (value: any) => void;
    isDisabled?: boolean;
}