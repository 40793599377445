import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";

export const initialState: IinitialState = {
  language: localStorage.getItem('language') === 'english' ? 'english' : "nepali",
  fontType: "traditional",
};

interface IinitialState {
  language: 'nepali' | 'english';
  fontType: 'traditional' | 'romanized';
}

const languageSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    changeFontType: (state, action) => {
      state.fontType = action.payload;
    },
  },
});

// Actions generated from the slice
const { changeLanguage } = languageSlice.actions;

// export user selector to get the slice in any component
export const languageSelector = (state: any) => state.language;

// export The reducer
const languageReducer = languageSlice.reducer;

export default languageReducer;

export const handleChangeLanguage =
  ({ language = "", fontType = "" }) =>
    (dispatch: AppDispatch) => {
      localStorage.setItem('language', language);

      dispatch(changeLanguage(language));
    };
