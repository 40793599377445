import { FC } from "react";
import ReceiverListInput from "../../components/@compose/ReceiverListInput";
import FormInput from "../../components/reusables/FormInput";
import { ILabel } from "../Compose/Compose";
import DocumentUpload from "../../components/@compose/DocumentUpload";
import { IFile } from "../../components/@compose/DocumentUpload/DocumentUpload";

interface ForwardModalProps {
  receivers: ILabel[] | null;
  handleForwardRemarksChange: (text: string) => void;
  handleReceiversChange: (value: ILabel[]) => void;
  handleForwardMail: () => void;
  handleForwardRemarkFilesChange: (value: string[]) => void;
}

const ForwardModal: FC<ForwardModalProps> = ({
  receivers,
  handleReceiversChange,
  handleForwardRemarksChange,
  handleForwardMail,
  handleForwardRemarkFilesChange,
}) => {
  const handleFileUpload = (files: IFile[]) => {
    handleForwardRemarkFilesChange(files.map((f: IFile) => f.file));
  };

  return (
    <div className="pt-4">
      <h1 className="text-3xl text-[#60A5FA] font-semibold mb-4">फरवार्ड</h1>

      <ReceiverListInput value={receivers} onChange={handleReceiversChange} />

      <div className="mb-3 min-h-[220px]">
        <FormInput
          id="remarks"
          label="प्रतिक्रिया लेख्नुहोस"
          type="Text"
          subType="textarea"
          getInputData={handleForwardRemarksChange}
        />
      </div>

      <div className="mb-3 min-h-[220px]">
        <DocumentUpload isMulti onUpload={handleFileUpload} url="" />
      </div>

      <div className="flex justify-end">
        <label
          htmlFor="forward-modal"
          className="btn border-0 bg-blue-400 hover:bg-blue-600 text-white"
          onClick={() => handleForwardMail()}
        >
          फरवार्ड गर्नुहोस
        </label>
      </div>
    </div>
  );
};

export default ForwardModal;
