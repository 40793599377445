import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleConvertNepali } from "../../../../_helpers/language/fonts";
import setUnicode from "../../../../_helpers/language/type";
import { nepaliNumber } from "../../../../_helpers/methods";
import { languageSelector } from "../../../../_redux/slices/languages";
import { nepaliNumberConverter } from "../../../../_helpers/template";


export default function InputField({
    type='text',
    language = 'nepali',
    getInputData,
    register,
    elementId,
    value,
    errors = {},
    setValue,
    properties = {},
    validation = {},
    autoComplete = "on",
    placeHolder = "",
    disabled,
    className, innerRef,
    onEnterPress,
    isRequired,
    ...rest
}: IInputFieldProps) {
    const [nepali, setNepali] = useState(value);
    const { fontType } = useSelector(languageSelector);

    function moveFocus(e: any) {
        e.target.parentElement.nextElementSibling?.querySelector("input").focus();
    }

    function switchFocus(e: any) {
        let val = e.target.value;
        // eslint-disable-next-line no-unused-vars
        const [_, suff] = elementId.split(".");
        switch (suff) {
            case "year":
                if (val.length === 4) {
                    moveFocus(e);
                }
                break;
            case "month":
                val.length === 2 && moveFocus(e);
                break;
            case "day":
                val.length === 2 && moveFocus(e);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setNepali(value);
    }, [value])


    function fillDigit(e: any) {
        let val = e.target.value;
        if (val) {
            // eslint-disable-next-line no-unused-vars
            const [pref, suff] = elementId.split(".");
            switch (suff) {
                case "month":
                    if (val.length >= 2) {
                        return null;
                    }
                    if (language === "english") {
                        val = val.padStart(2, 0);
                    } else {
                        val = val.padStart(2, "०");
                    }
                    setValue(elementId, val);
                    setNepali(val);
                    break;
                case "day":
                    if (val.length >= 2) {
                        return null;
                    }
                    if (language === "english") {
                        val = val.padStart(2, 0);
                    } else {
                        val = val.padStart(2, "०");
                    }
                    setValue(elementId, val);
                    setNepali(val);
                    break;
                default:
                    break;
            }
        }
    }

    const isNumeric = (number: any) => {
        // eslint-disable-next-line no-self-compare
        if (+number === +number) {
            // if is a number
            return true;
        }
        return false;
    };

    const handleNepaliTypeing = (e: any) => {
        const text = e.target.value;
        let output = "";

        switch (type) {
            case "text":
            case "textarea":
                if (language === "nepali") {
                    if (
                        fontType === "preeti" ||
                        fontType === "pcs_nepali" ||
                        fontType === "kantipur"
                    ) {
                        output = handleConvertNepali(fontType, text);
                        setNepali(output);
                        setValue && setValue(elementId, output);
                        if (getInputData) {
                            return getInputData(output);
                        }
                    }
                    else {
                        if (
                            e.nativeEvent.inputType === "deleteContentBackward" ||
                            e.nativeEvent.inputType === "deleteWordBackward" ||
                            !e.nativeEvent.inputType
                        ) {
                            setNepali(text);
                            setValue && setValue(elementId, text);

                            if (getInputData) {
                                return getInputData(text);
                            }
                        }
                    }
                } else if (language === "english") {
                    setNepali(text);
                    setValue && setValue(elementId, text);

                    if (getInputData) {
                        return getInputData(text);
                    }
                }
                break;
            case "number":
                if (language === "nepali") {
                    output = nepaliNumber(text);
                    setNepali(output);
                    setValue && setValue(elementId, output);
                    e.target.value = output;
                    if (getInputData) {
                        return getInputData(output);
                    }
                } else if (language === "english") {
                    for (let i = 0; i < text.length; i++) {
                        output += isNumeric(text[i]) ? text[i] : "";
                    }
                    setNepali(output);
                    setValue && setValue(elementId, output);

                    if (getInputData) {
                        return getInputData(output);
                    }
                }
                break;
            default:
                break;
        }
    };

    const handleNepaliNumberTyping = (e: any) => {
        let output = nepaliNumberConverter(e.target.value, true);
        setNepali(output);
        if (getInputData) {
            return getInputData(output);
        }
    }

    const handleOnPaste = (event: any) => {
        event.preventDefault();
        let pasteValue = (event.clipboardData || window.Clipboard).getData("text");
        const newValue = `${nepali || ''}${pasteValue}`;
        setNepali(newValue);
        setValue && setValue(elementId, newValue);
        if (getInputData) {
            getInputData(newValue);
        }
    }


    if (register) {
        let element = null;
        const handleKeyPressWithRegister = (e: any) => {
            if (language === "nepali") {
                if (
                    (type === "text" || type === "textarea")
                ) {
                    if (fontType === "traditional" || fontType === "romanized") {
                        const { value, newEndPos } = setUnicode(e, fontType);
                        setNepali(value);
                        setValue && setValue(elementId, value);
                        if (getInputData) {
                            getInputData(value);
                        }
                        e.target.setSelectionRange(newEndPos, newEndPos);
                    }
                }
            }
            else {
                setNepali(e.target.value);
                setValue && setValue(elementId, e.target.value);
                if (getInputData) {
                    getInputData(e.target.value);
                }
            }

            if (e.key === "Enter") {
                if (onEnterPress) {
                    onEnterPress(nepali)
                }
                if (setValue) {
                    setValue(elementId, "");
                }
                setNepali("");
            }
        }

        const onKeyDownWithRegister = (e: any) => {
            if (e.ctrlKey && e.key === ">") {
                const newValue = nepali + ".";
                setNepali(newValue);
                setValue && setValue(elementId, newValue);
                if (getInputData) {
                    getInputData(newValue);
                }
            } else if (e.ctrlKey && e.key === "?") {
                const newValue = nepali + "/";
                setNepali(newValue);
                setValue && setValue(elementId, newValue);
                if (getInputData) {
                    getInputData(newValue);
                }
            }
        };


        switch (type) {
            case 'textarea':
                element = <textarea
                    rows={10}
                    id={elementId}
                    value={nepali}
                    className={`${className} h-full border placeholder-[#808080]`}
                    autoComplete={autoComplete}
                    {...rest}
                    {...register(elementId.split("'").join("&&&&"), validation)}
                    onChange={(e) => {
                        if (properties.isBlock) {
                            e.target.value = e.target.value.toUpperCase();
                        }
                        register(elementId).onChange(e);
                        handleNepaliTypeing(e);
                    }}
                    placeholder={placeHolder}
                    onKeyPress={(e: any) => {
                        if (
                            language === "nepali"
                        ) {
                            if (fontType === "traditional" || fontType === "romanized") {
                                const { value, newEndPos } = setUnicode(e, fontType);
                                setValue(elementId, value);
                                setNepali(value);
                                if (getInputData) {
                                    getInputData(value);
                                }
                                e.target.setSelectionRange(newEndPos, newEndPos);
                            }
                        }
                        else {
                            setValue(elementId, e.target.value);
                            setNepali(e.target.value);

                            if (getInputData) {
                                getInputData(value);
                            }
                        }
                    }}
                    onPaste={handleOnPaste}
                    onKeyDown={onKeyDownWithRegister}
                    disabled={disabled}
                    isRequired={isRequired}
                />;
                break;
            default:
                element = <input
                    id={elementId}
                    value={nepali}
                    autoComplete={autoComplete}
                    className={`${className} border placeholder-[#808080]`}
                    {...rest}
                    {...register(elementId.split("'").join("&&&&"), validation)}
                    onChange={(e) => {
                        if (properties.isBlock) {
                            e.target.value = e.target.value.toUpperCase();
                        }
                        handleNepaliTypeing(e);
                        if (!setValue) register(elementId).onChange(e);
                    }}
                    onKeyUp={(e) => {
                        if (elementId.startsWith("date")) {
                            switchFocus(e);
                        }
                    }}
                    onBlur={(e) => {
                        if (elementId.startsWith("date")) {
                            fillDigit(e);
                        }
                    }}
                    onPaste={handleOnPaste}
                    placeholder={placeHolder}
                    onKeyPress={handleKeyPressWithRegister}
                    disabled={disabled}
                    onKeyDown={onKeyDownWithRegister}
                    isRequired={isRequired}
                />
        }

        return element;
    } else {
        let element = null;

        const handleKeyPress = (e: any) => {
            if (e.key === "Enter" && onEnterPress) {
                onEnterPress(nepali);
                setNepali("");
                e.target.value ='';
            }
            if (language === "nepali") {
                const { value, newEndPos } = setUnicode(e, fontType);
                setNepali(value);
                if (getInputData) {
                    getInputData(value);
                }
                e.target.setSelectionRange(newEndPos, newEndPos);

                if (getInputData) {
                    getInputData(value);
                }
            }
            else {
                setNepali(e.target.value);
                if (getInputData) {
                    getInputData(e.target.value);
                }
            }
        }

        switch (type) {
            case "textarea":
                element = <textarea
                    rows={9}
                    id={elementId}
                    onChange={handleNepaliTypeing}
                    onKeyPress={handleKeyPress}
                    placeholder={placeHolder}
                    value={nepali}
                    autoComplete={autoComplete}
                    className={`${className} !h-full placeholder-[#808080]`}
                    {...rest}
                    disabled={disabled}
                    onPaste={handleOnPaste}
                    required={isRequired}
                />
                break;
            case "number":
                element = <input
                    id={elementId}
                    onChange={handleNepaliNumberTyping}
                    placeholder={placeHolder}
                    value={nepali}
                    autoComplete={autoComplete}
                    className={`${className} border placeholder-[#808080]`}
                    {...rest}
                    disabled={disabled}
                    onPaste={handleOnPaste}
                    required={isRequired}
                />
                break;
            default:
                element = <input
                    ref={innerRef}
                    id={elementId}
                    onChange={handleNepaliTypeing}
                    onKeyPress={handleKeyPress}
                    placeholder={placeHolder}
                    value={nepali}
                    autoComplete={autoComplete}
                    className={`${className} border placeholder-[#808080]`}
                    {...rest}
                    disabled={disabled}
                    onKeyUp={(e) => {
                        if (elementId?.startsWith("date")) {
                            switchFocus(e);
                        }
                    }}
                    onBlur={(e) => {
                        if (elementId?.startsWith("date")) {
                            fillDigit(e);
                        }
                    }}
                    onPaste={handleOnPaste}
                    required={isRequired}
                />
        }

        return element;
    }
}

interface IInputFieldProps {
    type?: 'text' | 'textarea' | "number";
    language?: 'nepali' | 'english';
    getInputData?: (value: string) => void;
    register?: any;
    elementId: string;
    value?: any;
    errors?: any;
    setValue?: any;
    properties?: any;
    validation?: any;
    autoComplete?: "off" | "on";
    placeHolder?: string;
    disabled?: boolean;
    maxLength?: number;
    className?: string;
    onEnterPress?: (value: string) => void;
    innerRef?: any;
    isRequired?: boolean;
}