import dayjs from "dayjs";
import { Key, useCallback, useMemo, useState } from "react";
import { RiSendPlane2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import TopBar from "../../components/app/topbar";
import Avatar from "../../components/reusables/Avatar";
import Table, {
  tableDataType,
  tableHeadType,
} from "../../components/reusables/Table";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";
import { useQueryClient } from "@tanstack/react-query";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { setErrorToastMessage, setSuccessToastMessage } from "../../_redux/slices/toast";
import Loader from "../../components/app/Loader";
import { useAppDispatch } from "../../hooks/redux";

const LIMIT = 50;
var adbs = require("ad-bs-converter");

/**
 * 
 */
export default function Sent() {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  // const [listStyle, setListStyle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);


  //
  const handleSuccess = (data: any) => {
    setTotal(data?.data?.count);
    return data;
  };

  const handleError = () => { };

  const { data: sentData, isFetching, isLoading } = useQueryHook({
    queryName: "sent",
    queryRoute: "/paper/sent/",
    params: [
      ["limit", LIMIT],
      ["offset", offset * LIMIT],
    ],
    options: {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  });

  // Star feature
  const { mutate: starMail } = useMutationHook({
    queryRoute: 'paper/pin/',
    options: {
      onSuccess: (data) => {
        try {
          queryClient.invalidateQueries(['sent']);

          dispatch(setSuccessToastMessage('पत्र ताराङ्कित गरियो।'));
          return data;
        } catch (error) {
          console.log('ERROR star mails', error);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        setErrorToastMessage('Something went wrong');
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  // UnStar feature
  const { mutate: unStarMail } = useMutationHook({
    queryRoute: 'paper/unpin/',
    options: {
      onSuccess: (data) => {
        try {
          queryClient.invalidateQueries(['sent']);
          dispatch(setSuccessToastMessage('ताराङ्कित पत्रबाट हटाइयो।'));

          return data;
        } catch (error) {
          console.log('ERROR star mails', error);
        }
      },
      onError: (error) => {
        console.log(error, 'error');
        setErrorToastMessage('Something went wrong');
      },
    },
    axiosOptions: {
      multipart: false,
    },
  });

  const handleUnstarClick = useCallback((id: Key) => {
    unStarMail({ papers: [id] });
  }, [unStarMail]);

  const handleStarClick = useCallback((id: Key) => {
    starMail({ papers: [id] });
  }, [starMail])

  const headers: tableHeadType[] = useMemo(() => {
    return [
      {
        id: 1,
        cell: (value) => (
          <div className="flex space-x-4 items-center pl-2">
            <label htmlFor="" className="label !ml-0 !p-0">
              {
                value?.pinned ?
                  <span className="cursor-pointer !ml-0 text-yellow-400  hover:bg-yellow-200 hover:ease-in-out duration-500 hover:rounded-full" onClick={() => handleUnstarClick(value?.id)}>
                    <IoStar fontSize={17} />
                  </span>
                  :
                  <span className="cursor-pointer !ml-0 hover:bg-slate-200 hover:ease-in-out duration-500 hover:rounded-full" onClick={() => handleStarClick(value?.id)}>
                    <IoStarOutline fontSize={17} />
                  </span>
              }
            </label>
          </div>
        ),
        dataClassName: "w-[5%] border-l-[3px] !align-middle",
        noLink: true,
      },
      {
        id: 2,
        cell: (value: tableDataType) => (
          <div className="flex space-x-4 items-center">
            <span className="text-sm font-normal">To:</span>
            <span className="ml-2">
              <Avatar size="sm" name={value?.receiver} id={String(value?.receiver_id)} />
              <span className="ml-2 text-sm">{value?.receiver}</span>
            </span>
          </div>
        ),
        dataClassName: "w-[20%]",
      },
      {
        id: 3,
        // title: 'message',
        cell: (value: tableDataType) => (
          <Link to={`/sent-mail/${value?.id}`} className="text-slate-700">
            {
              value.darta_number && <><span>#{value.darta_number}</span> <span> &nbsp;| &nbsp;</span></>
            }

            <span className="font-bold">{value.subject}</span>

            {
              value.message && <> <span> &nbsp;- &nbsp;</span> <span className="text-sm">{value.message?.substring(0, 80)}</span></>
            }
          </Link>
        ),
        dataClassName: "w-[55%]",
      },
      {
        id: 4,
        cell: (value: tableDataType) => {
          const { ne } = adbs.ad2bs(dayjs(value.date).format("YYYY/M/D"));
          const nepaliDate = `${ne.year}/${ne.month.padStart(2, "०")}/${ne.day.padStart(2, "०")}`;

          const time = dayjs(value.date).format('hh:mm A');
          const isToday = dayjs(value.date).format("YYYY/MM/DD") === dayjs().format("YYYY/MM/DD");
          return (
            <span className='text-slate-500 w-full flex justify-end pr-4'>
              <div className="tooltip tooltip-left" data-tip={`${nepaliDate} ${time}`}>
                {
                  isToday ?
                    <span className="text-sm text-slate-600">{time}</span>
                    :
                    <span className="text-sm text-slate-600">
                      {nepaliDate}
                    </span>
                }
              </div>
            </span>
          )
        },
        dataClassName: "w-[20%] text-slate-500 text-sm",
      },
    ];
  }, [handleStarClick, handleUnstarClick]);


  const generateData = (data: any) => {
    if (data?.results) {
      const final = data?.results?.map((result: any) => {
        return {
          id: result.id,
          receiver: result.receiver || result.organization_name,
          receiver_id: result.receiver_id,
          subject: result.subject,
          message: result.message,
          date: result.created_at,
          priority: result.priority,
          pinned: result.pinned,
          read: true
        };
      });
      return final;
    }
    return [];
  };

  const finalData = sentData?.data ? generateData(sentData.data) : [];
  let render = null;
  if (isLoading) {
    render = (
      <div className="no-top-content-container">
        <div className="h-2/4 bg-white flex justify-center items-center flex-col rounded m-3">
          <Loader size='64px' />
        </div>
      </div>
    );
  }

  if (finalData.length < 1) {
    render = (
      <div className="no-top-content-container">
        <div className="h-2/4 bg-white flex justify-center items-center flex-col rounded m-3">
          <div className="mb-6 p-9 w-[190px] h-[190px] rounded-full bg-gray-200 flex justify-center items-center">
            <RiSendPlane2Line size={82} color="gray" />
          </div>

          <div className="text-xl">तपाईंले कुनै पत्राचार पठाउनु भएको छैन।</div>
        </div>
      </div>
    );
  }
  const handleNextPage = () => {
    setOffset((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setOffset((prev) => prev - 1);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries(['sent']);
  }

  return (
    <div>
      <TopBar
        pagination={{
          offset: offset,
          limit: LIMIT,
          total: total,
          onNext: handleNextPage,
          onPrev: handlePrevPage
        }}

        isLoading={isFetching}
        showRefresh
        showBackButton

        handleRefresh={handleRefresh}
      />

      {
        render || <div className="no-top-content-container">
          <Table
            headers={headers}
            data={finalData}
            className=""
            noHeader
          />
        </div>
      }

    </div>
  );
}
