import TopBar from '../../components/app/topbar';
import { useQueryHook } from '../../hooks/react-query/useQueryHook';

import TemplateCard, { colorClass } from './TemplateCard';
import Loader from '../../components/app/Loader';

export default function Templates() {
	const { data, isLoading } = useQueryHook({ queryName: 'templates', queryRoute: '/template/category/' });

	return (
		<div>
			<TopBar showBackButton />

			<div className="px-5 no-top-content-container">
				{isLoading ? (
					<div className="flex justify-center items-center h-[200px]">
						<Loader />
					</div>
				) : (
					data?.data?.results?.map((category: any) => {
						const templates = category.templates.filter((template: any) => template?.entity !== 'private');
						if (category.entity === 'private') {
							return null;
						}
						return (
							<div key={category.title}>
								<h3 className="text-2xl font-bold">{category.title}</h3>
								<div className="row mt-3 grid grid-cols-3 gap-3">
									{templates.length ? (
										templates.map((template: any, idx = 1) => (
											<TemplateCard
												templateUrl="/template/"
												title={template.title}
												icon={template.icon}
												categoryIcon={template.category_icon}
												id={template.id}
												key={template.id}
												idx={idx % colorClass.length}
												additional_info={template.additional_info}
											/>
										))
									) : (
										<div className="text-center m-4 w-full">
											<h3 className="color-primary text-xl">No templates found</h3>
										</div>
									)}
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}
