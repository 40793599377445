import { BsKeyboard } from "react-icons/bs";
import { handleChangeLanguage } from "../../../../_redux/slices/languages"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { useCallback, useEffect } from "react";

/**
 * 
 */
export default function LanguageSelector() {
    const dispatch = useAppDispatch();
    const language = useAppSelector(state => state.language.language);

    const handleToggleLanguage = useCallback(() => {
        if (language === "english") {
            dispatch(handleChangeLanguage({ language: 'nepali' }));
        }
        else {
            dispatch(handleChangeLanguage({ language: 'english' }));
        }
    }, [dispatch, language]);

    useEffect(() => {
        const handleLanguageChangeShortcut = (event: any) => {
            if (event.keyCode === 76 && event.ctrlKey) {
                event.preventDefault();
                handleToggleLanguage();
            }
        }
        document.addEventListener('keydown', handleLanguageChangeShortcut);

        return () => document.removeEventListener('keydown', handleLanguageChangeShortcut);
    }, [handleToggleLanguage])

    return (
        <label tabIndex={0} className="btn btn-sm bg-white border-secondary text-secondary hover:bg-secondary hover:text-white rounded-btn border hover:border-secondary ml-4 mr-0.5 pr-4" onClick={handleToggleLanguage}>
            <div className="tooltip tooltip-left tooltip-info flex items-center" data-tip={language === "nepali" ? "इनपुट भाषा परिवर्तन गर्न क्लिक गर्नुहोस्।" : "Click to change input language"}>
                < BsKeyboard fontSize={18} className="mr-3" /> {language === "nepali" ? "NE" : "EN"
                }
            </div>
        </label >
    )
}