import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/redux';

//
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

//
import { setToast } from '../_redux/slices/toast';

/**
 * 
 */
export default function Toast() {
	const dispatch = useAppDispatch();
	const toast = useAppSelector((state) => state.toast);
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		if (toast.show) {
			setShow(toast.show);
		}
	}, [toast])

	useEffect(() => {
		let timeout = setTimeout(() => {
			if (show) {
				dispatch(setToast({ ...toast, show: false }));
				setShow(false);
			}
		}, 5000);
		return () => clearTimeout(timeout);
	}, [dispatch, toast, show]);

	return show ? (
		<div className="toast toast-top w-96 z-[99999]">
			<div
				className={classNames('alert border-l-8 rounded-md bg-white shadow-lg border-2 justify-start', toast.type === "error" && 'border-l-error', toast.type === 'success' && 'border-l-success')}
			>
				{/* icon */}
				<div className={classNames('w-10 h-10 rounded-full bg-success flex justify-center items-center',
					toast.type === 'success' && "bg-success",
					toast.type === "error" && "bg-error")}
				>
					{
						toast.type === "success" &&
						<FaCheck className="text-white" />
					}

					{
						toast.type === "error" &&
						<IoClose fontSize={28} className="text-white" />
					}
				</div>

				{/* message */}
				<div>
					<span>{toast.message}</span>
				</div>
			</div>
		</div>
	) : null;
};