import React, { useEffect, useState } from "react";
import { districts } from "../../../../_datas/districts";

function District({
  data,
  systemLanguage,
  language,
  register,
  elementId,
  properties = {},
  errors = {},
  validation,
  setValue,
  value,
  isPublic,
  entity,
}) {
  const [district, setDistrict] = useState(() => {
    if (value) {
      return value;
    }
    return "";
  });

  if (isPublic && entity === "private") {
    return null;
  }

  const registerDistrictValueChange = (value) => {
    setDistrict(value);
    setValue(elementId, value);
  };
  const handleDistrictChangeBySelect = (value) => {
    registerDistrictValueChange(value);
  };
  return (
    <div>
      <label>{data.label} :</label>
      <div className="inputDiv">
        <span>
          <select
            id={elementId}
            value={district}
            {...register(elementId, validation)}
            className={`mx-1 form-control formField ${
              properties?.isRequired ? "textbox_req" : ""
            } ${errors[elementId] ? "is-invalid" : ""} `}
            onChange={(e) => {
              handleDistrictChangeBySelect(e.target.value);
            }}
            disabled={properties.readOnly}
          >
            {language === "nepali"
              ? Object.keys(districts).map((code) => (
                  <option key={code} value={districts[code]["nepali"]}>
                    {districts[code]["nepali"]}
                  </option>
                ))
              : Object.keys(districts).map((code) => (
                  <option key={code} value={districts[code]["english"]}>
                    {districts[code]["english"]}
                  </option>
                ))}
          </select>
        </span>
      </div>
    </div>
  );
}

export default District;
