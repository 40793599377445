import { FC } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import Avatar from '../../components/reusables/Avatar';
import { useSelector } from 'react-redux';
import { RootState } from '../../_redux/store';
import Skeleton from '../../components/Skeleton/Skeleton';

interface IUserInfo {
	from: {
		name: string;
		id?: number;
		post?: string | null | undefined;
		branch?: string | null | undefined;
	};
	to?: {
		name: string;
		id?: number;
		post?: string | null | undefined;
		branch?: string | null | undefined;
	},
	isLoading?: boolean;
}

const UserInfo: FC<IUserInfo> = ({ from, to, isLoading = false }) => {
	const { user } = useSelector((state: RootState) => state.auth);
	return (
		<div className="mb-4">
			<div className="flex items-center space-x-4">
				{isLoading ? <Skeleton.Circle /> : <Avatar name={from?.name} id={String(from?.id)} />}
				<div>
					<h4>
						<span className="font-semibold leading-none">
							{isLoading ? <Skeleton.Text className="h-3 mb-1" /> : from.name}
						</span>{' '}
					</h4>
					<div className="text-xs flex items-center space-x-1 text-slate-600">
						<span>पाउने: <span className='font-bold'>{to?.name}</span></span>
						<div className="dropdown">
							<label tabIndex={0} role="button" title="Show Detail">
								<FaCaretDown />
							</label>
							<div className="dropdown-content text-base p-2 shadow-sm border bg-white rounded-sm max-w-max w-max grid grid-cols-3 gap-x-4">
								<p className="font-semibold">पठाउने<span className="ml-[3px]">:</span></p>
								<p className="col-span-2">{from.name}</p>
								{from?.post && (
									<>
										<p className="font-semibold">Post:</p>
										<p className="col-span-2">{from.post}</p>
									</>
								)}
								{from?.post && (
									<>
										<p className="font-semibold">Branch/Ward:</p>
										<p className="col-span-2">{from.branch}</p>
									</>
								)}

								<p className="font-semibold">पाउने  <span className="ml-[8px]">:</span></p>
								<p className="col-span-2">{to?.name}</p>
								{from?.post && (
									<>
										<p className="font-semibold">Post:</p>
										<p className="col-span-2">{user && user?.profile?.post_np}</p>
									</>
								)}
								{from?.post && (
									<>
										<p className="font-semibold">Branch/Ward:</p>
										<p className="col-span-2">{user && user?.profile?.ward_info.name_np}</p>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserInfo;
