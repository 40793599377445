import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

/**
 * 
 */
function Password({ id, register, className, placeholder, isRequired, value }: IPasswordProps) {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    return (
        <div className="relative inline-block w-full">
            <input
                type={showCurrentPassword ? "text" : "password"}
                {...register(id, { required: isRequired })}
                placeholder={placeholder}
                autoComplete="new-password"
                className={`block w-full float-none px-2 py-2 border rounded${className}`}
            />
            <div className="absolute flex justify-center items-center h-[20px] w-[20px] right-2 bottom-2 cursor-pointer">
                {showCurrentPassword ? (
                    <AiFillEyeInvisible onClick={() => setShowCurrentPassword(false)} />
                ) : (
                    <AiFillEye onClick={() => setShowCurrentPassword(true)} />
                )}
            </div>
        </div>
    );
}

export default Password;

interface IPasswordProps {
    id: string;
    register: any;
    className?: string;
    placeholder?: string;
    isRequired?: boolean;
    value?: string;
}