import packageJson from "../../../../package.json";

/**
 * 
 */
export default function Footer() {
    return (
        <div className="flex justify-between px-4 text-slate-400 py-1 text-sm">
            <span>
                Version : {packageJson.version}
            </span>

            <span>Powered by: <a className="text-green-500 hover:text-green-400" href="https://ideabreed.com.np/" target="_blank" rel="noreferrer">Idea Breed IT Solution Pvt. Ltd.</a>
            </span>
        </div>
    )
}